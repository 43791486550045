import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { KeyboardArrowDown, KeyboardArrowUp, ShoppingCart, ShoppingCartCheckout, TaskAlt } from "@mui/icons-material";
import { Box, Collapse, Divider, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CreditNote } from "../../../core/models/credit-note";
import { CreditNoteItem } from "../../../core/models/credit-note-item";
import { getCreditNotes, releaseCreditNote } from "../../../core/services/credit-notes";
import { getDateTimeString } from "../../../core/services/dates";
import { useNotifications } from "../../../core/hooks/use-notifications";
import NumberField from "../../../shared/components/NumberField";

const getCreditNoteTotal = (creditNote: CreditNote) => hasCreditNoteItems(creditNote) ? getCreditNoteItemsTotal(creditNote.creditNoteItems) : getCreditNoteSubtotal(creditNote) * getVatFactor(creditNote.purchaseIncludesVat);
const getCreditNoteSubtotal = (creditNote: CreditNote) => hasCreditNoteItems(creditNote) ? getCreditNoteItemsSubtotal(creditNote.creditNoteItems) : creditNote.baseAmount * creditNote.creditReturn / 100;
const getCreditNoteItemsTotal = (creditNoteItems: CreditNoteItem[]) => creditNoteItems.reduce((s, cni) => s + getCreditNoteItemTotal(cni), 0);
const getCreditNoteItemsSubtotal = (creditNoteItems: CreditNoteItem[]) => creditNoteItems.reduce((s, cni) => s + getCreditNoteItemSubtotal(cni), 0);
const getCreditNoteItemTotal = (creditNoteItem: CreditNoteItem) => getCreditNoteItemSubtotal(creditNoteItem) * getVatFactor(creditNoteItem.purchaseIncludesVat);
const getCreditNoteItemSubtotal = (creditNoteItem: CreditNoteItem) => creditNoteItem.baseAmount * creditNoteItem.creditReturn / 100 * creditNoteItem.quantity;

const hasCreditNoteItems = (creditNote: CreditNote) => creditNote.opened || !!creditNote.creditNoteItems.length;
const getVatFactor = (includesVat: boolean) => includesVat ? 1.16 : 1;

export default function CreditNotes() {
  const [creditNotes, setCreditNotes] = useState<CreditNote[]>([]);
  const [showItems, setShowItems] = useState<{ [key: number]: boolean }>({});
  const [purchaseMenus, setPurchaseMenus] = useState<{ [key: number]: HTMLElement | null }>({});

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  useEffect(() => {
    getCreditNotes()
      .then(creditNotes => {
        setCreditNotes(creditNotes);
        setShowItems(creditNotes.reduce((si, cn) => ({ ...si, [cn.id]: false }), {}));
        setPurchaseMenus(creditNotes.reduce((pm, cn) => ({ ...pm, [cn.id]: null }), {}));
      })
      .catch(() => showErrorNotification('Error al cargar las Notas de Crédito.'));
  }, [showErrorNotification]);

  const handleToggleItemsClick = (id: number) => setShowItems({ ...showItems, [id]: !showItems[id] });

  const handlePurchasesOpen = (id: number, anchor: HTMLElement) => setPurchaseMenus({ ...purchaseMenus, [id]: anchor });
  const handlePurchasesClose = (id: number) => setPurchaseMenus({ ...purchaseMenus, [id]: null });

  const handleReleaseCreditNoteClick = (id: number) => {
    releaseCreditNote(id)
      .then(creditNote => {
        setCreditNotes(creditNotes.map(cn => cn.id === creditNote.id ? creditNote : cn));
        showSuccessNotification('Nota de Crédito liberada correctamente.');
      })
      .catch(() => showErrorNotification('Error al liberar la Nota de Crédito.'));
  };

  return (
    <Box>
      {[
        {
          title: 'Notas de Crédito Liberadas',
          filter: (creditNote: CreditNote) => creditNote.released && !creditNote.used
        },
        {
          title: 'Notas de Crédito Abiertas',
          filter: (creditNote: CreditNote) => creditNote.opened && !creditNote.released && !creditNote.used
        },
        {
          title: 'Notas de Crédito Pendientes',
          filter: (creditNote: CreditNote) => !creditNote.opened && !creditNote.released && !creditNote.used
        },
        {
          title: 'Notas de Crédito Utilizadas',
          filter: (creditNote: CreditNote) => creditNote.used
        }
      ].map((section, index) => (
        <React.Fragment key={index}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>{section.title}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Proveedor</Box></TableCell>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Descripción</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Importe Base</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Crédito</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditNotes.filter(cn => section.filter(cn)).map((cn, i) => (
                <React.Fragment key={i}>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {hasCreditNoteItems(cn) && (
                        <IconButton size="small" onClick={() => handleToggleItemsClick(cn.id)}>
                          {showItems[cn.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="body1">{cn.providerName}</Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="body1">{cn.description}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      {!hasCreditNoteItems(cn) && <NumberField type="text" format="currency" value={cn.baseAmount} />}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      {!hasCreditNoteItems(cn) && <NumberField type="text" format="percentage" value={cn.creditReturn} />}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      <NumberField type="text" format="currency" value={getCreditNoteSubtotal(cn)} />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      <NumberField type="text" format="currency" value={getCreditNoteTotal(cn)} />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      {cn.purchaseIds.length === 1 && (
                        <IconButton color="info" size="small" component={Link} to={`/compras/${cn.purchaseIds[0]}`}>
                          <ShoppingCart />
                        </IconButton>
                      )}
                      {cn.purchaseIds.length > 1 && (
                        <>
                          <IconButton color="info" size="small" onClick={e => handlePurchasesOpen(cn.id, e.currentTarget)}>
                            <ShoppingCart />
                          </IconButton>
                          <Menu anchorEl={purchaseMenus[cn.id]} open={!!purchaseMenus[cn.id]} onClose={() => handlePurchasesClose(cn.id)}>
                            {cn.purchaseIds.map((pid, j) => (
                              <MenuItem key={j} component={Link} to={`/compras/${pid}`}>{getDateTimeString(cn.purchaseDates[j])}</MenuItem>
                            ))}
                          </Menu>
                        </>
                      )}
                      {cn.used && (
                        <IconButton color="info" size="small" component={Link} to={`/compras/${cn.purchaseId}`}>
                          <ShoppingCartCheckout />
                        </IconButton>
                      )}
                      {(cn.opened || !cn.purchaseIds.length) && !cn.released && !cn.used && (
                        <IconButton color="success" size="small" onClick={() => handleReleaseCreditNoteClick(cn.id)}>
                          <TaskAlt />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                      <Collapse in={showItems[cn.id]}>
                        <Box sx={{ marginLeft: 8, marginRight: 8, marginBottom: 2 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Producto</Box></TableCell>
                                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Importe Base</Box></TableCell>
                                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Crédito</Box></TableCell>
                                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Cantidad</Box></TableCell>
                                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cn.creditNoteItems.map((cni, j) => (
                                <TableRow key={j}>
                                  <TableCell>
                                    <Typography variant="body1">{cni.fullName}</Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <NumberField type="text" format="currency" value={cni.baseAmount} />
                                  </TableCell>
                                  <TableCell align="center">
                                    <NumberField type="text" format="percentage" value={cni.creditReturn} />
                                  </TableCell>
                                  <TableCell align="center">
                                    <NumberField type="text" format="integer" value={cni.quantity} />
                                  </TableCell>
                                  <TableCell align="center">
                                    <NumberField type="text" format="currency" value={getCreditNoteItemSubtotal(cni)} />
                                  </TableCell>
                                  <TableCell align="center">
                                    <NumberField type="text" format="currency" value={getCreditNoteItemTotal(cni)} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </React.Fragment>
      )).reduce((sections, section, i) => sections.length ? [
        ...sections,
        <Divider key={-i} sx={{ bgcolor: theme => theme.palette.primary.light, marginTop: 4, marginBottom: 4 }} />,
        section
      ] : [section], [] as JSX.Element[])}
    </Box>
  );
};
