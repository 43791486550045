import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useNotifications } from "../../core/hooks/use-notifications";
import { Client } from "../../core/models/client";
import { getClients } from "../../core/services/clients";
import EntityTable from "../../shared/components/EntityTable";

export default function Clients() {
  const [clients, setClients] = useState<Client[]>([]);

  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    getClients().then(clients => setClients(clients)).catch(() => showErrorNotification('Error al cargar los Clientes.'));
  }, [showErrorNotification]);

  return (
    <Box>
      <Button variant="contained" color="success" component={Link} to="/clientes/nuevo" sx={{ marginBottom: 4 }}>Crear Nuevo Cliente</Button>
      {[
        {
          title: 'Clientes Activos',
          filter: (client: Client) => client.active
        },
        {
          title: 'Clientes Inactivos',
          filter: (client: Client) => !client.active
        }
      ].map((section, index) => (
        <React.Fragment key={index}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>{section.title}</Typography>
          <EntityTable
            entities={clients.filter(section.filter)}
            emptyLabel={'No se encontraron Clientes'}
            getEntityLink={c => `/clientes/${c.id}`}
            columns={{
              name: {
                name: 'Nombre'
              },
              contactName: {
                name: 'Contacto'
              },
              contactPhone: {
                name: 'Teléfono',
                getValue: cp => cp ? cp.replace(/\s/g, '').replace(/^(\d{2})(\d{4})(\d{4})$/, '$1 $2 $3') : ''
              },
              contactEmail: {
                name: 'Correo Electrónico',
                getValue: ce => ce ? ce.trim() : ''
              }
            }}
          />
        </React.Fragment>
      )).reduce((sections, section, index) => sections.length ? [
        ...sections,
        <Divider key={-index} sx={{ bgcolor: theme => theme.palette.primary.light, marginTop: 4, marginBottom: 4 }} />,
        section
      ] : [section], [] as JSX.Element[])}
    </Box>
  );
}