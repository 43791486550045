import { TDEntity, TDProperty, TDPropertyValue } from "../utils/entity-types"

export type TTreeNode<TEntity extends TDEntity> = {
  name: string;
  children: TTreeNode<TEntity>[];
  entities: TEntity[];
};

export const createGroupingTree = <
  TEntity extends TDEntity,
  TGroupingProperty extends TDProperty<TEntity>,
  TGroupingPropertyValue extends TDPropertyValue<TEntity, TGroupingProperty>
>(
  entities: TEntity[],
  groupingProperty: TGroupingProperty,
  groupingLevels: ((groupingPropertyValue: TGroupingPropertyValue) => string)[]
): TTreeNode<TEntity> => {
  let treeNode = entities.reduce((rootNode, entity) => {
    groupingLevels.reduce((levelNode, levelFunction, levelIndex) => {
      const nodeName = levelFunction(entity[groupingProperty] as TGroupingPropertyValue);
      const childNode = levelNode.children.find(tn => tn.name === nodeName) ?? {
        name: nodeName,
        children: [],
        entities: []
      };

      !childNode.children.length && !childNode.entities.length && levelNode.children.push(childNode);
      levelIndex === groupingLevels.length - 1 && childNode.entities.push(entity);

      return childNode;
    }, rootNode);

    return rootNode;
  }, {
    name: 'Root',
    children: [],
    entities: []
  } as TTreeNode<TEntity>);

  while (treeNode.children.length === 1) {
    treeNode = treeNode.children[0];
  }

  return treeNode;
};

export const getTreeNodeSubtotal = <TEntity extends TDEntity>(treeNode: TTreeNode<TEntity>, getEntityTotal: (entity: TEntity) => number, getEntitySubtotal: (entity: TEntity, getEntityTotal: (item: TEntity) => number) => number): number =>
  treeNode.children.reduce((t, tn) => t + getTreeNodeSubtotal(tn, getEntityTotal, getEntitySubtotal), 0) + treeNode.entities.reduce((t, e) => t + getEntitySubtotal(e, getEntityTotal), 0);

export const getTreeNodeTotal = <TEntity extends TDEntity>(treeNode: TTreeNode<TEntity>, getEntityTotal: (entity: TEntity) => number): number =>
  treeNode.children.reduce((t, tn) => t + getTreeNodeTotal(tn, getEntityTotal), 0) + treeNode.entities.reduce((t, e) => t + getEntityTotal(e), 0);
