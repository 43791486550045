import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { TColumnType, TNumberFormat } from "../../core/utils/util-types";
import NumberField from "./NumberField";

interface ValueListProps {
  items: ValueListItemProps[];
}

interface ValueListItemProps {
  name: string;
  value?: any;
  type?: TColumnType,
  format?: TNumberFormat
}

export default function ValueList({
  items
}: ValueListProps) {
  return (
    <List sx={{ mx: -1, my: -2 }}>
      {items.map((item, index) => (
        <ListItem key={index}>
          <ListItemText
            disableTypography
            primary={<Typography variant="body2" color={theme => theme.palette.text.secondary}>{item.name}</Typography>}
            secondary={
              <>
                {(() => {
                  switch (item.type) {
                    case 'number':
                      return (
                        <NumberField type="text" format={item.format || 'integer'} value={item.value} />
                      );
                    default:
                      return (
                        <Typography>{item.value}</Typography>
                      );
                  }
                })()}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};