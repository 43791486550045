import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import { AccountCircle, Menu, MenuOpen } from "@mui/icons-material";
import { AppBar, Backdrop, Box, CircularProgress, CssBaseline, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { useHttpAuthorization } from "../hooks/use-http-authorization";
import { getMenuItems } from "../services/roles";
import MenuList from "./Menu";
import Login from "./Login";
import Sales from "../../features/sales/Sales";
import Sale from "../../features/sales/Sale";
import Orders from "../../features/purchases/components/Orders";
import Purchases from "../../features/purchases/components/Purchases";
import Purchase from "../../features/purchases/components/Purchase";
import Loans from "../../features/loans/Loans";
import Loan from "../../features/loans/Loan";
import Clients from "../../features/clients/Clients";
import Client from "../../features/clients/Client";
import PurchasePayments from "../../features/purchases/components/PurchasePayments";
import CreditNotes from "../../features/purchases/components/CreditNotes";
import Profit from "../../features/profits/Profit";
import ProductPrices from "../../features/products/ProductPrices";

export default function Layout() {
  const menuWidth = 240;

  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [menuItems, setMenuItems] = useState<string[]>([]);

  const { promiseInProgress } = usePromiseTracker();

  const { isAuthenticating, authenticatedUser, startLogin, cancelLogin, logout } = useHttpAuthorization();

  const getSecureElement = useCallback((path: string, element: JSX.Element) => {
    return authenticatedUser && menuItems.length ? menuItems.some(mi => path.startsWith(getMenuItemUrl(mi))) ? element : <Typography variant="h4" component="div" align="center" color="error" marginTop={12}>Acceso Denegado</Typography> : <div />
  }, [authenticatedUser, menuItems]);
  
  useEffect(() => {
    startLogin();
  }, [startLogin]);

  useEffect(() => {
    if (authenticatedUser) {
      getMenuItems().then(menuItems => setMenuItems(menuItems)).catch(() => setMenuItems([]));
    } else {
      setMenuItems([]);
    }
  }, [authenticatedUser]);

  const handleMenuToggle = () => setMenuOpen(!menuOpen);

  const getMenuItemUrl = (s: string) => `/${s.toLowerCase().normalize('NFD').replace(/[ \u0300-\u036f]/g, '')}`;

  return (
    <BrowserRouter>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton color="inherit" edge="start" sx={{ mr: 2 }} onClick={handleMenuToggle}>
              {menuOpen ? <MenuOpen /> : <Menu />}
            </IconButton>
            <Typography noWrap variant="h6" component="div">
              Administración Berel Eje 8
            </Typography>
            <IconButton color="inherit" edge="end" sx={{ ml: 'auto' }} onClick={startLogin}>
              <AccountCircle fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor="left"
          open={menuOpen}
          sx={{
            width: menuWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: menuWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <MenuList menuItems={menuItems} getMenuItemUrl={getMenuItemUrl} />
          </Box>
        </Drawer>
        <Box component="main" sx={{
          p: 3,
          flexGrow: 1,
          transition: theme => theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: `-${menuWidth}px`,
          ...(menuOpen && {
            transition: theme => theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          })
        }}>
          <Toolbar />
          <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Login isAuthenticating={isAuthenticating} authenticatedUser={authenticatedUser} cancelLogin={cancelLogin} logout={logout} />
            <Routes>
              <Route path="/" element={<div />} />
              <Route path="/ventas" element={getSecureElement('/ventas', <Sales />)} />
              <Route path="/ventas/:id" element={getSecureElement('/ventas', <Sale />)} />
              <Route path="/pedidos" element={getSecureElement('/pedidos', <Orders />)} />
              <Route path="/pedidos/nuevo" element={getSecureElement('/pedidos', <Purchase />)} />
              <Route path='/pedidos/:id' element={getSecureElement('/pedidos', <Purchase />)} />
              <Route path="/compras" element={getSecureElement('/compras', <Purchases />)} />
              <Route path='/compras/:id' element={getSecureElement('/compras', <Purchase />)} />
              <Route path="/prestamos" element={getSecureElement('/prestamos', <Loans />)} />
              <Route path="/prestamos/nuevo" element={getSecureElement('/prestamos', <Loan />)} />
              <Route path='/prestamos/:id' element={getSecureElement('/prestamos', <Loan />)} />
              <Route path="/clientes" element={getSecureElement('/clientes', <Clients />)} />
              <Route path="/clientes/nuevo" element={getSecureElement('/clientes', <Client />)} />
              <Route path='/clientes/:id' element={getSecureElement('/clientes', <Client />)} />
              <Route path='/pagosaproveedores' element={getSecureElement('/pagosaproveedores', <PurchasePayments />)} />
              <Route path='/notasdecredito' element={getSecureElement('/notasdecredito', <CreditNotes />)} />
              <Route path='/calculadoradeutilidad' element={getSecureElement('/calculadoradeutilidad', <Profit />)} />
              <Route path='/configuraciondeprecios' element={getSecureElement('/configuraciondeprecios', <ProductPrices />)} />
            </Routes>
          </SnackbarProvider>
        </Box>
        <Backdrop open={promiseInProgress} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </BrowserRouter>
  );
}