import { useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useNotifications } from "../../core/hooks/use-notifications";
import { useProductsFilter } from "../../core/hooks/use-products-filter";
import { Client } from "../../core/models/client";
import { BusinessUnit } from "../../core/models/business-unit";
import { Measure } from "../../core/models/measure";
import { Product } from "../../core/models/product";
import { ProductType } from "../../core/models/product-type";
import { Provider } from "../../core/models/provider";
import { Sale } from "../../core/models/sale";
import { SaleItem } from "../../core/models/sale-item";
import { SaleItemComponent } from "../../core/models/sale-item-component";
import { StockProduct } from "../../core/models/stock-product";
import { getClients } from "../../core/services/clients";
import { getBusinessUnits } from "../../core/services/business-units";
import { getCurrentLocalDate, getCurrentLocalMonth, getCurrentLocalYear, getDateString, getDateYearMonthString, getDateYearString, getDaysDifference, isEqual, isGreaterOrEqual, isValidDate, normalizeDate } from "../../core/services/dates";
import { getItemsTotal } from "../../core/services/entities";
import { getMeasures } from "../../core/services/measures";
import { getProducts } from "../../core/services/products";
import { getProductTypes } from "../../core/services/product-types";
import { getProviders } from "../../core/services/providers";
import { downloadSalesReport, getSales } from "../../core/services/sales";
import { getStockProducts } from "../../core/services/stock-products";
import { cashPaymentMethodId, minValidDateString, vatFactor } from "../../core/utils/app-constants";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import SpanishLocale from "date-fns/locale/es";
import MultipleFilter from "../../shared/components/MultipleFilter";
import TreeTable from "../../shared/components/TreeTable";
import IncomeStatement from "../../shared/components/IncomeStatement";
import ValueList from "../../shared/components/ValueList";

type TStatus = 'sales' | 'quotations' | 'cancelations';
type TDateRange = 'today' | 'this-month' | 'this-year';

const minValidDate = normalizeDate(minValidDateString);

const salePredicate = (
  sale: Sale,
  clientIds: number[],
  businessUnitIds: number[],
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[],
  status: TStatus
) =>
  (!clientIds.length || clientIds.includes(sale.clientId)) &&
  (!businessUnitIds.length || businessUnitIds.includes(sale.businessUnitId)) &&
  (sale.saleItems.some(si => saleItemPredicate(si, providerIds, productIds, productTypeIds, measureIds))) &&
  ((status === 'sales' && !sale.quotation && !sale.canceled) || (status === 'quotations' && sale.quotation && !sale.canceled) || (status === 'cancelations' && !sale.quotation && sale.canceled));

const saleItemPredicate = (
  saleItem: SaleItem,
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[]
) =>
  (!providerIds.length || providerIds.includes(saleItem.providerId)) &&
  (!productIds.length || productIds.includes(saleItem.productId)) &&
  (!productTypeIds.length || productTypeIds.includes(saleItem.productTypeId)) &&
  (!measureIds.length || measureIds.includes(saleItem.measureId));

const saleItemComponentPredicate = (
  saleItemComponent: SaleItemComponent,
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[]
) =>
  (!providerIds.length || providerIds.includes(saleItemComponent.providerId)) &&
  (!productIds.length || productIds.includes(saleItemComponent.productId)) &&
  (!productTypeIds.length || productTypeIds.includes(saleItemComponent.productTypeId)) &&
  (!measureIds.length || measureIds.includes(saleItemComponent.measureId));

const getSaleTotal = (
  sale: Sale,
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[],
) => sale.saleItems.filter(si => saleItemPredicate(si, providerIds, productIds, productTypeIds, measureIds)).reduce((t, si) => t + si.saleTotal, 0);

const getSaleCost = (
  sale: Sale,
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[],
) => sale.saleItems.flatMap(si => si.saleItemComponents).filter(sic => saleItemComponentPredicate(sic, providerIds, productIds, productTypeIds, measureIds)).reduce((t, si) => t + si.purchaseTotal, 0) * vatFactor;

const getSaleCashPayments = (sale: Sale) => sale.salePaymentMethods.filter(sp => sp.paymentMethodId === cashPaymentMethodId).reduce((t, sp) => t + sp.paymentAmount, 0);
const getSaleElectronicPayments = (sale: Sale) => sale.salePaymentMethods.filter(sp => sp.paymentMethodId !== cashPaymentMethodId).reduce((t, sp) => t + sp.paymentAmount, 0);
const getSaleCommissions = (sale: Sale) => sale.salePaymentMethods.reduce((t, sp) => t + sp.paymentCommission, 0);

const getSalesTotal = (sales: Sale[], providerIds: number[], productIds: number[], productTypeIds: number[], measureIds: number[]) => getItemsTotal(sales, s => getSaleTotal(s, providerIds, productIds, productTypeIds, measureIds));
const getCostOfSale = (sales: Sale[], providerIds: number[], productIds: number[], productTypeIds: number[], measureIds: number[]) => getItemsTotal(sales, s => getSaleCost(s, providerIds, productIds, productTypeIds, measureIds));
const getGrossProfit = (sales: Sale[], providerIds: number[], productIds: number[], productTypeIds: number[], measureIds: number[]) => getSalesTotal(sales, providerIds, productIds, productTypeIds, measureIds) - getCostOfSale(sales, providerIds, productIds, productTypeIds, measureIds);
const getVariableExpends = (sales: Sale[]) => getItemsTotal(sales, getSaleCommissions);
const getNetProfit = (sales: Sale[]) => getGrossProfit(sales, [], [], [], []) - getVariableExpends(sales);

const getCashPaymentsTotal = (sales: Sale[]) => getItemsTotal(sales, getSaleCashPayments);
const getElectronicPaymentsTotal = (sales: Sale[]) => getItemsTotal(sales, getSaleElectronicPayments);
const getCommissionsTotal = (sales: Sale[]) => getItemsTotal(sales, getSaleCommissions);

const getAverageTicket = (sales: Sale[]) => getSalesTotal(sales, [], [], [], []) / sales.length;
const getDailyAverage = (sales: Sale[], startDate: Date, endDate: Date) => getSalesTotal(sales, [], [], [], []) / (getDaysDifference(endDate, startDate) + 1);

export default function Sales() {
  const [sales, setSales] = useState<Sale[]>([]);
  const [filteredSales, setFilteredSales] = useState<Sale[]>([]);

  const [clients, setClients] = useState<Client[]>([]);
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [measures, setMeasures] = useState<Measure[]>([]);
  const [stockProducts, setStockProducts] = useState<StockProduct[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(getCurrentLocalDate());
  const [endDate, setEndDate] = useState<Date | null>(getCurrentLocalDate());
  const [dateRange, setDateRange] = useState<TDateRange | null>('today');

  const [clientIds, setClientIds] = useState<number[]>([]);
  const [businessUnitIds, setBusinessUnitIds] = useState<number[]>([]);
  const [providerIds, setProviderIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [productTypeIds, setProductTypeIds] = useState<number[]>([]);
  const [measureIds, setMeasureIds] = useState<number[]>([]);

  const [status, setStatus] = useState<TStatus>('sales');

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const { filteredProducts, filteredProductTypes, filteredMeasures } = useProductsFilter(products, productTypes, measures, stockProducts, providerIds, productIds, productTypeIds);

  useEffect(() => {
    getClients().then(clients => setClients(clients)).catch(() => showErrorNotification('Error al cargar los Clientes.'));
    getBusinessUnits().then(businessUnits => setBusinessUnits(businessUnits)).catch(() => showErrorNotification('Error al cargar las Unidades de Negocio.'));
    getProviders().then(providers => setProviders(providers)).catch(() => showErrorNotification('Error al cargar los Proveedores.'));
    getProducts().then(products => setProducts(products)).catch(() => showErrorNotification('Error al cargar los Productos.'));
    getProductTypes().then(productTypes => setProductTypes(productTypes)).catch(() => showErrorNotification('Error al cargar los Tipos de Producto.'));
    getMeasures().then(measures => setMeasures(measures)).catch(() => showErrorNotification('Error al cargar las Medidas.'));
    getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos de Inventario.'));
  }, [showErrorNotification]);

  useEffect(() => {
    if (startDate && endDate && isValidDate(startDate) && isValidDate(endDate) && isGreaterOrEqual(startDate, minValidDate) && isGreaterOrEqual(endDate, minValidDate)) {
      getSales(startDate, endDate).then(sales => setSales(sales)).catch(() => showErrorNotification('Error al cargar las Ventas.'));
      setDateRange(isEqual(endDate, getCurrentLocalDate()) ? isEqual(startDate, getCurrentLocalYear()) ? 'this-year' : isEqual(startDate, getCurrentLocalMonth()) ? 'this-month' : isEqual(startDate, getCurrentLocalDate()) ? 'today' : null : null);
    } else {
      setSales([]);
      setDateRange(null);
    }
  }, [startDate, endDate, showErrorNotification]);

  useEffect(() => {
    setFilteredSales(sales.filter(sale => salePredicate(sale, clientIds, businessUnitIds, providerIds, productIds, productTypeIds, measureIds, status)));
  }, [sales, clientIds, businessUnitIds, providerIds, productIds, productTypeIds, measureIds, status]);

  const handleDownloadSalesReportClick = () => {
    downloadSalesReport(startDate!, endDate!)
      .then(file => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(file.data);
        anchor.download = file.name;
        anchor.click();
        showSuccessNotification('Reporte de Ventas descargado correctamente.');
      })
      .catch(() => showErrorNotification('Error al descargar el Reporte de Ventas.'));
  };

  return (
    <Grid container spacing={4}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={SpanishLocale}>
        <Grid item xs={4}>
          <DatePicker label="Fecha Inicial" minDate={minValidDate} value={startDate} onChange={d => setStartDate(d)} renderInput={params => <TextField fullWidth {...params} />} />
        </Grid>
        <Grid item xs={4}>
          <DatePicker label="Fecha Final" minDate={minValidDate} value={endDate} onChange={d => setEndDate(d)} renderInput={params => <TextField fullWidth {...params} />} />
        </Grid>
      </LocalizationProvider>
      <Grid item container alignItems="center" xs={4}>
        <ToggleButtonGroup fullWidth value={dateRange}>
          <ToggleButton value="today" onClick={() => { setStartDate(getCurrentLocalDate()); setEndDate(getCurrentLocalDate()) }}>Hoy</ToggleButton>
          <ToggleButton value="this-month" onClick={() => { setStartDate(getCurrentLocalMonth()); setEndDate(getCurrentLocalDate()) }}>Este Mes</ToggleButton>
          <ToggleButton value="this-year" onClick={() => { setStartDate(getCurrentLocalYear()); setEndDate(getCurrentLocalDate()) }}>Este Año</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={clients}
          inputLabel={'Clientes'}
          getOptionKey={c => c.id}
          getOptionLabel={c => c.name}
          onChange={ci => setClientIds(ci)}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={businessUnits}
          inputLabel={'Unidades de Negocio'}
          getOptionKey={bu => bu.id}
          getOptionLabel={bu => bu.description}
          onChange={bui => setBusinessUnitIds(bui)}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={providers}
          inputLabel={'Proveedores'}
          getOptionKey={p => p.id}
          getOptionLabel={p => p.name}
          onChange={pi => setProviderIds(pi)}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={filteredProducts}
          inputLabel={'Productos'}
          getOptionKey={p => p.id}
          getOptionLabel={p => p.name}
          onChange={pi => setProductIds(pi)}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={filteredProductTypes}
          inputLabel={'Tipos de Producto'}
          getOptionKey={pt => pt.id}
          getOptionLabel={pt => pt.fullName}
          onChange={pti => setProductTypeIds(pti)}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleFilter
          entities={filteredMeasures}
          inputLabel={'Medidas'}
          getOptionKey={m => m.id}
          getOptionLabel={m => m.name}
          onChange={mi => setMeasureIds(mi)}
        />
      </Grid>
      <Grid item xs={8}>
        <FormControl sx={{ ml: 1 }}>
          <FormLabel sx={{ my: -1 }}><Typography variant="caption">Tipos de Venta</Typography></FormLabel>
          <RadioGroup row value={status} onChange={e => setStatus(e.target.value as TStatus)} sx={{ mb: -1 }}>
            <FormControlLabel value="sales" label="Ventas" control={<Radio />} />
            <FormControlLabel value="quotations" label="Cotizaciones" control={<Radio />} />
            <FormControlLabel value="cancelations" label="Cancelaciones" control={<Radio />} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4} textAlign="right">
        <Button variant="contained" color="info" disabled={!startDate || !endDate || status !== 'sales'} onClick={handleDownloadSalesReportClick}>Reporte de Ventas</Button>
      </Grid>
      <Grid item xs={12}>
        <TreeTable
          entities={filteredSales}
          groupingProperty={'date'}
          groupingLevels={[(date: Date) => getDateYearString(date), (date: Date) => getDateYearMonthString(date), (date: Date) => getDateString(date)]}
          emptyLabel={'No se encontraron Ventas'}
          getEntityTotal={sale => getSaleTotal(sale, providerIds, productIds, productTypeIds, measureIds)}
          getEntityLink={s => `/ventas/${s.id}`}
          columns={{
            clientName: {
              name: 'Cliente'
            },
            businessUnitDescription: {
              name: 'Unidad de Negocio'
            },
            salePaymentMethods: {
              name: 'Forma de Pago',
              getValue: sps => sps.map(sp => sp.paymentMethodName).join(', ')
            }
          }}
          groupingColumn={{
            name: 'Fecha',
            type: 'date-time'
          }}
        />
      </Grid>
      {filteredSales.length > 0 && status === 'sales' && (
        <>
          <Grid item xs={6}>
            <IncomeStatement
              entity={filteredSales}
              items={[
                { name: 'Venta', isBold: true, getAmount: s => getSalesTotal(s, providerIds, productIds, productTypeIds, measureIds) },
                { name: 'Costo de Venta', getAmount: s => getCostOfSale(s, providerIds, productIds, productTypeIds, measureIds) },
                { name: 'Utilidad Bruta', isBold: true, getAmount: s => getGrossProfit(s, providerIds, productIds, productTypeIds, measureIds) },
                ...!providerIds.length && !productIds.length && !productTypeIds.length && !measureIds.length ? [{ name: 'Gastos Variables', getAmount: getVariableExpends }] : [],
                ...!providerIds.length && !productIds.length && !productTypeIds.length && !measureIds.length ? [{ name: 'Utilidad Neta', isBold: true, getAmount: getNetProfit }] : []
              ]}
            />
          </Grid>
          {!providerIds.length && !productIds.length && !productTypeIds.length && !measureIds.length && (
            <>
              <Grid item xs={3}>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  <ValueList
                    items={[
                      { name: 'Pagos en Efectivo', value: getCashPaymentsTotal(filteredSales), type: 'number', format: 'currency' },
                      { name: 'Pagos Electrónicos', value: getElectronicPaymentsTotal(filteredSales), type: 'number', format: 'currency' },
                      { name: 'Comisiones Bancarias', value: getCommissionsTotal(filteredSales), type: 'number', format: 'currency' },
                      { name: 'Depósitos Bancarios', value: getElectronicPaymentsTotal(filteredSales) - getCommissionsTotal(filteredSales), type: 'number', format: 'currency' }
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ display: 'flex', justifyContent: 'start', mt: 2 }}>
                  <ValueList
                    items={[
                      { name: 'Total de Ventas', value: getSalesTotal(filteredSales, [], [], [], []), type: 'number', format: 'currency' },
                      { name: 'Total de Ingresos', value: getSalesTotal(filteredSales, [], [], [], []) - getCommissionsTotal(filteredSales), type: 'number', format: 'currency' },
                      { name: 'Ticket Promedio', value: getAverageTicket(filteredSales), type: 'number', format: 'currency' },
                      { name: 'Promedio Diario', value: getDailyAverage(filteredSales, startDate!, endDate!), type: 'number', format: 'currency' }
                    ]}
                  />
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}