import { Purchase } from "../models/purchase"
import { httpDownload, httpGet, httpPost } from "./http-client";

const apiUrl = '/api/purchases';

export const getPendingPurchases = () => httpGet<Purchase[]>(`${apiUrl}/pending`);
export const getCompletedPurchases = () => httpGet<Purchase[]>(`${apiUrl}/completed`);

export const getPurchase = (id: number) => httpGet<Purchase>(`${apiUrl}/${id}`);
export const savePurchase = (purchase: Purchase) => httpPost<Purchase>(`${apiUrl}/savepurchase`, purchase);
export const completePurchase = (purchase: Purchase) => httpPost<Purchase>(`${apiUrl}/completepurchase`, purchase);
export const cancelPurchase = (purchase: Purchase) => httpPost<Purchase>(`${apiUrl}/cancelpurchase`, purchase);
export const downloadOrderFormat = (id: number) => httpDownload(`${apiUrl}/orderformat/${id}`);
