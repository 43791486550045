import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { TDEntity } from "../../core/utils/entity-types";

interface MultipleFilterProps<TEntity extends TDEntity> {
  entities: TEntity[];
  inputLabel: string;
  disabled?: boolean;
  getOptionKey: (entity: TEntity) => number;
  getOptionLabel: (entity: TEntity) => string;
  onChange: (selectedKeys: number[]) => void;
}

export default function MultipleFilter<TEntity extends TDEntity>({
  entities,
  inputLabel,
  disabled,
  getOptionKey,
  getOptionLabel,
  onChange
}: MultipleFilterProps<TEntity>) {
  const [selectedEntities, setSelectedEntities] = useState<TEntity[]>([]);
  
  useEffect(() => {
    if (selectedEntities.length) {
      const entityKeys = entities.map(e => getOptionKey(e));
      if (selectedEntities.find(e => !entityKeys.includes(getOptionKey(e)))) {
        setSelectedEntities(selectedEntities.filter(e => entityKeys.includes(getOptionKey(e))));
      }
    }
  }, [entities, selectedEntities]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChange(selectedEntities.map(e => getOptionKey(e)));
  }, [selectedEntities]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      multiple
      fullWidth
      filterSelectedOptions
      options={entities}
      disabled={disabled}
      value={selectedEntities.filter(e => entities.includes(e))}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(o, v) => getOptionKey(o) === getOptionKey(v)}
      onChange={(_, selectedEntities) => setSelectedEntities(selectedEntities)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
        />
      )}
    />
  );
};
