import { Loan } from "../models/loan";
import { httpDownload, httpGet, httpPost } from "./http-client";

const apiUrl = '/api/loans';

export const getLoans = () => httpGet<Loan[]>(apiUrl);
export const getLoan = (id: number) => httpGet<Loan>(`${apiUrl}/${id}`);
export const saveLoan = (loan: Loan) => httpPost<Loan>(`${apiUrl}/saveloan`, loan);
export const updateLoan = (loan: Loan) => httpPost<Loan>(`${apiUrl}/updateloan`, loan);
export const completeLoan = (loan: Loan) => httpPost<Loan>(`${apiUrl}/completeloan`, loan);
export const cancelLoan = (loan: Loan) => httpPost<Loan>(`${apiUrl}/cancelloan`, loan);
export const downloadLoanContract = (id: number) => httpDownload(`${apiUrl}/loancontract/${id}`);
