import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, Box, Button, Divider, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useNotifications } from "../../core/hooks/use-notifications";
import { BusinessUnit } from "../../core/models/business-unit";
import { ClientType } from "../../core/models/client-type";
import { Client } from "../../core/models/client";
import { getBusinessUnits } from "../../core/services/business-units";
import { getClientTypes } from "../../core/services/client-types";
import { createClient, getClient, updateClient } from "../../core/services/clients";
import { setEntityPropertyValue } from "../../core/services/entities";

const isNew = (client: Client) => client.id === 0;
const isSaved = (client: Client) => client.id > 0;

const getInitialClient = (): Client => ({
  id: 0,
  clientTypeId: 0,
  businessUnitId: 0,
  name: '',
  address: '',
  addressLink: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  active: true,
  clientType: null,
  businessUnit: null
});

const getSavingClient = (client: Client) => ({
  ...client,
  ...client.clientTypeId && { clientType: null },
  ...client.businessUnitId && { businessUnit: null }
});

const filterClientTypes = createFilterOptions<ClientType>();
const filterBusinessUnits = createFilterOptions<BusinessUnit>();

export default function ClientPage() {
  const { id } = useParams();

  const [client, setClient] = useState<Client>(getInitialClient());

  const [clientTypes, setClientTypes] = useState<ClientType[]>([]);
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);

  const form = useForm();
  const navigate = useNavigate();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    getClientTypes().then(clientTypes => setClientTypes(clientTypes)).catch(() => showErrorNotification('Error al cargar los Tipos de Cliente.'));
    getBusinessUnits().then(businessUnits => setBusinessUnits(businessUnits)).catch(() => showErrorNotification('Error al cargar las Unidades de Negocio.'));
  }, [showErrorNotification]);

  useEffect(() => {
    if (id) {
      getClient(+id)
        .then(client => {
          form.reset({
            name: client.name,
            address: client.address,
            addressLink: client.addressLink,
            contactName: client.contactName,
            contactPhone: client.contactPhone,
            contactEmail: client.contactEmail,
            clientType: client.clientType,
            businessUnit: client.businessUnit,
          });
          setClient(client);
        })
        .catch(() => showErrorNotification('Error al cargar el Cliente.'));
    } else {
      setClient(getInitialClient());
    }
  }, [id, form, showErrorNotification]);

  const handleCreateClientClick = () => {
    createClient(getSavingClient(client))
      .then(() => {
        navigate('/clientes');
        showSuccessNotification('Cliente registrado correctamente.');
      })
      .catch(() => showErrorNotification('Error al registrar el Cliente.'));
  };

  const handleUpdateClientClick = () => {
    updateClient(getSavingClient(client))
      .then(() => {
        navigate('/clientes');
        showSuccessNotification('Cliente actualizado correctamente.');
      })
      .catch(() => showErrorNotification('Error al actualizar el Cliente.'));
  };

  return (
    <Box>
      <FormProvider {...form}>
        <Grid container spacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos Generales</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="name" defaultValue={client.name} rules={{ required: true, maxLength: 50 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Nombre" error={invalid} value={client.name} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'name', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
              <Controller name="address" defaultValue={client.address} rules={{ maxLength: 100 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Dirección" error={invalid} value={client.address} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'address', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
              <Controller name="addressLink" defaultValue={client.addressLink} rules={{ maxLength: 100, validate: v => !v || v.trim().startsWith('https://goo.gl/maps/') }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Enlace de Mapas" error={invalid} value={client.addressLink} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'addressLink', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos de Contacto</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="contactName" defaultValue={client.contactName} rules={{ maxLength: 50 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Nombre" error={invalid} value={client.contactName} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'contactName', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="contactPhone" defaultValue={client.contactPhone} rules={{ maxLength: 50, validate: v => !v || /^\d{10}$/.test(v.replace(/\s/g, '')) }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Teléfono" error={invalid} value={client.contactPhone} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'contactPhone', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="contactEmail" defaultValue={client.contactEmail} rules={{ maxLength: 50, validate: v => !v || /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v.trim()) }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Correo Electrónico" error={invalid} value={client.contactEmail} onChange={e => { onChange(e); setClient(setEntityPropertyValue(client, 'contactEmail', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos Complementarios</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="clientType" defaultValue={client.clientType} rules={{ required: true }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <Autocomplete
                  freeSolo
                  fullWidth
                  clearOnBlur
                  value={client.clientType}
                  getOptionLabel={ct => ct.description}
                  isOptionEqualToValue={(cto, ctv) => cto.id === ctv.id}
                  options={clientTypes.length || !client.clientType ? clientTypes : [client.clientType]}
                  filterOptions={(options, state) => {
                    const filteredOptions = filterClientTypes(options, state);
                    return [
                      ...filteredOptions,
                      ...(state.inputValue && !filteredOptions.length ? [{ id: 0, description: `Agregar "${state.inputValue}"` }] : []),
                      ...(!state.inputValue && client.clientType?.id === 0 ? [{ id: 0, description: client.clientType.description }] : [])
                    ];
                  }}
                  onChange={(_, value) => {
                    const regexp = /^Agregar "(.+)"$/;
                    const objectValue = typeof value === 'string' ? { id: 0, description: `Agregar "${value}"` } : value;
                    const fixedValue = regexp.test(objectValue?.description ?? '') ? { id: 0, description: objectValue!.description.match(regexp)![1] } : objectValue;
                    onChange(fixedValue);
                    setClient(c => setEntityPropertyValue(c, 'clientType', fixedValue));
                    setClient(c => setEntityPropertyValue(c, 'clientTypeId', fixedValue?.id || 0));
                  }}
                  renderInput={p => <TextField {...p} label="Tipo de Cliente" error={invalid} />}
                />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="businessUnit" defaultValue={client.businessUnit} rules={{ required: true }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <Autocomplete
                  freeSolo
                  fullWidth
                  clearOnBlur
                  value={client.businessUnit}
                  getOptionLabel={bu => bu.description}
                  isOptionEqualToValue={(buo, buv) => buo.id === buv.id}
                  options={businessUnits.length || !client.businessUnit ? businessUnits : [client.businessUnit]}
                  filterOptions={(options, state) => {
                    const filteredOptions = filterBusinessUnits(options, state);
                    return [
                      ...filteredOptions,
                      ...(state.inputValue && !filteredOptions.length ? [{ id: 0, description: `Agregar "${state.inputValue}"`, active: true }] : []),
                      ...(!state.inputValue && client.businessUnit?.id === 0 ? [{ id: 0, description: client.businessUnit.description, active: true }] : [])
                    ];
                  }}
                  onChange={(_, value) => {
                    const regexp = /^Agregar "(.+)"$/;
                    const objectValue = typeof value === 'string' ? { id: 0, description: `Agregar "${value}"`, active: true } : value;
                    const fixedValue = regexp.test(objectValue?.description ?? '') ? { id: 0, description: objectValue!.description.match(regexp)![1], active: true } : objectValue;
                    onChange(fixedValue);
                    setClient(c => setEntityPropertyValue(c, 'businessUnit', fixedValue));
                    setClient(c => setEntityPropertyValue(c, 'businessUnitId', fixedValue?.id || 0));
                  }}
                  renderInput={p => <TextField {...p} label="Unidad de Negocio" error={invalid} />}
                />
              )} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel label="Cliente Activo" control={<Switch checked={client.active} onChange={(_, c) => setClient(setEntityPropertyValue(client, 'active', c))} />} />
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {isNew(client) && <Button variant="contained" color="success" onClick={form.handleSubmit(handleCreateClientClick)}>Guardar Préstamo</Button>}
              {isSaved(client) && <Button variant="contained" color="warning" onClick={form.handleSubmit(handleUpdateClientClick)}>Actualizar Préstamo</Button>}
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
}