import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { vatFactor } from "../../core/utils/app-constants";
import { TDEntity } from "../../core/utils/entity-types";
import NumberField from "./NumberField";

interface IncomeStatementProps<TEntity extends TDEntity> {
  entity: TEntity;
  items: IncomeStatementItemProps<TEntity>[];
}

interface IncomeStatementItemProps<TEntity extends TDEntity> {
  name: string;
  isBold?: boolean;
  getAmount: (entity: TEntity) => number;
}

export default function IncomeStatement<TEntity extends TDEntity>({
  entity,
  items
}: IncomeStatementProps<TEntity>) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right"><Typography fontWeight="bold">Porcentage</Typography></TableCell>
          <TableCell align="right"><Typography fontWeight="bold">Subtotal</Typography></TableCell>
          <TableCell align="right"><Typography fontWeight="bold">Total</Typography></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography sx={{ ...item.isBold && { fontWeight: 'bold' } }}>{item.name}</Typography>
            </TableCell>
            <TableCell align="right" sx={{ ...item.isBold && { fontWeight: 'bold' } }}>
              <NumberField type="text" format="percentage" value={item.getAmount(entity) / items[0].getAmount(entity) * 100} />
            </TableCell>
            <TableCell align="right" sx={{ ...item.isBold && { fontWeight: 'bold' } }}>
              <NumberField type="text" format="currency" value={item.getAmount(entity) / vatFactor} />
            </TableCell>
            <TableCell align="right" sx={{ ...item.isBold && { fontWeight: 'bold' } }}>
              <NumberField type="text" format="currency" value={item.getAmount(entity)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};