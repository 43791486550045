import { trackPromise } from 'react-promise-tracker';
import { TinyEmitter } from 'tiny-emitter';

const unauthorizedStatusCode = 401;

const usersApiUrl = '/api/users';
const loginApiUrl = '/api/users/getaccesstoken';

export const subscriptionsCompletedEvent = 'SubscriptionsCompleted';
export const accessTokenSetEvent = 'AccessTokenSet';
export const beginLoginEvent = 'BeginLogin';
export const completeLoginEvent = 'CompleteLogin';

export const emitter = new TinyEmitter();

let _subscriptionsCompleted = false;
export const completeSubscriptions = () => {
  _subscriptionsCompleted = true;
  emitter.emit(subscriptionsCompletedEvent);
};

let _accessToken = '';
export const setAccessToken = (accessToken: string) => {
  _accessToken = accessToken;
  emitter.emit(accessTokenSetEvent, accessToken);
};

export const httpGet = <T>(url: string) => {
  return httpFetchObject<T>(url);
};

export const httpPost = <T>(url: string, object: any) => {
  return httpFetchObject<T>(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: object && JSON.stringify(object)
  });
};

export const httpDownload = (url: string) => {
  return httpFetchBlob(url);
};

const httpFetchObject = async <T>(url: string, requestInit?: RequestInit): Promise<T> => {
  const response = await httpFetch(url, requestInit);
  const text = await response.text();
  return text ? JSON.parse(text) : undefined;
};

const httpFetchBlob = async (url: string, requestInit?: RequestInit): Promise<{ name: string, data: Blob }> => {
  const response = await httpFetch(url, requestInit);
  return {
    name: decodeURI(response.headers.get('Content-Disposition')?.match(/filename\*=UTF-8''(.+\..+)/)?.[1] ?? ''),
    data: await response.blob()
  };
};

const httpFetch = async (url: string, requestInit?: RequestInit): Promise<Response> => {
  if (!_subscriptionsCompleted) {
    await new Promise(resolve => emitter.once(subscriptionsCompletedEvent, resolve));
  }

  const response = await trackPromise(fetch(url, {
    ...requestInit,
    headers: {
      ...requestInit?.headers,
      ..._accessToken && { 'Authorization': `Bearer ${_accessToken}` }
    }
  }));

  if (response.status === unauthorizedStatusCode && !url.startsWith(usersApiUrl)) {
    setAccessToken('');
    emitter.emit(beginLoginEvent);
    if (await new Promise(resolve => emitter.once(completeLoginEvent, resolve))) {
      return await httpFetch(url, requestInit);
    }
  }

  if (response.ok && url.startsWith(loginApiUrl)) {
    setAccessToken(await response.clone().json());
    emitter.emit(completeLoginEvent, true);
  }

  if (response.ok) {
    return response;
  } else {
    throw new Error(JSON.stringify({
      url: response.url,
      status: response.status,
      statusText: response.statusText
    }));
  }
};
