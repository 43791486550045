import { TextField, Typography } from "@mui/material";
import ReactNumberFormat, { NumberFormatValues } from "react-number-format";
import { TNumberFormat, TNumberType } from "../../core/utils/util-types";

const formatProperties: {
  [key: string]: {
    prefix?: string;
    suffix?: string;
    decimalScale?: number;
    width?: number;
    isAllowed?: (values: NumberFormatValues) => boolean
  }
} = {
  'currency': {
    prefix: '$',
    decimalScale: 2,
    width: 85
  },
  'tight-currency': {
    prefix: '$',
    decimalScale: 2,
    width: 50
  },
  'percentage': {
    suffix: '%',
    decimalScale: 2,
    width: 65,
    isAllowed: v => v.floatValue! <= 100
  },
  'integer': {
    width: 30
  },
  'fraction': {
    decimalScale: 3,
    width: 50
  },
  'kilometers': {
    suffix: ' ㎞',
    width: 50
  },
  'fuel-efficiency': {
    suffix: ' ᵏᵐ⁄ₗ',
    width: 50
  },
  'hours': {
    suffix: ' h',
    decimalScale: 2,
    width: 55
  }
};

interface NumberFieldProps {
  type: TNumberType;
  format: TNumberFormat;
  value: number;
  label?: string;
  size?: 'xsmall';
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  inputRef?: React.Ref<any>;
  onChange?: (value: number) => void;
}

const CustomTextField = (props: any) => <TextField {...props} size="small" />

export default function NumberField({
  type,
  format,
  value,
  label,
  size,
  disabled,
  error,
  warning,
  inputRef,
  onChange
}: NumberFieldProps) {
  if (type === 'text') {
    return (
      <Typography variant={size === 'xsmall' ? 'body2' : 'body1'} sx={{ fontWeight: 'inherit', color: theme => error ? theme.palette.error.main : theme.palette.text.primary }}>
        {`${value < 0 ? '-' : ''}${formatProperties[format].prefix || ''}${value.toLocaleString('en', {
          minimumFractionDigits: formatProperties[format].decimalScale,
          maximumFractionDigits: formatProperties[format].decimalScale,
          signDisplay: "never"
        })}${formatProperties[format].suffix || ''}`}
      </Typography>);
  } else {
    return (
      <ReactNumberFormat
        prefix={formatProperties[format].prefix}
        suffix={formatProperties[format].suffix}
        decimalScale={formatProperties[format].decimalScale}
        isAllowed={formatProperties[format].isAllowed}
        value={value}
        label={label}
        onValueChange={(v, s) => v.value !== '' && s.source === 'event' && onChange?.(+v.value)}
        onFocus={e => e.target.select()}
        thousandSeparator={','}
        fixedDecimalScale={true}
        allowNegative={false}
        autoComplete="off"
        customInput={CustomTextField}
        disabled={disabled}
        error={error}
        color={warning ? 'warning' : undefined}
        inputRef={inputRef}
        inputProps={{
          style: {
            textAlign: 'right',
            fontSize: size === 'xsmall' ? 14 : undefined,
            width: formatProperties[format].width
          }
        }}
      />
    );
  }
};