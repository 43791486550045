import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { Close } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useNotifications } from "../../core/hooks/use-notifications";
import { useProductsFilter } from "../../core/hooks/use-products-filter";
import { Measure } from "../../core/models/measure";
import { PriceType } from "../../core/models/price-type";
import { Product } from "../../core/models/product";
import { ProductType } from "../../core/models/product-type";
import { Provider } from "../../core/models/provider";
import { StockProduct } from "../../core/models/stock-product";
import { getMeasures } from "../../core/services/measures";
import { compareToCents, limitPercentage, limitPossitive } from "../../core/services/numbers";
import { getPriceTypes } from "../../core/services/price-types";
import { getProducts } from "../../core/services/products";
import { getProductTypes } from "../../core/services/product-types";
import { getProviders } from "../../core/services/providers";
import { getStockProducts, updateStockProductPrices } from "../../core/services/stock-products";
import { berelProviderId, divisionPriceTypeId, fixedPriceTypeId, profitPriceTypeId, vatFactor } from "../../core/utils/app-constants";
import { TDProperty, TDPropertyValue } from "../../core/utils/entity-types";
import MultipleFilter from "../../shared/components/MultipleFilter";
import NumberField from "../../shared/components/NumberField";

interface UpdatingStockProduct {
  selected: boolean;
  priceTypeId: number;
  priceProfit: number;
  purchasePrice: number;
  purchaseDiscount: number;
  salePrice: number;
  saleDiscount: number;
  stockProduct: StockProduct;
  biggerStockProduct: StockProduct | null;
  smallerStockProducts: StockProduct[] | null;
}

interface ProductPricesUpdatingRowProps {
  priceTypes: PriceType[];
  updatingStockProduct: UpdatingStockProduct;
  setUpdatingStockProducts: (getUpdatingStockProducts: (updatingStockProducts: UpdatingStockProduct[]) => UpdatingStockProduct[]) => void;
}

interface ProductPricesReviewDialogProps {
  priceTypes: PriceType[];
  updatingStockProducts: UpdatingStockProduct[];
  reviewing: boolean;
  cancelReview: () => void;
  confirmUpdate: () => void;
}

const canShowStockProducts = (
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[]
) => !!((providerIds.length && !providerIds.includes(berelProviderId)) || productIds.length || productTypeIds.length || measureIds.length);

const stockProductPredicate = (
  stockProduct: StockProduct,
  providerIds: number[],
  productIds: number[],
  productTypeIds: number[],
  measureIds: number[]
) =>
  (!providerIds.length || providerIds.includes(stockProduct.providerId)) &&
  (!productIds.length || productIds.includes(stockProduct.productId)) &&
  (!productTypeIds.length || productTypeIds.includes(stockProduct.productTypeId)) &&
  (!measureIds.length || measureIds.includes(stockProduct.measureId));

const createUpdatingStockProduct = (stockProducts: StockProduct[], stockProduct: StockProduct): UpdatingStockProduct => {
  const relatedStockProducts = stockProducts.filter(sp => sp.productId === stockProduct.productId && sp.productTypeId === stockProduct.productTypeId);
  const biggerStockProduct = relatedStockProducts.filter(sp => sp.measureUnitsNumber > stockProduct.measureUnitsNumber).sort((spA, spB) => spA.measureUnitsNumber - spB.measureUnitsNumber)[0] ?? null;
  const smallerStockProducts = relatedStockProducts.filter(sp => sp.measureUnitsNumber < stockProduct.measureUnitsNumber).sort((spA, spB) => spB.measureUnitsNumber - spA.measureUnitsNumber);
  return {
    selected: false,
    priceTypeId: stockProduct.priceTypeId,
    priceProfit: stockProduct.priceProfit,
    purchasePrice: stockProduct.purchasePrice,
    purchaseDiscount: stockProduct.purchaseDiscount,
    salePrice: stockProduct.salePrice,
    saleDiscount: getPercentageSaleDiscount(stockProduct),
    stockProduct: stockProduct,
    biggerStockProduct: biggerStockProduct,
    smallerStockProducts: smallerStockProducts
  }
};

const updateUpdatingStockProducts = <
  TProperty extends TDProperty<UpdatingStockProduct>,
  TPropertyValue extends TDPropertyValue<UpdatingStockProduct, TProperty>
>(updatingStockProducts: UpdatingStockProduct[], id: number, property: TProperty, value: TPropertyValue) => {
  const selected = updatingStockProducts.find(sp => sp.stockProduct.id === id)?.selected;
  const updatedSalePrices: { [key: number]: number } = {};

  return updatingStockProducts.flatMap(sp => {
    if (
      (
        (sp.stockProduct.id === id || (selected && sp.selected && property !== 'selected')) &&
        (
          (property === 'priceProfit' && (sp.priceTypeId === profitPriceTypeId || sp.priceTypeId === divisionPriceTypeId)) ||
          (property === 'purchasePrice' && sp.priceTypeId === profitPriceTypeId) ||
          (property === 'salePrice')
        )
      ) ||
      (updatedSalePrices[sp.stockProduct.id] !== undefined)
    ) {
      const smallerStockProducts = sp.smallerStockProducts ?? [];

      let continueFinding = true;
      const missingStockProducts = smallerStockProducts.filter(sp => {
        if (continueFinding) {
          if (sp.priceTypeId === divisionPriceTypeId) {
            const smallerUpdatingStockProduct = updatingStockProducts.find(usp => usp.stockProduct.id === sp.id);
            if (smallerUpdatingStockProduct) {
              continueFinding = false;
              updatedSalePrices[sp.id] = smallerUpdatingStockProduct.salePrice;
            }
          } else {
            continueFinding = false;
          }
        }
        return continueFinding;
      });

      const relatedStockProducts = [sp.stockProduct, ...smallerStockProducts];
      const missingUpdatingStockProducts = missingStockProducts.map(sp => createUpdatingStockProduct(relatedStockProducts, sp));

      return [sp, ...missingUpdatingStockProducts];
    }
    return sp;
  }).map(sp => {
    if (
      (sp.stockProduct.id === id || (selected && sp.selected && property !== 'selected')) ||
      (sp.priceTypeId === divisionPriceTypeId && sp.biggerStockProduct && updatedSalePrices[sp.biggerStockProduct.id] !== undefined)
    ) {
      const updatedStockProduct = {
        ...sp,
        ...(sp.stockProduct.id === id || (selected && sp.selected && property !== 'selected')) ? {
          [property]: value,
          ...property === 'priceTypeId' && {
            ...value === fixedPriceTypeId && { priceProfit: 0 },
            ...value === profitPriceTypeId && { priceProfit: getProfitPriceProfit(sp.purchasePrice, sp.salePrice) },
          },
          ...property === 'priceProfit' && {
            ...sp.priceTypeId === fixedPriceTypeId && { priceProfit: 0 },
            ...sp.priceTypeId === profitPriceTypeId && { salePrice: getProfitSalePrice(sp.purchasePrice, value as number) },
          },
          ...property === 'purchasePrice' && {
            ...sp.priceTypeId === profitPriceTypeId && { salePrice: getProfitSalePrice(value as number, sp.priceProfit) },
          },
          ...property === 'salePrice' && {
            ...sp.priceTypeId === profitPriceTypeId && { priceProfit: getProfitPriceProfit(sp.purchasePrice, value as number) },
          },
          ...sp.biggerStockProduct ? {
            ...property === 'priceTypeId' && {
              ...value === divisionPriceTypeId && {
                priceProfit: getDivisionPriceProfit(
                  getBiggerSalePrice(sp.biggerStockProduct, updatingStockProducts, updatedSalePrices),
                  getBiggerUnitsNumber(sp.biggerStockProduct),
                  sp.salePrice,
                  sp.stockProduct.measureUnitsNumber
                ),
                purchasePrice: 0
              }
            },
            ...property === 'priceProfit' && {
              ...sp.priceTypeId === divisionPriceTypeId && {
                salePrice: getDivisionSalePrice(
                  getBiggerSalePrice(sp.biggerStockProduct, updatingStockProducts, updatedSalePrices),
                  getBiggerUnitsNumber(sp.biggerStockProduct),
                  sp.stockProduct.measureUnitsNumber,
                  value as number
                )
              }
            },
            ...property === 'purchasePrice' && {
              ...sp.priceTypeId === divisionPriceTypeId && { purchasePrice: 0 }
            },
            ...property === 'salePrice' && {
              ...sp.priceTypeId === divisionPriceTypeId && {
                priceProfit: getDivisionPriceProfit(
                  getBiggerSalePrice(sp.biggerStockProduct, updatingStockProducts, updatedSalePrices),
                  getBiggerUnitsNumber(sp.biggerStockProduct),
                  value as number,
                  sp.stockProduct.measureUnitsNumber
                )
              }
            },
          } : {
            ...property === 'priceTypeId' && {
              ...value === divisionPriceTypeId && { priceTypeId: sp.priceTypeId }
            }
          }
        } : {
          salePrice: getDivisionSalePrice(
            getBiggerSalePrice(sp.biggerStockProduct!, updatingStockProducts, updatedSalePrices),
            getBiggerUnitsNumber(sp.biggerStockProduct!),
            sp.stockProduct.measureUnitsNumber,
            sp.priceProfit
          )
        }
      };

      if (updatedStockProduct.salePrice !== sp.salePrice) {
        updatedSalePrices[sp.stockProduct.id] = updatedStockProduct.salePrice;
      }

      return updatedStockProduct;
    } else {
      return sp;
    }
  });
};

const isStockProductUpdated = (updatingStockProduct: UpdatingStockProduct) =>
  updatingStockProduct.priceTypeId !== updatingStockProduct.stockProduct.priceTypeId ||
  !compareToCents(updatingStockProduct.priceProfit, updatingStockProduct.stockProduct.priceProfit) ||
  !compareToCents(updatingStockProduct.purchasePrice, updatingStockProduct.stockProduct.purchasePrice) ||
  !compareToCents(updatingStockProduct.purchaseDiscount, updatingStockProduct.stockProduct.purchaseDiscount) ||
  !compareToCents(updatingStockProduct.salePrice, updatingStockProduct.stockProduct.salePrice) ||
  !compareToCents(updatingStockProduct.saleDiscount, getPercentageSaleDiscount(updatingStockProduct.stockProduct));

const getPercentageSaleDiscount = (stockProduct: StockProduct) => limitPercentage(stockProduct.saleDiscount / stockProduct.salePrice * 100);
const getCurrencySaleDiscount = (updatingStockProduct: UpdatingStockProduct) => limitPossitive(updatingStockProduct.salePrice * updatingStockProduct.saleDiscount / 100);

const getProfitPriceProfit = (purchasePrice: number, salePrice: number) => limitPercentage((1 - purchasePrice / salePrice * vatFactor) * 100);
const getProfitSalePrice = (purchasePrice: number, priceProfit: number) => limitPossitive(purchasePrice / (1 - priceProfit / 100) * vatFactor);

const getDivisionPriceProfit = (biggerSalePrice: number, biggerUnitsNumber: number, salePrice: number, unitsNumber: number) =>
  limitPercentage((1 - biggerSalePrice / biggerUnitsNumber / salePrice * unitsNumber) * 100);
const getDivisionSalePrice = (biggerSalePrice: number, biggerUnitsNumber: number, unitsNumber: number, priceProfit: number) =>
  limitPossitive(biggerSalePrice / biggerUnitsNumber * unitsNumber / (1 - priceProfit / 100));

const getBiggerSalePrice = (biggerStockProduct: StockProduct, updatingStockProducts: UpdatingStockProduct[], updatedSalePrices: { [key: number]: number }) =>
  updatedSalePrices[biggerStockProduct.id] ?? updatingStockProducts.find(sp => sp.stockProduct.id === biggerStockProduct.id)?.salePrice ?? biggerStockProduct.salePrice;
const getBiggerUnitsNumber = (biggerStockProduct: StockProduct) => biggerStockProduct.measureUnitsNumber;

export default function ProductPrices() {
  const [stockProducts, setStockProducts] = useState<StockProduct[]>([]);
  const [filteredStockProducts, setFilteredStockProducts] = useState<StockProduct[]>([]);
  const [updatingStockProducts, setUpdatingStockProducts] = useState<UpdatingStockProduct[]>([]);

  const [providers, setProviders] = useState<Provider[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [measures, setMeasures] = useState<Measure[]>([]);
  const [priceTypes, setPriceTypes] = useState<PriceType[]>([]);

  const [providerIds, setProviderIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [productTypeIds, setProductTypeIds] = useState<number[]>([]);
  const [measureIds, setMeasureIds] = useState<number[]>([]);

  const [updating, setUpdating] = useState<boolean>(false);
  const [reviewing, setReviewing] = useState<boolean>(false);

  const [updatedCount, setUpdatedCount] = useState<number>(0);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const [purchasePriceButton, setPurchasePriceButton] = useState<boolean>(false);
  const [salePriceButton, setSalePriceButton] = useState<boolean>(false);

  const form = useForm();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const { filteredProducts, filteredProductTypes, filteredMeasures } = useProductsFilter(products, productTypes, measures, stockProducts, providerIds, productIds, productTypeIds);

  useEffect(() => {
    getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos de Inventario.'));
    getProviders().then(providers => setProviders(providers)).catch(() => showErrorNotification('Error al cargar los Proveedores.'));
    getProducts().then(products => setProducts(products)).catch(() => showErrorNotification('Error al cargar los Productos.'));
    getProductTypes().then(productTypes => setProductTypes(productTypes)).catch(() => showErrorNotification('Error al cargar los Tipos de Producto.'));
    getMeasures().then(measures => setMeasures(measures)).catch(() => showErrorNotification('Error al cargar las Medidas.'));
    getPriceTypes().then(priceTypes => setPriceTypes(priceTypes)).catch(() => showErrorNotification('Error al cargar los Tipos de Precio.'));
  }, [showErrorNotification]);

  useEffect(() => {
    setFilteredStockProducts(canShowStockProducts(providerIds, productIds, productTypeIds, measureIds) ? stockProducts.filter(sp => stockProductPredicate(sp, providerIds, productIds, productTypeIds, measureIds)) : []);
  }, [stockProducts, providerIds, productIds, productTypeIds, measureIds]);

  useEffect(() => {
    setUpdatingStockProducts(updating ? filteredStockProducts.map(sp => createUpdatingStockProduct(stockProducts, sp)) : []);
  }, [updating, stockProducts, filteredStockProducts]);

  useEffect(() => {
    const updatedStockProducts = updatingStockProducts.filter(sp => isStockProductUpdated(sp));
    const selectedStockProducts = updatingStockProducts.filter(sp => sp.selected);

    setUpdatedCount(updatedStockProducts.length);
    setSelectedCount(selectedStockProducts.length);

    setPurchasePriceButton(selectedStockProducts.length > 0 && selectedStockProducts.every(sp =>
      sp.stockProduct.productId === selectedStockProducts[0].stockProduct.productId &&
      sp.stockProduct.measureId === selectedStockProducts[0].stockProduct.measureId &&
      compareToCents(sp.purchasePrice, selectedStockProducts[0].purchasePrice)));
    setSalePriceButton(selectedStockProducts.length > 0 && selectedStockProducts.every(sp =>
      sp.stockProduct.productId === selectedStockProducts[0].stockProduct.productId &&
      sp.stockProduct.measureId === selectedStockProducts[0].stockProduct.measureId &&
      compareToCents(sp.salePrice, selectedStockProducts[0].salePrice)));
  }, [updatingStockProducts]);

  const selectAllStockProducts = (selected: boolean) => setUpdatingStockProducts(updatingStockProducts.map(sp => ({ ...sp, selected })));

  const selectStockProductsByPurchasePrice = () => {
    const selectedStockProduct = updatingStockProducts.find(sp => sp.selected);
    setUpdatingStockProducts(updatingStockProducts.map(sp => {
      return sp.stockProduct.productId === selectedStockProduct?.stockProduct.productId &&
        sp.stockProduct.measureId === selectedStockProduct?.stockProduct.measureId &&
        compareToCents(sp.purchasePrice, selectedStockProduct?.purchasePrice) ? { ...sp, selected: true } : sp;
    }));
  };

  const selectStockProductsBySalePrice = () => {
    const selectedStockProduct = updatingStockProducts.find(sp => sp.selected);
    setUpdatingStockProducts(updatingStockProducts.map(sp => {
      return sp.stockProduct.productId === selectedStockProduct?.stockProduct.productId &&
        sp.stockProduct.measureId === selectedStockProduct?.stockProduct.measureId &&
        compareToCents(sp.salePrice, selectedStockProduct?.salePrice) ? { ...sp, selected: true } : sp;
    }));
  };

  const startUpdate = () => setUpdating(true);
  const startReview = () => setReviewing(true);
  const cancelUpdate = () => setUpdating(false);
  const cancelReview = () => setReviewing(false);

  const confirmUpdate = () => {
    updateStockProductPrices(updatingStockProducts.map(sp => ({
      ...sp.stockProduct,
      priceTypeId: sp.priceTypeId,
      priceProfit: sp.priceProfit,
      purchasePrice: sp.purchasePrice,
      purchaseDiscount: sp.purchaseDiscount,
      salePrice: sp.salePrice,
      saleDiscount: getCurrencySaleDiscount(sp)
    })))
      .then(() => {
        form.reset();
        setUpdating(false);
        setReviewing(false);
        showSuccessNotification('Productos actualizados correctamente.');
        getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos de Inventario.'));
      })
      .catch(() => showErrorNotification('Error al actualizar los productos.'));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <MultipleFilter
          entities={providers}
          inputLabel={'Proveedores'}
          disabled={updating}
          getOptionKey={p => p.id}
          getOptionLabel={p => p.name}
          onChange={pi => setProviderIds(pi)}
        />
      </Grid>
      <Grid item xs={3}>
        <MultipleFilter
          entities={filteredProducts}
          inputLabel={'Productos'}
          disabled={updating}
          getOptionKey={p => p.id}
          getOptionLabel={p => p.name}
          onChange={pi => setProductIds(pi)}
        />
      </Grid>
      <Grid item xs={3}>
        <MultipleFilter
          entities={filteredProductTypes}
          inputLabel={'Tipos de Producto'}
          disabled={updating}
          getOptionKey={pt => pt.id}
          getOptionLabel={pt => pt.fullName}
          onChange={pti => setProductTypeIds(pti)}
        />
      </Grid>
      <Grid item xs={3}>
        <MultipleFilter
          entities={filteredMeasures}
          inputLabel={'Medidas'}
          disabled={updating}
          getOptionKey={m => m.id}
          getOptionLabel={m => m.name}
          onChange={mi => setMeasureIds(mi)}
        />
      </Grid>
      <Grid item xs={12}>
        {updating ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="contained" color="secondary" disabled={!updatedCount} onClick={form.handleSubmit(startReview)}>Revisar Actualización</Button>
            <Button variant="contained" color="error" onClick={cancelUpdate}>Cancelar Actualización</Button>
            {updatedCount > 0 && <Typography fontWeight="bold" color={theme => theme.palette.success.dark}>{updatedCount} Producto{updatedCount > 1 && 's'} Actualizado{updatedCount > 1 && 's'}...</Typography>}
            {selectedCount > 0 && <Typography fontWeight="bold" color={theme => theme.palette.primary.dark}>{selectedCount} Producto{selectedCount > 1 && 's'} Seleccionado{selectedCount > 1 && 's'}...</Typography>}
          </Stack>
        ) : (
          <Button variant="contained" disabled={filteredStockProducts.length === 0} onClick={startUpdate}>Iniciar Actualización</Button>
        )}
        <ProductPricesReviewDialog
          priceTypes={priceTypes}
          updatingStockProducts={updatingStockProducts}
          reviewing={reviewing}
          cancelReview={cancelReview}
          confirmUpdate={confirmUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              {updating && (
                <TableCell>
                  <Checkbox color="info" checked={selectedCount > 0} indeterminate={selectedCount > 0 && selectedCount < updatingStockProducts.length} onChange={e => selectAllStockProducts(e.target.checked)} sx={{ m: -1 }} />
                </TableCell>
              )}
              <TableCell><Typography fontWeight="bold">Producto</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Tipo de Precio</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Utilidad de Precio</Typography></TableCell>
              <TableCell>
                {purchasePriceButton ? (
                  <Tooltip title="Seleccionar elementos con mismo Producto, Medida y Precio de Compra">
                    <Button color="info" onClick={selectStockProductsByPurchasePrice} sx={{ p: 0, fontWeight: 'bold' }}>Precio de Compra</Button>
                  </Tooltip>
                ) : (
                  <Typography fontWeight="bold" align="center">Precio de Compra</Typography>
                )}
              </TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Descuento de Compra</Typography></TableCell>
              <TableCell>
                {salePriceButton ? (
                  <Tooltip title="Seleccionar elementos con mismo Producto, Medida y Precio de Venta">
                    <Button color="info" onClick={selectStockProductsBySalePrice} sx={{ p: 0, fontWeight: 'bold' }}>Precio de Venta</Button>
                  </Tooltip>
                ) : (
                  <Typography fontWeight="bold" align="center">Precio de Venta</Typography>
                )}
              </TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Descuento de Venta</Typography></TableCell>
            </TableRow>
          </TableHead>
          {updating ? (
            <TableBody>
              <FormProvider {...form}>
                {updatingStockProducts.map(updatingStockProduct => (
                  <ProductPricesUpdatingItem
                    key={updatingStockProduct.stockProduct.id}
                    priceTypes={priceTypes}
                    updatingStockProduct={updatingStockProduct}
                    setUpdatingStockProducts={setUpdatingStockProducts}
                  />
                ))}
              </FormProvider>
            </TableBody>
          ) : (
            <TableBody>
              {filteredStockProducts.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <Typography>{canShowStockProducts(providerIds, productIds, productTypeIds, measureIds) ? 'No se encontraron Productos' : 'Seleccionar más filtros de búsqueda'}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {filteredStockProducts.map(sp => (
                <TableRow key={sp.id}>
                  <TableCell>
                    <Typography>{sp.fullName}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{sp.priceTypeDescription}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="percentage" value={sp.priceProfit} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={sp.purchasePrice} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="percentage" value={sp.purchaseDiscount} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={sp.salePrice} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="percentage" value={getPercentageSaleDiscount(sp)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Grid >
    </Grid >
  );
};

function ProductPricesUpdatingItem({
  priceTypes,
  updatingStockProduct,
  setUpdatingStockProducts
}: ProductPricesUpdatingRowProps) {
  const formItemName = `stockProduct-${updatingStockProduct.stockProduct.id}`;

  const formContext = useFormContext();

  useEffect(() => {
    formContext.setValue(`${formItemName}-priceProfit`, {
      priceTypeId: updatingStockProduct.priceTypeId,
      priceProfit: updatingStockProduct.priceProfit
    }, {
      shouldValidate: formContext.formState.isSubmitted
    });
  }, [updatingStockProduct.priceTypeId, updatingStockProduct.priceProfit, formItemName, formContext]);

  useEffect(() => {
    formContext.setValue(`${formItemName}-purchasePrice`, {
      priceTypeId: updatingStockProduct.priceTypeId,
      purchasePrice: updatingStockProduct.purchasePrice
    }, {
      shouldValidate: formContext.formState.isSubmitted
    });
  }, [updatingStockProduct.priceTypeId, updatingStockProduct.purchasePrice, formItemName, formContext]);

  useEffect(() => {
    formContext.setValue(`${formItemName}-salePrice`, {
      salePrice: updatingStockProduct.salePrice
    }, {
      shouldValidate: formContext.formState.isSubmitted
    });
  }, [updatingStockProduct.salePrice, formItemName, formContext]);

  const setPropertyValue = useCallback(<
    TProperty extends TDProperty<UpdatingStockProduct>,
    TPropertyValue extends TDPropertyValue<UpdatingStockProduct, TProperty>
  >(
    id: number,
    property: TProperty,
    value: TPropertyValue
  ) => setUpdatingStockProducts(updatingStockProducts => updateUpdatingStockProducts(updatingStockProducts, id, property, value)), [setUpdatingStockProducts]);

  return (
    <TableRow selected={updatingStockProduct.selected} sx={{ backgroundColor: theme => isStockProductUpdated(updatingStockProduct) ? `${theme.palette.success.main}14` : undefined }}>
      <TableCell>
        <Checkbox checked={updatingStockProduct.selected} onChange={e => setPropertyValue(updatingStockProduct.stockProduct.id, 'selected', e.target.checked)} sx={{ m: -1 }} />
      </TableCell>
      <TableCell>
        <Typography>{updatingStockProduct.stockProduct.fullName}</Typography>
      </TableCell>
      <TableCell>
        <TextField select fullWidth size="small" value={updatingStockProduct.priceTypeId} onChange={e => setPropertyValue(updatingStockProduct.stockProduct.id, 'priceTypeId', +e.target.value)}>
          {priceTypes.filter(pt => pt.id < divisionPriceTypeId || updatingStockProduct.biggerStockProduct).map(pt => (
            <MenuItem key={pt.id} value={pt.id}>{pt.description}</MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell align="center">
        <Controller shouldUnregister name={`${formItemName}-priceProfit`} rules={{ validate: sp => sp.priceTypeId === fixedPriceTypeId || sp.priceProfit > 0 }} render={({ fieldState: { invalid } }) => (
          <NumberField type="write" format="percentage" disabled={(updatingStockProduct.priceTypeId) === fixedPriceTypeId} error={invalid} value={updatingStockProduct.priceProfit} onChange={v => setPropertyValue(updatingStockProduct.stockProduct.id, 'priceProfit', v)} />
        )} />
      </TableCell>
      <TableCell align="center">
        <Controller shouldUnregister name={`${formItemName}-purchasePrice`} rules={{ validate: sp => sp.priceTypeId === divisionPriceTypeId || sp.purchasePrice > 0 }} render={({ fieldState: { invalid } }) => (
          <NumberField type="write" format="currency" disabled={(updatingStockProduct.priceTypeId) === divisionPriceTypeId} error={invalid} value={updatingStockProduct.purchasePrice} onChange={v => setPropertyValue(updatingStockProduct.stockProduct.id, 'purchasePrice', v)} />
        )} />
      </TableCell>
      <TableCell align="center">
        <NumberField type="write" format="percentage" value={updatingStockProduct.purchaseDiscount} onChange={v => setPropertyValue(updatingStockProduct.stockProduct.id, 'purchaseDiscount', v)} />
      </TableCell>
      <TableCell align="center">
        <Controller shouldUnregister name={`${formItemName}-salePrice`} rules={{ validate: sp => sp.salePrice > 0 }} render={({ fieldState: { invalid } }) => (
          <NumberField type="write" format="currency" warning={updatingStockProduct.priceTypeId !== fixedPriceTypeId} error={invalid} value={updatingStockProduct.salePrice} onChange={v => setPropertyValue(updatingStockProduct.stockProduct.id, 'salePrice', v)} />
        )} />
      </TableCell>
      <TableCell align="center">
        <NumberField type="write" format="percentage" value={updatingStockProduct.saleDiscount} onChange={v => setPropertyValue(updatingStockProduct.stockProduct.id, 'saleDiscount', v)} />
      </TableCell>
    </TableRow>
  );
};

function ProductPricesReviewDialog({
  priceTypes,
  updatingStockProducts,
  reviewing,
  cancelReview,
  confirmUpdate
}: ProductPricesReviewDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xl" open={reviewing} onClose={cancelReview}>
      <DialogTitle>
        Revisión de Actualización de Precios
        <IconButton onClick={cancelReview} sx={{ position: 'absolute', top: 8, right: 8, color: (theme) => theme.palette.grey[500], }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography fontWeight="bold">Producto</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Tipo de Precio</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Utilidad de Precio</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Precio de Compra</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Descuento de Compra</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Precio de Venta</Typography></TableCell>
              <TableCell><Typography fontWeight="bold" align="center">Descuento de Venta</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatingStockProducts.filter(sp => isStockProductUpdated(sp)).map(sp => (
              <TableRow key={sp.stockProduct.id}>
                <TableCell>
                  <Typography>{sp.stockProduct.fullName}</Typography>
                </TableCell>
                <TableCell align="center">
                  {sp.priceTypeId === sp.stockProduct.priceTypeId ? (
                    <Typography>{priceTypes.find(pt => pt.id === sp.priceTypeId)?.description}</Typography>
                  ) : (
                    <>
                      <Typography bgcolor={theme => `${theme.palette.error.main}40`}>{sp.stockProduct.priceTypeDescription}</Typography>
                      <Typography bgcolor={theme => `${theme.palette.success.main}40`}>{priceTypes.find(pt => pt.id === sp.priceTypeId)?.description}</Typography>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {compareToCents(sp.priceProfit, sp.stockProduct.priceProfit) ? (
                    <NumberField type="text" format="percentage" value={sp.priceProfit} />
                  ) : (
                    <>
                      <Box bgcolor={theme => `${theme.palette.error.main}40`}>
                        <NumberField type="text" format="percentage" value={sp.stockProduct.priceProfit} />
                      </Box>
                      <Box bgcolor={theme => `${theme.palette.success.main}40`}>
                        <NumberField type="text" format="percentage" value={sp.priceProfit} />
                      </Box>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {compareToCents(sp.purchasePrice, sp.stockProduct.purchasePrice) ? (
                    <NumberField type="text" format="currency" value={sp.purchasePrice} />
                  ) : (
                    <>
                      <Box bgcolor={theme => `${theme.palette.error.main}40`}>
                        <NumberField type="text" format="currency" value={sp.stockProduct.purchasePrice} />
                      </Box>
                      <Box bgcolor={theme => `${theme.palette.success.main}40`}>
                        <NumberField type="text" format="currency" value={sp.purchasePrice} />
                      </Box>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {compareToCents(sp.purchaseDiscount, sp.stockProduct.purchaseDiscount) ? (
                    <NumberField type="text" format="percentage" value={sp.purchaseDiscount} />
                  ) : (
                    <>
                      <Box bgcolor={theme => `${theme.palette.error.main}40`}>
                        <NumberField type="text" format="percentage" value={sp.stockProduct.purchaseDiscount} />
                      </Box>
                      <Box bgcolor={theme => `${theme.palette.success.main}40`}>
                        <NumberField type="text" format="percentage" value={sp.purchaseDiscount} />
                      </Box>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {compareToCents(sp.salePrice, sp.stockProduct.salePrice) ? (
                    <NumberField type="text" format="currency" value={sp.salePrice} />
                  ) : (
                    <>
                      <Box bgcolor={theme => `${theme.palette.error.main}40`}>
                        <NumberField type="text" format="currency" value={sp.stockProduct.salePrice} />
                      </Box>
                      <Box bgcolor={theme => `${theme.palette.success.main}40`}>
                        <NumberField type="text" format="currency" value={sp.salePrice} />
                      </Box>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {compareToCents(sp.saleDiscount, getPercentageSaleDiscount(sp.stockProduct)) ? (
                    <NumberField type="text" format="percentage" value={sp.saleDiscount} />
                  ) : (
                    <>
                      <Box bgcolor={theme => `${theme.palette.error.main}40`}>
                        <NumberField type="text" format="percentage" value={getPercentageSaleDiscount(sp.stockProduct)} />
                      </Box>
                      <Box bgcolor={theme => `${theme.palette.success.main}40`}>
                        <NumberField type="text" format="percentage" value={sp.saleDiscount} />
                      </Box>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="contained" color="inherit" onClick={cancelReview}>Cerrar</Button>
        <Button variant="contained" color="success" onClick={confirmUpdate}>Confirmar Actualización</Button>
      </DialogActions>
    </Dialog>
  );
};