import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Clear, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { useNotifications } from "../hooks/use-notifications";
import { User } from "../models/user";
import { changePassword, getAccessToken, resetPassword } from "../services/users";
import ValueList from "../../shared/components/ValueList";

interface LoginProps {
    isAuthenticating: boolean;
    authenticatedUser?: User;
    cancelLogin: () => void;
    logout: () => void;
}

interface LoginInputProps {
    user: User;
    property: keyof User;
    type: 'text' | 'password';
    label: string;
    name?: string;
    readOnly?: boolean;
    compareWith?: keyof User;
    onChange?: (name: keyof User, value: string) => void;
}

const initialUser: User = {
    name: '',
    email: '',
    nickname: '',
    password: '',
    nicknameOrEmail: '',
    newPassword: '',
    confirmPassword: '',
    roleName: ''
};

const LoginInput = ({ user, property, type, label, name, readOnly, compareWith, onChange }: LoginInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPasswordAutoFill, setIsPasswordAutoFill] = useState<boolean>(false);

    useEffect(() => {
        type === 'password' && setTimeout(() => {
            try {
                setIsPasswordAutoFill(inputRef.current?.matches(':-webkit-autofill') ?? false);
            } catch { }
        }, 0);
    }, [user, type]);

    useEffect(() => {
        isPasswordAutoFill && setShowPassword(false);
    }, [isPasswordAutoFill]);

    const handleClearPassword = () => onChange && onChange(property, '');
    const handleToggleShowPassword = () => setShowPassword(showPassword => !showPassword);

    return (
        <Controller
            shouldUnregister
            name={property}
            defaultValue={user[property]}
            rules={{
                required: true,
                ...compareWith && { validate: v => v === user[compareWith] }
            }}
            render={({ field: { onChange: onControlChange }, fieldState: { invalid } }) => (
                <TextField
                    inputRef={inputRef}
                    type={showPassword ? 'text' : type}
                    label={label}
                    {...name && {
                        id: name,
                        name: name,
                        autoComplete: name
                    }}
                    value={user[property]}
                    error={invalid}
                    onChange={e => {
                        onControlChange(e.target.value);
                        onChange && onChange(property, e.target.value);
                    }}
                    {...readOnly && {
                        inputProps: {
                            readOnly: true
                        }
                    }}
                    {...type === 'password' && {
                        inputProps: {
                            className: 'password',
                            readOnly: isPasswordAutoFill
                        },
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isPasswordAutoFill ? (
                                        <IconButton onClick={handleClearPassword}>
                                            <Clear />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={handleToggleShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            )
                        }
                    }}
                    {...isPasswordAutoFill && inputRef.current && {
                        sx: {
                            backgroundColor: getComputedStyle(inputRef.current).getPropertyValue('background-color')
                        }
                    }}
                />
            )}
        />
    );
};

const Login = ({ isAuthenticating, authenticatedUser, cancelLogin, logout }: LoginProps) => {
    const [user, setUser] = useState<User>({ ...initialUser });
    const [activeForm, setActiveForm] = useState<'login' | 'logout' | 'reset' | 'change'>('login');

    const location = useLocation();
    const navigate = useNavigate();

    const formMethods = useForm();

    const { showSuccessNotification, showErrorNotification } = useNotifications();

    const handleChange = <T extends keyof User>(name: T, value: User[T]) => setUser(user => ({ ...user, [name]: value }));

    const handleLogin = () => {
        getAccessToken(user)
            .then(() => navigate(location, { replace: true }))
            .catch(() => showErrorNotification('Error al iniciar sesión.'));
    };

    const handleLogout = () => {
        logout();
    };

    const handleResetPassword = () => {
        resetPassword(user)
            .then(() => {
                navigate(location, { replace: true });
                resetState(isAuthenticating, authenticatedUser);
                showSuccessNotification('Contraseña restablecida correctamente.');
            })
            .catch(() => showErrorNotification('Error al restablecer la contraseña.'));
    };

    const handleChangePassword = () => {
        changePassword(user)
            .then(() => {
                logout();
                navigate(location, { replace: true });
                showSuccessNotification('Contraseña modificada correctamente.');
            })
            .catch(() => showErrorNotification('Error al modificar la contraseña.'));
    };

    const resetState = useCallback((isAuthenticating: boolean, authenticatedUser?: User, activeForm?: 'login' | 'logout' | 'reset' | 'change') => {
        if (isAuthenticating) {
            formMethods.reset({ ...initialUser, nickname: authenticatedUser?.nickname ?? '' });
            setUser({ ...initialUser, nickname: authenticatedUser?.nickname ?? '' });
            setActiveForm(activeForm ?? (authenticatedUser ? 'logout' : 'login'));
        }
    }, [formMethods]);

    useEffect(() => {
        resetState(isAuthenticating, authenticatedUser);
    }, [isAuthenticating, authenticatedUser, resetState]);

    useEffect(() => {
        formMethods.formState.isSubmitted && formMethods.trigger(['newPassword', 'confirmPassword']);
    }, [user.newPassword, user.confirmPassword, formMethods]);

    return (
        <>
            {isAuthenticating && (
                <Dialog maxWidth="xs" fullWidth={true} open={true} onClose={cancelLogin}>
                    <DialogTitle>
                        Acceso
                        <IconButton onClick={cancelLogin} sx={{
                            position: 'absolute',
                            top: 8,
                            right: 16,
                            color: theme => theme.palette.grey[500],
                        }}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{ py: 3 }}>
                        <FormProvider {...formMethods}>
                            {activeForm === 'login' && (
                                <Box component="form" id="login" autoComplete="on" onSubmit={formMethods.handleSubmit(handleLogin)}>
                                    <Stack spacing={3}>
                                        <LoginInput user={user} property="nickname" type="text" label="Usuario" name="username" onChange={handleChange} />
                                        <LoginInput user={user} property="password" type="password" label="Contraseña" name="current-password" onChange={handleChange} />
                                    </Stack>
                                </Box>
                            )}
                            {activeForm === 'logout' && (
                                <ValueList
                                    items={[
                                        { name: 'Nombre', value: authenticatedUser?.name },
                                        { name: 'Correo Electrónico', value: authenticatedUser?.email },
                                        { name: 'Usuario', value: authenticatedUser?.nickname },
                                        { name: 'Perfil', value: authenticatedUser?.roleName }
                                    ]}
                                />
                            )}
                            {activeForm === 'reset' && (
                                <Box component="form" id="reset" autoComplete="on" onSubmit={formMethods.handleSubmit(handleResetPassword)}>
                                    <Stack spacing={4}>
                                        <LoginInput user={user} property="nicknameOrEmail" type="text" label="Usuario o Correo Electrónico" name="username" onChange={handleChange} />
                                    </Stack>
                                </Box>
                            )}
                            {activeForm === 'change' && (
                                <Box component="form" id="change" autoComplete="on" onSubmit={formMethods.handleSubmit(handleChangePassword)}>
                                    <Stack spacing={4}>
                                        <LoginInput user={user} property="nickname" type="text" label="Usuario" name="username" readOnly />
                                        <LoginInput user={user} property="password" type="password" label="Contraseña" name="current-password" onChange={handleChange} />
                                        <LoginInput user={user} property="newPassword" type="password" label="Nueva Contraseña" name="new-password" compareWith="confirmPassword" onChange={handleChange} />
                                        <LoginInput user={user} property="confirmPassword" type="password" label="Confirmar Contraseña" compareWith="newPassword" onChange={handleChange} />
                                    </Stack>
                                </Box>
                            )}
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
                        {activeForm === 'login' && (
                            <>
                                <Button variant="text" type="button" sx={{ p: 0 }} onClick={() => resetState(isAuthenticating, authenticatedUser, 'reset')}>Restablecer Contraseña</Button>
                                <Button variant="contained" color="success" type="submit" form="login">Iniciar Sesión</Button>
                            </>
                        )}
                        {activeForm === 'logout' && (
                            <>
                                <Button variant="text" type="button" sx={{ p: 0 }} onClick={() => resetState(isAuthenticating, authenticatedUser, 'change')}>Modificar Contraseña</Button>
                                <Button variant="contained" color="error" type="button" onClick={handleLogout}>Cerrar Sesión</Button>
                            </>
                        )}
                        {activeForm === 'reset' && (
                            <>
                                <Button variant="text" type="button" sx={{ p: 0 }} onClick={() => resetState(isAuthenticating, authenticatedUser, 'login')}>Iniciar Sesión</Button>
                                <Button variant="contained" color="secondary" type="submit" form="reset">Restablecer Contraseña</Button>
                            </>
                        )}
                        {activeForm === 'change' && (
                            <>
                                <Button variant="text" type="button" sx={{ p: 0 }} onClick={() => resetState(isAuthenticating, authenticatedUser, 'logout')}>Cerrar Sesión</Button>
                                <Button variant="contained" color="primary" type="submit" form="change">Modificar Contraseña</Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default Login;
