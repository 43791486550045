import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Add, Cancel, ExpandMore, Remove } from "@mui/icons-material";
import { Box, Button, Checkbox, Grid, IconButton, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { getDateTimeString } from "../../core/services/dates";
import { addEntityArrayPropertyAllItemsArrayPropertyItem, addEntityArrayPropertyItem, getEntityArrayPropertyItems, getItemsSubtotal, getItemsTotal, getItemSubtotal, removeEntityArrayPropertyAllItemsArrayPropertyItem, removeEntityArrayPropertyItem, setEntityArrayPropertyAllItemsArrayPropertyItem, setEntityArrayPropertyAllItemsPropertyValue, setEntityArrayPropertyItemArrayPropertyItem, setEntityArrayPropertyItemPropertyValue } from "../../core/services/entities";
import { isStockProductArray } from "../../core/utils/type-guards";
import { TDEntity, TDEntityArrayProperty, TDEntityArrayPropertyItem, TDProperty, TDPropertyValue } from "../../core/utils/entity-types";
import { TColumnAccess, TColumnAlign, TColumnType, TNumberFormat } from "../../core/utils/util-types";
import ProductSelector from "./ProductSelector";
import NumberField from "./NumberField";

//TODO:
// change value should restrict to property type
// avoid casts to any
// calls to entity methods could be internal (to avoid repeating property names)
// faltan los disabled (en los botones de agregar y quitar descuento)
// I'm using id for the form name, but id is not required

type PropertyTableProps<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
  TSourceEntity extends TDEntity
> = {
  entity: TEntity,
  property: TEntityArrayProperty,
  columns: PropertyTableColumnProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem>,
  disabled?: boolean,
  enableAdd?: boolean,
  canRemove?: boolean,
  required?: boolean,
  sourceEntities?: TSourceEntity[],
  getInitialItem?: (sourceEntity: TSourceEntity, entity: TEntity) => TEntityArrayPropertyItem,
  getItemTotal?: (item: TEntityArrayPropertyItem) => number,
  getCanUpdate?: (item: TEntityArrayPropertyItem) => boolean,
  getCanRemove?: (item: TEntityArrayPropertyItem) => boolean,
  filterItems?: (item: TEntityArrayPropertyItem) => boolean,
  updateEntity?: (entity: TEntity, previousEntity: TEntity) => TEntity,
  setEntity?: (entity: TEntity) => void,
  isValid?: (item: TEntityArrayPropertyItem) => boolean
};

type PropertyTableColumnProps<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
> = {
    [TEntityArrayPropertyItemProperty in TDProperty<TEntityArrayPropertyItem>]?: PropertyTableColumnDefinitionProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem, TEntityArrayPropertyItemProperty, TDPropertyValue<TEntityArrayPropertyItem, TEntityArrayPropertyItemProperty>>
  };

type PropertyTableColumnDefinitionProps<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
  TEntityArrayPropertyItemProperty extends TDProperty<TEntityArrayPropertyItem>,
  TEntityArrayPropertyItemPropertyValue extends TDPropertyValue<TEntityArrayPropertyItem, TEntityArrayPropertyItemProperty>
> = {
  name: string,
  type?: TColumnType,
  access?: TColumnAccess,
  align?: TColumnAlign,
  format?: TNumberFormat,
  unified?: boolean,
  hidden?: boolean,
  enabled?: boolean,
  validateItem?: boolean,
  getAccess?: (item: TEntityArrayPropertyItem) => TColumnAccess,
  isValid?: (value: TEntityArrayPropertyItemPropertyValue) => boolean
};

type PropertyTableRowProps<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
  TSourceEntity extends TDEntity
> = PropertyTableProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem, TSourceEntity> & {
  item: TEntityArrayPropertyItem,
  itemIndex: number
};

const get = <TValue,>(value: any) => value as TValue;

const any = (value: any): any => value as any;
const anyArray = (value: any): any[] => value as any[];

const getUnifiedValue = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>
>(
  items: TEntityArrayPropertyItem[],
  property: TDProperty<TEntityArrayPropertyItem>
) => {
  return items.length && items.every(item => item[property] === items[0][property]) ? items[0][property] : NaN;
};

const getUnifiedArrayValue = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>
>(
  items: TEntityArrayPropertyItem[],
  property: TDProperty<TEntityArrayPropertyItem>,
  index: number
) => {
  return items.length && items.every(item => get<any[]>(item[property])[index] === get<any[]>(items[0][property])[index]) ? get<any[]>(items[0][property])[index] : NaN;
};

const getColumns = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>
>(columns: PropertyTableColumnProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem>) => {
  return Object.entries(columns).map(([property, column]) => ({
    property: property as TDProperty<TEntityArrayPropertyItem>,
    ...column as PropertyTableColumnDefinitionProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem, TDProperty<TEntityArrayPropertyItem>, TDPropertyValue<TEntityArrayPropertyItem, TDProperty<TEntityArrayPropertyItem>>>
  }));
};

export default function PropertyTable<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
  TSourceEntity extends TDEntity
>({
  entity,
  property,
  columns,
  disabled,
  enableAdd,
  canRemove,
  required,
  sourceEntities,
  getInitialItem,
  getItemTotal,
  getCanUpdate,
  getCanRemove,
  filterItems,
  updateEntity = entity => entity,
  setEntity = () => { },
  isValid = () => true
}: PropertyTableProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem, TSourceEntity>) {
  const [addItemMenu, setAddItemMenu] = useState<HTMLElement | null>(null);

  const formContext = useFormContext();

  const filteredColumns = getColumns(columns).filter(column => !column.hidden);

  const items = getEntityArrayPropertyItems(entity, property);
  const filteredItems = filterItems ? items.filter(item => filterItems(any(item))) : items;

  const countFormName = `${any(property)}-count`;
  const countFormValue = filteredItems.length;
  useEffect(() => {
    formContext.setValue(countFormName, countFormValue, { shouldValidate: formContext.formState.isSubmitted });
  }, [countFormName, countFormValue, formContext]);

  return (
    <Stack spacing={4}>
      {sourceEntities && (
        <>
          {isStockProductArray(sourceEntities) ? (
            <Grid container>
              <Grid item xs={6}>
                <Controller shouldUnregister name={countFormName} rules={{ validate: count => !required || count > 0 }} render={({ fieldState: { invalid } }) => (
                  <ProductSelector
                    stockProducts={sourceEntities}
                    disabled={disabled && !enableAdd}
                    error={invalid}
                    onStockProductSelect={sp => getInitialItem && setEntity(updateEntity(addEntityArrayPropertyItem(entity, property, getInitialItem(get<any>(sp), entity)), entity))}
                  />
                )} />
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Controller shouldUnregister name={countFormName} rules={{ validate: count => !required || count > 0 }} render={({ fieldState: { invalid } }) => (
                <Button variant="contained" disabled={disabled && !enableAdd} color={invalid ? 'error' : 'primary'} endIcon={<ExpandMore />} onClick={e => setAddItemMenu(e.currentTarget)}>Agregar</Button>
              )} />
              <Menu anchorEl={addItemMenu} open={!!addItemMenu} onClose={() => setAddItemMenu(null)}>
                {[sourceEntities.map((se, i) => (
                  <MenuItem key={i} onClick={() => { setAddItemMenu(null); getInitialItem && setEntity(updateEntity(addEntityArrayPropertyItem(entity, property, getInitialItem(get<any>(se), entity)), entity)); }}>{get<any>(se)['name'] || get<any>(se)['description']}</MenuItem>
                ))]}
              </Menu>
            </Box>
          )}
        </>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {filteredColumns.map((column, columnIndex) => (
              <TableCell key={columnIndex} align={column.align || 'left'}>
                {column.unified ? (
                  <>
                    {(() => {
                      switch (column.type) {
                        case 'number':
                          return (
                            <>
                              {filteredItems.length > 1 ? (
                                <NumberField type="write" format={column.format || 'integer'} disabled={disabled && !column.enabled} label={column.name} value={any(getUnifiedValue(any(filteredItems), column.property))} onChange={value => setEntity(updateEntity(setEntityArrayPropertyAllItemsPropertyValue(entity, property, any(column.property), any(value)), entity))} />
                              ) : (
                                <Typography fontWeight="bold">{column.name}</Typography>
                              )}
                            </>
                          );
                        case 'number-array':
                          return (
                            <Box sx={{ display: 'inline-block', position: 'relative', whiteSpace: 'nowrap' }}>
                              {filteredItems.length > 1 ? (
                                <>
                                  {get<any[]>(any(filteredItems[0])[column.property]).map((_, arrayIndex) => (
                                    <Box key={arrayIndex} sx={{ display: 'inline-block', mr: 1 }}>
                                      <NumberField type="write" format={column.format || 'integer'} disabled={disabled && !column.enabled} label={column.name} value={getUnifiedArrayValue(any(filteredItems), column.property, arrayIndex)} onChange={value => setEntity(updateEntity(setEntityArrayPropertyAllItemsArrayPropertyItem(entity, property, any(column.property), arrayIndex, any(value)), entity))} />
                                    </Box>
                                  ))}
                                </>
                              ) : (
                                <Typography fontWeight="bold">{column.name}</Typography>
                              )}
                              {filteredItems.length > 0 && (
                                <Stack sx={{ position: 'absolute', top: filteredItems.length > 1 ? 0 : -8, right: filteredItems.length > 1 ? -16 : -24 }}>
                                  <IconButton color="success" size="small" disabled={disabled && !column.enabled} onClick={() => setEntity(updateEntity(addEntityArrayPropertyAllItemsArrayPropertyItem(entity, property, get<any>(column.property), get<any>(0)), entity))} sx={{ p: 0 }}>
                                    <Add fontSize="small" />
                                  </IconButton>
                                  <IconButton color="error" size="small" disabled={(disabled && !column.enabled) || get<any[]>(any(filteredItems[0])[column.property]).length <= 1} onClick={() => setEntity(updateEntity(removeEntityArrayPropertyAllItemsArrayPropertyItem(entity, property, get<any>(column.property)), entity))} sx={{ p: 0 }}>
                                    <Remove fontSize="small" />
                                  </IconButton>
                                </Stack>
                              )}
                            </Box>
                          );
                      }
                    })()}
                  </>
                ) : (
                  <Typography fontWeight="bold">{column.name}</Typography>
                )}
              </TableCell>
            ))}
            {getItemTotal && (
              <>
                <TableCell align="center">
                  <Typography fontWeight="bold">Subtotal</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight="bold">Total</Typography>
                </TableCell>
              </>
            )}
            {canRemove && (
              <TableCell></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, itemIndex) => (
            <PropertyTableRow
              key={itemIndex}
              entity={entity}
              property={property}
              columns={columns}
              disabled={disabled}
              canRemove={canRemove}
              item={any(item)}
              itemIndex={itemIndex}
              getItemTotal={getItemTotal}
              getCanUpdate={getCanUpdate}
              getCanRemove={getCanRemove}
              filterItems={filterItems}
              updateEntity={updateEntity}
              setEntity={setEntity}
              isValid={isValid}
            />
          ))}
        </TableBody>
        {getItemTotal && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={filteredColumns.length} sx={{ borderBottom: 'none' }}></TableCell>
              <TableCell align="center" sx={{ color: theme => theme.palette.text.primary, fontWeight: 'bold' }}>
                <NumberField type="text" format="currency" value={getItemsSubtotal(any(filteredItems), getItemTotal)} />
              </TableCell>
              <TableCell align="center" sx={{ color: theme => theme.palette.text.primary, fontWeight: 'bold' }}>
                <NumberField type="text" format="currency" value={getItemsTotal(any(filteredItems), getItemTotal)} />
              </TableCell>
              {canRemove && (
                <TableCell sx={{ borderBottom: 'none' }}></TableCell>
              )}
            </TableRow>
          </TableFooter>
        )}
      </Table >
    </Stack >
  );
};

function PropertyTableRow<
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>,
  TSourceEntity extends TDEntity
>({
  entity,
  property,
  columns,
  disabled,
  canRemove,
  item,
  itemIndex,
  getItemTotal,
  getCanUpdate = () => true,
  getCanRemove = () => true,
  filterItems = () => true,
  updateEntity = entity => entity,
  setEntity = () => { },
  isValid = () => true
}: PropertyTableRowProps<TEntity, TEntityArrayProperty, TEntityArrayPropertyItem, TSourceEntity>) {
  const formContext = useFormContext();

  const filteredColumns = getColumns(columns).filter(column => !column.hidden);

  const itemFormName = `${any(property)}-${any(item)['id']}`;
  useEffect(() => {
    formContext.setValue(itemFormName, item, { shouldValidate: formContext.formState.isSubmitted });
  }, [item, itemFormName, formContext]);

  return (
    <>
      {filterItems(item) && (
        <Controller shouldUnregister name={itemFormName} rules={{ validate: value => isValid(value) }} render={({ fieldState: { invalid: invalidItem } }) => (
          <TableRow>
            {filteredColumns.map((column, columnIndex) => (
              <TableCell key={columnIndex} align={column.align || 'left'}>
                {column.access === 'write' || (column.getAccess && column.getAccess(item) === 'write') ? (
                  <>
                    {(() => {
                      switch (column.type) {
                        case 'number':
                          return (
                            <Controller shouldUnregister name={`${itemFormName}.${any(column.property)}`} defaultValue={any(any(item)[column.property])} rules={{ validate: value => !column.isValid || column.isValid(value) }} render={({ fieldState: { invalid } }) => (
                              <NumberField type="write" format={column.format || 'integer'} disabled={(disabled && !column.enabled) || !getCanUpdate(item)} error={invalid || (column.validateItem && invalidItem)} value={any(item)[column.property] as any} onChange={value => setEntity(updateEntity(setEntityArrayPropertyItemPropertyValue(entity, property, itemIndex, any(column.property), any(value)), entity))} />
                            )} />
                          );
                        case 'boolean':
                          return (
                            <Controller shouldUnregister name={`${itemFormName}.${any(column.property)}`} defaultValue={any(any(item)[column.property])} rules={{ validate: value => !column.isValid || column.isValid(value) }} render={({ fieldState: { invalid } }) => (
                              <Checkbox disabled={(disabled && !column.enabled) || !getCanUpdate(item)} checked={any(any(item)[column.property])} onChange={(_, checked) => setEntity(updateEntity(setEntityArrayPropertyItemPropertyValue(entity, property, itemIndex, any(column.property), checked), entity))} sx={{ ...(invalid || (column.validateItem && invalidItem)) && { color: theme => theme.palette.error.main, '&.Mui-checked': { color: theme => theme.palette.error.main } } }} />
                            )} />
                          );
                        case 'number-array':
                          return (
                            <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                              {get<any[]>(any(item)[column.property]).map((arrayItem, arrayIndex) => (
                                <Box key={arrayIndex} sx={{ display: 'inline-block', mr: 1 }}>
                                  <Controller shouldUnregister name={`${itemFormName}.${any(column.property)}[${arrayIndex}]`} defaultValue={anyArray(any(item)[column.property])[arrayIndex]} rules={{ validate: value => !column.isValid || column.isValid(value) }} render={({ fieldState: { invalid } }) => (
                                    <NumberField type="write" format={column.format || 'integer'} disabled={(disabled && !column.enabled) || !getCanUpdate(item)} error={invalid || (column.validateItem && invalidItem)} value={arrayItem} onChange={value => setEntity(updateEntity(setEntityArrayPropertyItemArrayPropertyItem(entity, property, itemIndex, get<any>(column.property), arrayIndex, get<any>(value)), entity))} />
                                  )} />
                                </Box>
                              ))}
                            </Box>
                          );
                        default:
                          return (
                            <Controller shouldUnregister name={`${itemFormName}.${any(column.property)}`} defaultValue={any(item)[column.property]} rules={{ validate: value => !column.isValid || column.isValid(value) }} render={({ fieldState: { invalid } }) => (
                              <TextField fullWidth size="small" disabled={(disabled && !column.enabled) || !getCanUpdate(item)} error={invalid} value={any(item)[column.property]} onChange={e => setEntity(updateEntity(setEntityArrayPropertyItemPropertyValue(entity, property, itemIndex, any(column.property), e.target.value), entity))} />
                            )} />
                          );
                      }
                    })()}
                  </>
                ) : (
                  <>
                    {(() => {
                      switch (column.type) {
                        case 'number':
                          return (
                            <Controller shouldUnregister name={`${itemFormName}.${any(column.property)}`} defaultValue={any(any(item)[column.property])} rules={{ validate: value => !column.isValid || column.isValid(value) }} render={({ fieldState: { invalid } }) => (
                              <NumberField type="text" format={column.format || 'integer'} error={invalid} value={any(any(item)[column.property])} />
                            )} />
                          );
                        case 'boolean':
                          return (
                            <Typography>{item[column.property] ? 'Sí' : 'No'}</Typography>
                          );
                        case 'date-time':
                          return (
                            <Typography>{getDateTimeString(any(item)[column.property])}</Typography>
                          );
                        default:
                          return (
                            <Typography>{any(item)[column.property]}</Typography>
                          );
                      }
                    })()}
                  </>
                )}
              </TableCell>
            ))}
            {getItemTotal && (
              <>
                <TableCell align="center">
                  <NumberField type="text" format="currency" value={getItemSubtotal(any(item), getItemTotal)} />
                </TableCell>
                <TableCell align="center">
                  <NumberField type="text" format="currency" value={getItemTotal(any(item))} />
                </TableCell>
              </>
            )}
            {canRemove && (
              <TableCell align="center">
                <IconButton color="error" size="small" disabled={disabled || !getCanRemove(item)} onClick={() => setEntity(updateEntity(removeEntityArrayPropertyItem(entity, property, itemIndex), entity))}>
                  <Cancel />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        )} />
      )}
    </>
  );
};
