export const getInvalidDate = () => new Date(NaN);

export const getCurrentLocalDateTime = () => new Date();
export const getCurrentLocalDate = () => new Date(getCurrentLocalDateTime().setHours(0, 0, 0, 0));
export const getCurrentLocalMonth = () => new Date(getCurrentLocalDate().setDate(1));
export const getCurrentLocalYear = () => new Date(getCurrentLocalDate().setMonth(0, 1));

export const getDateTimeString = (date: Date) => normalizeDate(date).toLocaleString();
export const getDateString = (date: Date) => normalizeDate(date).toLocaleDateString();
export const getIsoString = (date: Date) => normalizeDate(date).toISOString();

export const getDateYearString = (date: Date) => normalizeDate(date).toLocaleDateString(undefined, { year: 'numeric' });
export const getDateYearMonthString = (date: Date) => normalizeDate(date).toLocaleDateString(undefined, { year: 'numeric', month: 'long' });

export const isValidDate = (date: Date) => !isNaN(normalizeDate(date).getTime());
export const isEqual = (dateA: Date, dateB: Date) => normalizeDate(dateA).getTime() === normalizeDate(dateB).getTime();
export const isGreaterOrEqual = (dateA: Date, dateB: Date) => normalizeDate(dateA).getTime() >= normalizeDate(dateB).getTime();

export const getDaysDifference = (dateA: Date, dateB: Date) => (normalizeDate(dateA).getTime() - normalizeDate(dateB).getTime()) / (1000 * 60 * 60 * 24);
export const getRemainingDays = (date: Date) => getDaysDifference(date, getCurrentLocalDate());

export const normalizeDate = (date: Date | string) => typeof date === 'string' ? new Date(date) : date;
