import { StockProduct } from "../models/stock-product"
import { TDEntity } from "./entity-types"

const defaultStockProduct: StockProduct = {
  id: 0,
  productId: 0,
  productTypeId: 0,
  measureId: 0,
  priceTypeId: 0,
  priceProfit: 0,
  purchasePrice: 0,
  purchaseDiscount: 0,
  salePrice: 0,
  saleDiscount: 0,
  providerId: 0,
  measureUnitsNumber: 0,
  priceTypeDescription: '',
  fullName: ''
};

export const isStockProductArray = (entities: TDEntity[]): entities is StockProduct[] => isOfArray<StockProduct>(entities, defaultStockProduct);

export const isOfArray = <TEntity extends TDEntity>(entities: TDEntity[], defaultEntity: TEntity) => {
  return entities.length > 0 && isOf<TEntity>(entities[0], defaultEntity);
};

export const isOf = <TEntity extends TDEntity>(entity: TDEntity, defaultEntity: TEntity) => {
  const defaultEntries = Object.entries(defaultEntity);
  return Object.keys(entity).length === defaultEntries.length && defaultEntries.every(([k, v]) => k in entity && typeof (entity as any)[k] === typeof v);
};
