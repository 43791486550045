import { List, ListItemButton, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export interface MenuProps {
  menuItems: string[];
  getMenuItemUrl: (s: string) => string;
}

export default function Menu({ menuItems, getMenuItemUrl }: MenuProps) {
  const location = useLocation();

  return (
    <List>
      {menuItems.map((text, index) => (
        <ListItemButton key={index} component={Link} to={getMenuItemUrl(text)} selected={location.pathname.startsWith(getMenuItemUrl(text))}>
          {/* <ListItemIcon>
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon> */}
          <ListItemText primary={text} />
        </ListItemButton>
      ))}
    </List>
  );
};