import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useNotifications = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSuccessNotification = useCallback((message: string) => enqueueSnackbar(message, { variant: 'success' }), [enqueueSnackbar]);
    const showErrorNotification = useCallback((message: string) => enqueueSnackbar(message, { variant: 'error' }), [enqueueSnackbar]);

    return {
        showSuccessNotification,
        showErrorNotification
    };
};
