import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paid, ShoppingCart } from "@mui/icons-material";
import { Box, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { PurchasePayment } from "../../../core/models/purchase-payment";
import { getCurrentLocalDateTime, getDateString, getDateTimeString, getRemainingDays } from "../../../core/services/dates";
import { getPurchasePayments, payPurchasePayment } from "../../../core/services/purchase-payments";
import { useNotifications } from "../../../core/hooks/use-notifications";
import NumberField from "../../../shared/components/NumberField";

export default function PurchasePayments() {
  const [purchasePayments, setPurchasePayments] = useState<PurchasePayment[]>([]);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  useEffect(() => {
    getPurchasePayments().then(purchasePayments => setPurchasePayments(purchasePayments)).catch(() => showErrorNotification('Error al cargar los Pagos a Proveedores.'));
  }, [showErrorNotification]);

  const handlePayPurchasePaymentClick = (purchasePayment: PurchasePayment) => {
    payPurchasePayment({
      ...purchasePayment,
      paymentDate: getCurrentLocalDateTime()
    })
      .then(purchasePayment => {
        setPurchasePayments(purchasePayments.map(pp => pp.id === purchasePayment.id ? purchasePayment : pp));
        showSuccessNotification('Pago a Proveedor realizado correctamente.');
      })
      .catch(() => showErrorNotification('Error al realizar el Pago a Proveedor.'));
  };

  return (
    <Box>
      {[
        {
          title: 'Pagos Pendientes',
          filter: (purchasePayment: PurchasePayment) => !purchasePayment.paid
        },
        {
          title: 'Pagos Realizados',
          filter: (purchasePayment: PurchasePayment) => purchasePayment.paid
        }
      ].map((section, index) => (
        <React.Fragment key={index}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>{section.title}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Proveedor</Box></TableCell>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Método de Pago</Box></TableCell>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Nota de Crédito</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Límite de Pago</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Fecha de Pago</Box></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchasePayments.filter(pp => section.filter(pp)).map((pp, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography variant="body1">{pp.purchaseProvider}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{pp.paymentMethodName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{pp.creditNoteDescription}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={pp.paymentAmount} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={pp.paymentAmount * (pp.purchaseIncludesVat ? 1.16 : 1)} />
                  </TableCell>
                  <TableCell align="center">
                    {pp.paydayLimit && <Typography variant="body1" sx={{
                      ...!pp.paid && {
                        fontWeight: 'bold',
                        color: theme => {
                          const remainingDays = getRemainingDays(pp.paydayLimit);
                          return remainingDays <= 2 ? theme.palette.error.main : remainingDays <= 7 ? theme.palette.warning.light : theme.palette.success.main
                        }
                      }
                    }}>{getDateString(pp.paydayLimit)}</Typography>}
                  </TableCell>
                  <TableCell align="center">
                    {pp.paymentDate && <Typography variant="body1">{getDateTimeString(pp.paymentDate)}</Typography>}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton color="info" size="small" component={Link} to={`/compras/${pp.purchaseId}`}>
                      <ShoppingCart />
                    </IconButton>
                    {!pp.paid && (
                      <IconButton color="success" size="small" onClick={() => handlePayPurchasePaymentClick(pp)}>
                        <Paid />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </React.Fragment >
      )).reduce((sections, section, i) => sections.length ? [
        ...sections,
        <Divider key={-i} sx={{ bgcolor: theme => theme.palette.primary.light, marginTop: 4, marginBottom: 4 }} />,
        section
      ] : [section], [] as JSX.Element[])
      }
    </Box >
  );
};
