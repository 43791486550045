import { CreditNote } from "../models/credit-note";
import { httpGet, httpPost } from "./http-client";

const apiUrl = '/api/creditnotes';

export const getCreditNotes = () => httpGet<CreditNote[]>(`${apiUrl}/all`);
export const releaseCreditNote = (creditNoteId: number) => httpPost<CreditNote>(`${apiUrl}/release/${creditNoteId}`, null);

export const getOpenedCreditNotes = (providerId: number) => httpGet<CreditNote[]>(`${apiUrl}/opened/${providerId}`);
export const getReleasedCreditNotes = (providerId: number) => httpGet<CreditNote[]>(`${apiUrl}/released/${providerId}`);
