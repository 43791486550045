import { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useNotifications } from "../../core/hooks/use-notifications";
import { Loan } from "../../core/models/loan";
import { getLoans } from "../../core/services/loans";
import { roundToCents } from "../../core/services/numbers";
import { vatFactor } from "../../core/utils/app-constants";
import EntityTable from "../../shared/components/EntityTable";

type TRole = 'lender' | 'borrower';
type TStatus = 'lent' | 'paid' | 'canceled';

const loanPredicate = (loan: Loan, role: TRole, status: TStatus) =>
  ((role === 'lender' && !loan.borrower) || (role === 'borrower' && loan.borrower)) &&
  ((status === 'lent' && !loan.completed && !loan.canceled) || (status === 'paid' && loan.completed && !loan.canceled) || (status === 'canceled' && !loan.completed && loan.canceled));

export default function Loans() {
  const [loans, setLoans] = useState<Loan[]>([]);

  const [role, setRole] = useState<TRole>('lender');
  const [status, setStatus] = useState<TStatus>('lent');

  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    getLoans().then(loans => setLoans(loans)).catch(() => showErrorNotification('Error al cargar los Préstamos.'));
  }, [showErrorNotification]);

  return (
    <Stack alignItems="start" spacing={4}>
      <Button variant="contained" color="success" component={Link} to="/prestamos/nuevo">Crear Nuevo Préstamo</Button>
      <Stack sx={{ width: '100%' }}>
        <Stack direction="row" spacing={4}>
          <FormControl>
            <FormLabel>Rol de Berel Eje 8</FormLabel>
            <RadioGroup row value={role} onChange={e => setRole(e.target.value as TRole)}>
              <FormControlLabel value="lender" label="Prestamista" control={<Radio />} />
              <FormControlLabel value="borrower" label="Prestatario" control={<Radio />} />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Estatus del Préstamo</FormLabel>
            <RadioGroup row value={status} onChange={e => setStatus(e.target.value as TStatus)}>
              <FormControlLabel value="lent" label="Prestado" control={<Radio />} />
              <FormControlLabel value="paid" label="Pagado" control={<Radio />} />
              <FormControlLabel value="canceled" label="Cancelado" control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Stack>
        <EntityTable
          entities={loans.filter(l => loanPredicate(l, role, status))}
          emptyLabel={'No se encontraron Préstamos'}
          getEntityTotal={l => l.loanItems.filter(li => !li.returned).reduce((t, li) => t + roundToCents(li.purchasePrice * (1 - li.purchaseDiscount / 100) * li.quantity * vatFactor), 0)}
          getEntityLink={l => `/prestamos/${l.id}`}
          columns={{
            date: {
              name: 'Fecha',
              type: 'date-time'
            },
            partnerStoreName: {
              name: 'Tienda Asociada'
            }
          }}
        />
      </Stack>
    </Stack>
  );
}