import { Autocomplete, AutocompleteRenderOptionState, FilterOptionsState, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { StockProduct } from "../../core/models/stock-product";
import { getCurrentLocalDateTime } from "../../core/services/dates";

interface ProductSelectorProps {
  stockProducts: StockProduct[];
  selectedStockProduct?: StockProduct | null;
  error?: boolean;
  disabled?: boolean;
  onStockProductSelect: (stockProduct: StockProduct | null) => void;
}

export default function ProductSelector({
  stockProducts,
  selectedStockProduct,
  disabled,
  error,
  onStockProductSelect
}: ProductSelectorProps) {
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>('');
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [autocompleteValue, setAutocompleteValue] = useState<StockProduct | null>(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<string>('');

  const [internalChange, setInternalChange] = useState<boolean>(true);

  useEffect(() => {
    if (!internalChange && !selectedStockProduct) {
      setAutoCompleteKey(getCurrentLocalDateTime().toString());
      setAutocompleteValue(null);
      setAutocompleteInputValue('');
    }
    setInternalChange(false);
  }, [internalChange, selectedStockProduct]);

  const handleAutocompleteOpen = () => {
    setAutocompleteOpen(!autocompleteValue && !!autocompleteInputValue);
  }

  const handleAutocompleteClose = () => {
    setAutocompleteOpen(false);
  }

  const handleAutocompleteInputChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setAutocompleteOpen(!!value);
    setAutocompleteInputValue(value);
    internalStockProductSelect(value === autocompleteValue?.fullName ? autocompleteValue : null);
  }

  const handleAutocompleteChange = (event: React.SyntheticEvent<Element, Event>, value: StockProduct | null) => {
    setAutocompleteValue(value);
    internalStockProductSelect(value);
  };

  const internalStockProductSelect = (stockProduct: StockProduct | null) => {
    if (stockProduct?.id !== selectedStockProduct?.id) {
      setInternalChange(true);
      onStockProductSelect(stockProduct);
    }
  };

  const filterOptions = (options: StockProduct[], state: FilterOptionsState<StockProduct>) => {
    const filterWords = state.inputValue.split(' ').filter(w => w).map(w => normalize(w));
    return filterWords.length ? options.filter(spo => {
      const productFullName = normalize(spo.fullName);
      return filterWords.every(w => productFullName.includes(w));
    }).slice(0, 10) : [];
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: StockProduct, state: AutocompleteRenderOptionState) => {
    const filterWords = state.inputValue.split(' ').filter(w => w).map(w => normalize(w));
    if (filterWords.length) {
      const productFullName = normalize(option.fullName);
      const boldPositions = new Array(productFullName.length).fill(false);
      filterWords.forEach(word => {
        let index = 0;
        let subIndex = -1;
        while (index < productFullName.length && (subIndex = productFullName.substring(index).indexOf(word)) !== -1) {
          index += subIndex;
          boldPositions.fill(true, index, index + word.length);
          index++;
        }
      });

      let startIndex = 0;
      let childrenArray = [];
      for (let i = 1; i <= boldPositions.length; i++) {
        if (i === boldPositions.length || boldPositions[i] !== boldPositions[i - 1]) {
          if (boldPositions[startIndex]) {
            childrenArray.push(<span key={i}><b>{option.fullName.substring(startIndex, i)}</b></span>);
          } else {
            childrenArray.push(<span key={i}>{option.fullName.substring(startIndex, i)}</span>);
          }
          startIndex = i;
        }
      }

      return <li {...props}><div>{childrenArray}</div></li>;
    }

    return <li {...props}><div>{option.fullName}</div></li>;
  };

  const normalize = (s: string) => s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return (
    <>
      {/* Falta mostrar solo los productos visibles */}
      {/* Falta longitud mínima para no mostrar leyenda de no encontrados */}
      {/* Falta ver si puedo agregar el botón como adornment */}
      {/* Falta mejorar el código de las caracteres resaltados */}
      <Autocomplete
        key={autoCompleteKey}
        options={stockProducts}
        renderInput={p => (
          <TextField
            {...p}
            label="Producto"
            disabled={disabled}
            error={error}
            // InputProps={{
          //   ...p.InputProps,
          //   endAdornment: (
          //     <>
          //       {p.InputProps.endAdornment}
          //       <InputAdornment position="end">
          //         <IconButton
          //         // onClick={handleClickShowPassword}
          //         // onMouseDown={handleMouseDownPassword}
          //         // edge="end"
          //         >
          //           <Add />
          //         </IconButton>
          //       </InputAdornment>
          //     </>
          //   )
          // }}
          />
        )}
        getOptionLabel={spo => spo.fullName}
        noOptionsText="No se encontraron productos."
        filterOptions={filterOptions}
        renderOption={renderOption}
        open={autocompleteOpen}
        onOpen={handleAutocompleteOpen}
        onClose={handleAutocompleteClose}
        inputValue={autocompleteInputValue}
        onInputChange={handleAutocompleteInputChange}
        onChange={handleAutocompleteChange}
        isOptionEqualToValue={(o, v) => o.id === v.id}
      />
    </>
  );

}