import { Sale } from "../models/sale";
import { getIsoString } from "./dates";
import { httpDownload, httpGet } from "./http-client";

const apiUrl = '/api/sales';

export const getSales = (startDate: Date, endDate: Date) => httpGet<Sale[]>(`${apiUrl}/${getIsoString(startDate)}/${getIsoString(endDate)}`);
export const getSale = (id: number) => httpGet<Sale>(`${apiUrl}/${id}`);
export const downloadQuoteFormat = (id: number) => httpDownload(`${apiUrl}/quoteformat/${id}`);
export const downloadDeliveryNote = (id: number) => httpDownload(`${apiUrl}/deliverynote/${id}`);
export const downloadSalesReport = (startDate: Date, endDate: Date) => httpDownload(`${apiUrl}/salesreport/${getIsoString(startDate)}/${getIsoString(endDate)}`);
