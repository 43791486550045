import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { AddCircle, Cancel, ExpandMore } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, ListItemText, ListSubheader, Menu, MenuItem, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Purchase } from "../../../core/models/purchase";
import { Provider } from "../../../core/models/provider";
import { PurchaseItem } from "../../../core/models/purchase-item";
import { PurchaseCreditNote } from "../../../core/models/purchase-credit-note";
import { CreditNote } from "../../../core/models/credit-note";
import { CreditNoteItem } from "../../../core/models/credit-note-item";
import { PurchasePayment } from "../../../core/models/purchase-payment";
import { PaymentMethod } from "../../../core/models/payment-method";
import { StockProduct } from "../../../core/models/stock-product";
import { getCurrentLocalDate, getCurrentLocalDateTime, getInvalidDate, isGreaterOrEqual } from "../../../core/services/dates";
import { getProviders } from "../../../core/services/providers";
import { getStockProducts, updateStockProductPurchasePrices } from "../../../core/services/stock-products";
import { getPurchasePaymentMethods } from "../../../core/services/payment-methods";
import { getOpenedCreditNotes, getReleasedCreditNotes } from "../../../core/services/credit-notes";
import { cancelPurchase, completePurchase, downloadOrderFormat, getPurchase, savePurchase } from "../../../core/services/purchases";
import { useNotifications } from "../../../core/hooks/use-notifications";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import SpanishLocale from "date-fns/locale/es";
import ProductSelector from "../../../shared/components/ProductSelector"
import NumberField from "../../../shared/components/NumberField"

const berelProviderId = 1;
const creditNotePaymentMethodId = 9;

const overpaymentCreditNoteDescription = 'Pago Excedido';
const missingItemsCreditNoteDescription = 'Productos Faltantes';
const reservedCreditNoteDescriptions = [overpaymentCreditNoteDescription, missingItemsCreditNoteDescription];

const initialPurchase: Purchase = {
  id: 0,
  providerId: 0,
  date: getCurrentLocalDateTime(),
  includesVat: false,
  completed: false,
  canceled: false,
  remarks: '',
  purchaseItems: [],
  purchaseCreditNotes: [],
  purchasePayments: [],
  providerName: ''
};

const getIsFinished = (purchase: Purchase) => purchase.id > 0 && (purchase.completed || purchase.canceled);
const getCanShowReceived = (purchase: Purchase) => purchase.id > 0;
const getCanChangeReceived = (purchase: Purchase) => !getIsFinished(purchase) && getCanShowReceived(purchase) && purchase.purchasePayments.every(pp => pp.paid);

const getPurchaseItemsTotal = (purchaseItems: PurchaseItem[], includesVat: boolean) => getPurchaseItemsSubtotal(purchaseItems) * getVatFactor(includesVat);
const getPurchaseItemsSubtotal = (purchaseItems: PurchaseItem[]) => purchaseItems.reduce((s, pi) => s + getPurchaseItemSubtotal(pi), 0);
const getPurchaseItemTotal = (purchaseItem: PurchaseItem, includesVat: boolean) => getPurchaseItemSubtotal(purchaseItem) * getVatFactor(includesVat);
const getPurchaseItemSubtotal = (purchaseItem: PurchaseItem) => getPurchaseItemUnitSubtotal(purchaseItem) * purchaseItem.requestedQuantity;
const getPurchaseItemUnitSubtotal = (purchaseItem: PurchaseItem) => purchaseItem.purchasePrice * (1 - purchaseItem.purchaseDiscount / 100);

const getPurchaseItemsReceivedTotal = (purchaseItems: PurchaseItem[], includesVat: boolean) => getPurchaseItemsReceivedSubtotal(purchaseItems) * getVatFactor(includesVat);
const getPurchaseItemsReceivedSubtotal = (purchaseItems: PurchaseItem[]) => purchaseItems.reduce((s, pi) => s + getPurchaseItemReceivedSubtotal(pi), 0);
const getPurchaseItemReceivedTotal = (purchaseItem: PurchaseItem, includesVat: boolean) => getPurchaseItemReceivedSubtotal(purchaseItem) * getVatFactor(includesVat);
const getPurchaseItemReceivedSubtotal = (purchaseItem: PurchaseItem) => getPurchaseItemUnitSubtotal(purchaseItem) * purchaseItem.receivedQuantity;

const getCreditNoteTotal = (creditNote: CreditNote, includesVat: boolean) => getCreditNoteSubtotal(creditNote) * getVatFactor(includesVat);
const getCreditNoteSubtotal = (creditNote: CreditNote) => hasCreditNoteItems(creditNote) ? getCreditNoteItemsSubtotal(creditNote.creditNoteItems) : creditNote.baseAmount * creditNote.creditReturn / 100;
const getCreditNoteItemsSubtotal = (creditNoteItems: CreditNoteItem[]) => creditNoteItems.reduce((s, cni) => s + getCreditNoteItemSubtotal(cni), 0);
const getCreditNoteItemTotal = (creditNoteItem: CreditNoteItem, includesVat: boolean) => getCreditNoteItemSubtotal(creditNoteItem) * getVatFactor(includesVat);
const getCreditNoteItemSubtotal = (creditNoteItem: CreditNoteItem) => creditNoteItem.baseAmount * creditNoteItem.creditReturn / 100 * creditNoteItem.quantity;

const getPurchasePaymentsTotal = (purchasePayments: PurchasePayment[], includesVat: boolean) => getPurchasePaymentsSubtotal(purchasePayments) * getVatFactor(includesVat);
const getPurchasePaymentsSubtotal = (purchasePayments: PurchasePayment[]) => purchasePayments.reduce((s, pp) => s + pp.paymentAmount, 0);
const getPurchasePaymentTotal = (purchasePayment: PurchasePayment, includesVat: boolean) => purchasePayment.paymentAmount * getVatFactor(includesVat);

const getVatFactor = (includesVat: boolean) => includesVat ? 1.16 : 1;

const roundToCents = (n: number) => +`${Math.round(+`${n}e+2`)}e-2`;

const hasCreditNoteItems = (creditNote: CreditNote) => creditNote.opened || creditNote.creditNoteItems.length;

const getValidPurchaseItemIdsToUpdate = (purchaseItemIdsToUpdate: number[], purchaseItems: PurchaseItem[]) => {
  const purchaseItemIds = purchaseItems.filter(pi => pi.purchasePrice > 0 && pi.purchaseDiscount < 100).map(pi => pi.id);
  if (purchaseItemIdsToUpdate.every(pii => purchaseItemIds.includes(pii))) {
    return purchaseItemIdsToUpdate;
  } else {
    return purchaseItemIdsToUpdate.filter(pii => purchaseItemIds.includes(pii));
  }
};

const getValidFixedPurchasePaymentIds = (fixedPurchasePaymentIds: number[], purchasePayments: PurchasePayment[]) => {
  const purchasePaymentIds = purchasePayments.map(pp => pp.id);
  if (fixedPurchasePaymentIds.every(ppi => purchasePaymentIds.includes(ppi))) {
    return fixedPurchasePaymentIds;
  } else {
    return fixedPurchasePaymentIds.filter(ppi => purchasePaymentIds.includes(ppi));
  }
};

const getValidPurchaseCreditNotes = (purchaseCreditNotes: PurchaseCreditNote[], purchaseItems: PurchaseItem[], openedCreditNotes: CreditNote[]) => {
  const purchaseItemIds = purchaseItems.map(pi => pi.id);
  const openedCreditNoteIds = openedCreditNotes.map(cn => cn.id);
  return purchaseCreditNotes.filter(pcn => pcn.id > 0 || pcn.id < 0 || pcn.creditNote.id < 0 || openedCreditNoteIds.includes(pcn.creditNote.id)).map(pcn => ({
    ...pcn,
    creditNote: {
      ...pcn.creditNote,
      creditNoteItems: pcn.creditNote.creditNoteItems.filter(cni => cni.id > 0 || purchaseItemIds.includes(cni.purchaseItemId))
    }
  }));
}

const getValidPurchasePayments = (purchasePayments: PurchasePayment[], purchaseCreditNotes: PurchaseCreditNote[], releasedCreditNotes: CreditNote[]) => {
  const generatedCreditNoteIds = purchaseCreditNotes.filter(pcn => !pcn.creditNote.opened).map(pcn => pcn.creditNote.id);
  const releasedCreditNoteIds = releasedCreditNotes.map(cn => cn.id);
  return purchasePayments.filter(pp => pp.id > 0 || pp.paymentMethodId !== creditNotePaymentMethodId || generatedCreditNoteIds.includes(pp.creditNoteId) || releasedCreditNoteIds.includes(pp.creditNoteId));
};

const getUpdatedPurchaseCreditNotes = (purchaseCreditNotes: PurchaseCreditNote[], purchaseItems: PurchaseItem[], canChangeReceived: boolean, isFinished: boolean) => {
  // If the purchase is being completed, but the received quantities can't be changed, it's safe to use requested quantities because they're the same as received quantities for all items
  if (isFinished) {
    return purchaseCreditNotes;
  } else {
    const currentPurchaseItems = purchaseItems.reduce((cpi, pi) => ({
      ...cpi,
      [pi.id]: {
        usedBaseAmount: getPurchaseItemUnitSubtotal(pi),
        unusedBaseAmount: getPurchaseItemUnitSubtotal(pi),
        requestedQuantity: pi.requestedQuantity,
        receivedQuantity: pi.receivedQuantity,
      }
    }), {} as {
      [key: number]: {
        usedBaseAmount: number;
        unusedBaseAmount: number;
        requestedQuantity: number;
        receivedQuantity: number;
      };
    });

    return purchaseCreditNotes.map((pcn, i) => {
      const hasItems = hasCreditNoteItems(pcn.creditNote);
      const isOverpayment = pcn.creditNote.description === overpaymentCreditNoteDescription;
      const isMissingItems = pcn.creditNote.description === missingItemsCreditNoteDescription;

      const purchaseCreditNote = {
        ...pcn,
        position: i + 1,
        creditNote: {
          ...pcn.creditNote,
          baseAmount: hasItems ? 0 : isOverpayment ? pcn.creditNote.baseAmount : Object.values(currentPurchaseItems).reduce((s, pi) => s + (pcn.creditNote.used ? pi.usedBaseAmount : pi.unusedBaseAmount) * (canChangeReceived ? pi.receivedQuantity : pi.requestedQuantity), 0),
          creditReturn: hasItems ? 0 : isOverpayment ? pcn.creditNote.creditReturn : pcn.creditNote.creditReturn,
          creditNoteItems: pcn.creditNote.creditNoteItems.map(cni => {
            const purchaseItem = currentPurchaseItems[cni.purchaseItemId];
            if (purchaseItem) {
              const creditNoteItem = {
                ...cni,
                baseAmount: isMissingItems ? purchaseItem.usedBaseAmount : pcn.creditNote.used ? purchaseItem.usedBaseAmount : purchaseItem.unusedBaseAmount,
                quantity: isMissingItems ? purchaseItem.requestedQuantity - purchaseItem.receivedQuantity : canChangeReceived ? purchaseItem.receivedQuantity : purchaseItem.requestedQuantity
              };

              purchaseItem.usedBaseAmount *= pcn.creditNote.used ? 1 - cni.creditReturn / 100 : 1;
              purchaseItem.unusedBaseAmount *= 1 - cni.creditReturn / 100;

              return creditNoteItem;
            } else {
              return cni;
            }
          })
        }
      };

      !hasItems && !isOverpayment && Object.keys(currentPurchaseItems).forEach(id => {
        currentPurchaseItems[+id].usedBaseAmount *= pcn.creditNote.used ? 1 - pcn.creditNote.creditReturn / 100 : 1;
        currentPurchaseItems[+id].unusedBaseAmount *= 1 - pcn.creditNote.creditReturn / 100;
      });

      return purchaseCreditNote;
    });
  }
};

const getUpdatedPurchasePayments = (purchasePayments: PurchasePayment[], purchaseCreditNotes: PurchaseCreditNote[], fixedPurchasePaymentIds: number[], itemsSubtotal: number, beginingIndex: number, isFinished: boolean) => {
  if (isFinished) {
    return purchasePayments;
  } else {
    purchasePayments = purchasePayments.map(pp => ({
      pp,
      cn: pp.paymentMethodId === creditNotePaymentMethodId && purchaseCreditNotes.map(pcn => pcn.creditNote).find(cn => cn.id === pp.creditNoteId && !cn.opened)
    })).map(({ pp, cn }) => cn ? {
      ...pp,
      paymentAmount: getCreditNoteSubtotal(cn),
      creditNoteDescription: cn.description
    } : pp);

    const isForward = beginingIndex >= 0;
    const paymentsLength = purchasePayments.length;
    const actualIndex = isForward ? beginingIndex % paymentsLength : (beginingIndex + 1) % paymentsLength;
    const paymentsSubtotal = getPurchasePaymentsSubtotal(purchasePayments);

    const shiftedPayments = [
      ...(isForward ? purchasePayments.slice(actualIndex) : purchasePayments.slice(0, paymentsLength + actualIndex).reverse()),
      ...(isForward ? purchasePayments.slice(0, actualIndex) : purchasePayments.slice(paymentsLength + actualIndex).reverse())
    ];

    const shiftedPaymentAmounts = shiftedPayments.reduce((prev, pp) => {
      const changePaymentAmount = prev.remainingIncrement && pp.id < 0 && pp.paymentMethodId !== creditNotePaymentMethodId && !fixedPurchasePaymentIds.includes(pp.id);
      return {
        remainingIncrement: changePaymentAmount ? Math.min(pp.paymentAmount + prev.remainingIncrement, 0) : prev.remainingIncrement,
        paymentAmounts: [
          ...prev.paymentAmounts,
          changePaymentAmount ? Math.max(pp.paymentAmount + prev.remainingIncrement, 0) : pp.paymentAmount
        ]
      };
    }, {
      remainingIncrement: itemsSubtotal - paymentsSubtotal,
      paymentAmounts: [] as number[]
    }).paymentAmounts;

    const paymentAmounts = [
      ...(isForward ? shiftedPaymentAmounts.slice(paymentsLength - actualIndex) : shiftedPaymentAmounts.slice(0, paymentsLength + actualIndex).reverse()),
      ...(isForward ? shiftedPaymentAmounts.slice(0, paymentsLength - actualIndex) : shiftedPaymentAmounts.slice(paymentsLength + actualIndex).reverse())
    ];

    return purchasePayments.map((pp, i) => ({
      ...pp,
      paymentAmount: paymentAmounts[i]
    }));
  }
};

const addAutomaticCreditNotes = (purchaseCreditNotes: PurchaseCreditNote[], purchaseItems: PurchaseItem[], overpayment: number, canShowReceived: boolean, canChangeReceived: boolean) => ([
  ...purchaseCreditNotes.filter(pcn => pcn.id >= 0),
  ...!canShowReceived && overpayment > 0 ? [{
    id: -1,
    position: 0,
    creditNote: {
      id: Math.min(0, ...purchaseCreditNotes.filter(pcn => pcn.id >= 0).map(pcn => pcn.creditNote.id)) - 1,
      description: overpaymentCreditNoteDescription,
      baseAmount: overpayment,
      creditReturn: 100,
      opened: false,
      released: false,
      used: false,
      creditNoteItems: [],
      providerName: '',
      purchaseIncludesVat: false,
      purchaseIds: [],
      purchaseDates: [],
      purchaseId: 0
    }
  }] : [],
  ...canChangeReceived && purchaseItems.some(pi => pi.receivedQuantity < pi.requestedQuantity) ? [{
    id: -1,
    position: 0,
    creditNote: {
      id: Math.min(0, ...purchaseCreditNotes.filter(pcn => pcn.id >= 0).map(pcn => pcn.creditNote.id)) - 1,
      description: missingItemsCreditNoteDescription,
      baseAmount: 0,
      creditReturn: 0,
      opened: false,
      released: false,
      used: false,
      creditNoteItems: purchaseItems.filter(pi => pi.receivedQuantity < pi.requestedQuantity).map(pi => ({
        id: 0,
        purchaseItemId: pi.id,
        baseAmount: 0,
        creditReturn: 100,
        quantity: 0,
        fullName: pi.fullName,
        purchaseIncludesVat: false
      })),
      providerName: '',
      purchaseIncludesVat: false,
      purchaseIds: [],
      purchaseDates: [],
      purchaseId: 0
    }
  }] : []
]);

export default function PurchasePage() {
  const { id } = useParams();

  const [purchase, setPurchase] = useState<Purchase>({ ...initialPurchase });

  const [providers, setProviders] = useState<Provider[]>([]);
  const [stockProducts, setStockProducts] = useState<StockProduct[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [openedCreditNotes, setOpenedCreditNotes] = useState<CreditNote[]>([]);
  const [releasedCreditNotes, setReleasedCreditNotes] = useState<CreditNote[]>([]);

  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [canShowReceived, setCanShowReceived] = useState<boolean>(false);
  const [canChangeReceived, setCanChangeReceived] = useState<boolean>(false);

  const [purchaseItemsSubtotal, setPurchaseItemsSubtotal] = useState<number>(0);
  const [purchasePaymentsSubtotal, setPurchasePaymentsSubtotal] = useState<number>(0);
  const [purchaseOverpayment, setPurchaseOverpayment] = useState<number>(0);

  const [purchaseItemIdsToUpdate, setPurchaseItemIdsToUpdate] = useState<number[]>([]);
  const [fixedPurchasePaymentIds, setFixedPurchasePaymentIds] = useState<number[]>([]);

  const [addCreditNote, setAddCreditNote] = useState<HTMLElement | null>(null);
  const [addCreditNoteItem, setAddCreditNoteItem] = useState<{ creditNoteIndex: number, anchor: HTMLElement } | null>(null);
  const [addPayment, setAddPayment] = useState<HTMLElement | null>(null);

  const { control, formState: { isSubmitted }, setValue, reset, handleSubmit } = useForm();

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const navigate = useNavigate();

  const setFormValue = useCallback((name: string, value: any) => {
    setValue(name, value, { shouldValidate: isSubmitted });
  }, [isSubmitted, setValue]);

  const resetFormFixedValues = useCallback((purchase: Purchase) => {
    reset({
      provider: purchase.providerId,
      remarks: purchase.remarks,
      ...purchase.purchaseItems.reduce((fv, pi) => ({
        ...fv,
        [`requestedQuantity-${pi.id}`]: pi.requestedQuantity,
        ...getCanShowReceived(purchase) ? { [`receivedQuantity-${pi.id}`]: pi.receivedQuantity } : {}
      }), {}),
      ...purchase.purchaseCreditNotes.map(pcn => pcn.creditNote).reduce((fv, cn) => ({
        ...fv,
        [`creditNoteName-${cn.id}`]: cn.description,
        ...!hasCreditNoteItems(cn) ? { [`creditReturn-${cn.id}`]: cn.creditReturn } : {},
        ...cn.creditNoteItems.reduce((fv, cni) => ({
          ...fv,
          [`creditReturn-${cn.id}-${cni.purchaseItemId}`]: cni.creditReturn
        }), {})
      }), {}),
      ...purchase.purchasePayments.reduce((fv, pp) => ({
        ...fv,
        ...!pp.paid ? { [`paydayLimit-${pp.id}`]: getCurrentLocalDate() } : {}
      }), {})
    });
  }, [reset]);

  useEffect(() => {
    getProviders().then(providers => setProviders(providers)).catch(() => showErrorNotification('Error al cargar los Proveedores.'));
    getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos.'));
    getPurchasePaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods)).catch(() => showErrorNotification('Error al cargar los Métodos de Pago.'));
  }, [showErrorNotification]);

  useEffect(() => {
    if (id) {
      getPurchase(+id)
        .then(purchase => {
          const loadingPurchase = {
            ...purchase,
            purchaseItems: purchase.purchaseItems.map(pi => ({
              ...pi,
              receivedQuantity: getIsFinished(purchase) ? pi.receivedQuantity : pi.requestedQuantity
            }))
          };
          resetFormFixedValues(loadingPurchase);
          setPurchase(loadingPurchase);
        })
        .catch(() => showErrorNotification('Error al cargar la Compra.'));
    } else {
      setPurchase({ ...initialPurchase });
    }
  }, [id, resetFormFixedValues, showErrorNotification]);

  useEffect(() => {
    if (purchase.providerId) {
      getOpenedCreditNotes(purchase.providerId).then(creditNotes => setOpenedCreditNotes(creditNotes)).catch(() => showErrorNotification('Error al cargar las Notas de Crédito Abiertas.'));
      getReleasedCreditNotes(purchase.providerId).then(creditNotes => setReleasedCreditNotes(creditNotes)).catch(() => showErrorNotification('Error al cargar las Notas de Crédito Liberadas.'));
    } else {
      setOpenedCreditNotes([])
      setReleasedCreditNotes([])
    }
  }, [purchase.providerId, showErrorNotification]);

  useEffect(() => {
    setIsFinished(getIsFinished(purchase));
    setCanShowReceived(getCanShowReceived(purchase));
    setCanChangeReceived(getCanChangeReceived(purchase));
  }, [purchase]);

  useEffect(() => {
    setPurchaseItemsSubtotal(canShowReceived ? getPurchaseItemsReceivedSubtotal(purchase.purchaseItems) : getPurchaseItemsSubtotal(purchase.purchaseItems));
    setPurchaseItemIdsToUpdate(purchaseItemIdsToUpdate => getValidPurchaseItemIdsToUpdate(purchaseItemIdsToUpdate, purchase.purchaseItems));
  }, [purchase.purchaseItems, canShowReceived]);

  useEffect(() => {
    setPurchasePaymentsSubtotal(getPurchasePaymentsSubtotal(purchase.purchasePayments));
    setFixedPurchasePaymentIds(fixedPurchasePaymentIds => getValidFixedPurchasePaymentIds(fixedPurchasePaymentIds, purchase.purchasePayments));
  }, [purchase.purchasePayments]);

  useEffect(() => {
    setPurchaseOverpayment(roundToCents(purchasePaymentsSubtotal) - roundToCents(purchaseItemsSubtotal));
  }, [purchaseItemsSubtotal, purchasePaymentsSubtotal]);

  useEffect(() => {
    setPurchase(purchase => ({
      ...purchase,
      purchaseCreditNotes: getUpdatedPurchaseCreditNotes(addAutomaticCreditNotes(getValidPurchaseCreditNotes(purchase.purchaseCreditNotes, purchase.purchaseItems, openedCreditNotes), purchase.purchaseItems, purchaseOverpayment, canShowReceived, canChangeReceived), purchase.purchaseItems, canChangeReceived, isFinished)
    }));
  }, [purchase.purchaseItems, openedCreditNotes, purchaseOverpayment, canShowReceived, canChangeReceived, isFinished]);

  useEffect(() => {
    setPurchase(purchase => ({
      ...purchase,
      purchasePayments: getUpdatedPurchasePayments(getValidPurchasePayments(purchase.purchasePayments, purchase.purchaseCreditNotes, releasedCreditNotes), purchase.purchaseCreditNotes, fixedPurchasePaymentIds, purchaseItemsSubtotal, -1, isFinished)
    }));
  }, [purchase.purchaseCreditNotes, releasedCreditNotes, fixedPurchasePaymentIds, purchaseItemsSubtotal, isFinished]);

  useEffect(() => {
    setFormValue('purchaseItemsCount', purchase.purchaseItems.length);
  }, [purchase.purchaseItems.length, setFormValue]);

  useEffect(() => {
    setFormValue('purchaseSubtotals', {
      itemsSubtotal: purchaseItemsSubtotal,
      paymentsSubtotal: purchasePaymentsSubtotal
    });
  }, [purchaseItemsSubtotal, purchasePaymentsSubtotal, setFormValue]);

  useEffect(() => {
    purchase.purchaseCreditNotes.map(pcn => pcn.creditNote).forEach(cn => setFormValue(`creditNoteItemsCount-${cn.id}`, {
      isOpened: cn.opened,
      itemsCount: cn.creditNoteItems.length
    }));
  }, [purchase.purchaseCreditNotes, setFormValue]);

  useEffect(() => {
    const paymentCreditNoteIds = purchase.purchasePayments.filter(pp => pp.paymentMethodId === creditNotePaymentMethodId).map(pp => pp.creditNoteId);
    const paymentCreditNotePositions = purchase.purchaseCreditNotes.filter(pcn => paymentCreditNoteIds.includes(pcn.creditNote.id)).map(pcn => pcn.position);
    purchase.purchasePayments.forEach(pp => {
      const creditNotePosition = purchase.purchaseCreditNotes.find(pcn => pcn.creditNote.id === pp.creditNoteId)?.position ?? 0;
      setFormValue(`beginingCreditNotes-${pp.id}`, paymentCreditNotePositions.filter(pcnp => pcnp <= creditNotePosition).length === creditNotePosition);
    });
  }, [purchase.purchasePayments, purchase.purchaseCreditNotes, setFormValue]);

  const handlePurchaseChange = <T extends keyof Purchase>(name: T, value: Purchase[T]) => setPurchase(purchase => ({ ...purchase, [name]: value }));

  const handleStockProductSelect = (sp: StockProduct | null) => {
    setPurchase(purchase => ({
      ...purchase,
      purchaseItems: [
        ...purchase.purchaseItems,
        {
          id: Math.min(0, ...purchase.purchaseItems.map(pi => pi.id)) - 1,
          productId: sp!.productId,
          productTypeId: sp!.productTypeId,
          measureId: sp!.measureId,
          purchasePrice: sp!.purchasePrice,
          purchaseDiscount: sp!.purchaseDiscount,
          requestedQuantity: 1,
          receivedQuantity: 0,
          fullName: sp!.fullName
        }
      ]
    }));
  };

  const handleRemovePurchaseItemClick = (index: number) => setPurchase(purchase => ({
    ...purchase,
    purchaseItems: purchase.purchaseItems.filter((_, i) => i !== index)
  }));

  const handlePurchaseItemChange = <T extends keyof PurchaseItem>(index: number, name: T, value: PurchaseItem[T]) => setPurchase(purchase => ({
    ...purchase,
    purchaseItems: purchase.purchaseItems.map((pi, i) => i === index ? {
      ...pi,
      [name]: value
    } : pi)
  }));

  const handleUpdatePriceChange = (purchaseItemId: number, update: boolean) => {
    setPurchaseItemIdsToUpdate([
      ...purchaseItemIdsToUpdate.filter(pii => pii !== purchaseItemId),
      ...update ? [purchaseItemId] : []
    ]);
  };

  const handleAddCreditNoteOpen = (anchor: HTMLElement) => setAddCreditNote(anchor);
  const handleAddCreditNoteClose = () => setAddCreditNote(null);

  const handleAddCreditNoteClick = (creditNoteId: number) => {
    setAddCreditNote(null);
    setPurchase(purchase => ({
      ...purchase,
      purchaseCreditNotes: getUpdatedPurchaseCreditNotes(addAutomaticCreditNotes([
        ...purchase.purchaseCreditNotes.filter(pcn => pcn.id >= 0),
        {
          id: 0,
          position: 0,
          creditNote: creditNoteId ? {
            ...openedCreditNotes.find(cn => cn.id === creditNoteId)!
          } : {
            id: Math.min(0, ...purchase.purchaseCreditNotes.filter(pcn => pcn.id >= 0).map(pcn => pcn.creditNote.id)) - 1,
            description: '',
            baseAmount: 0,
            creditReturn: 0,
            opened: false,
            released: false,
            used: false,
            creditNoteItems: [],
            providerName: '',
            purchaseIncludesVat: false,
            purchaseIds: [],
            purchaseDates: [],
            purchaseId: 0
          }
        }
      ], purchase.purchaseItems, purchaseOverpayment, canShowReceived, canChangeReceived), purchase.purchaseItems, canChangeReceived, isFinished)
    }));
  };

  const handleRemoveCreditNoteClick = (index: number) => setPurchase(purchase => ({
    ...purchase,
    purchaseCreditNotes: getUpdatedPurchaseCreditNotes(purchase.purchaseCreditNotes.filter((_, i) => i !== index), purchase.purchaseItems, canChangeReceived, isFinished)
  }));

  const handleCreditNoteChange = <T extends keyof CreditNote>(index: number, name: T, value: CreditNote[T]) => setPurchase(purchase => ({
    ...purchase,
    purchaseCreditNotes: getUpdatedPurchaseCreditNotes(purchase.purchaseCreditNotes.map((pcn, i) => i === index ? {
      ...pcn,
      creditNote: {
        ...pcn.creditNote,
        [name]: value
      }
    } : pcn), purchase.purchaseItems, canChangeReceived, isFinished)
  }));

  const handleAddCreditNoteItemOpen = (creditNoteIndex: number, anchor: HTMLElement) => setAddCreditNoteItem({ creditNoteIndex, anchor });
  const handleAddCreditNoteItemClose = () => setAddCreditNoteItem(null);

  const handleAddCreditNoteItemClick = (purchaseItemId: number) => {
    setAddCreditNoteItem(null);
    setPurchase(purchase => ({
      ...purchase,
      purchaseCreditNotes: getUpdatedPurchaseCreditNotes(purchase.purchaseCreditNotes.map((pcn, i) => i === addCreditNoteItem?.creditNoteIndex ? {
        ...pcn,
        creditNote: {
          ...pcn.creditNote,
          creditNoteItems: [
            ...pcn.creditNote.creditNoteItems,
            {
              id: 0,
              purchaseItemId: purchaseItemId,
              baseAmount: 0,
              creditReturn: 0,
              quantity: 0,
              fullName: purchase.purchaseItems.find(pi => pi.id === purchaseItemId)!.fullName,
              purchaseIncludesVat: false
            }
          ]
        }
      } : pcn), purchase.purchaseItems, canChangeReceived, isFinished)
    }));
  };

  const handleRemoveCreditNoteItemClick = (purchaseCreditNoteIndex: number, creditNoteItemIndex: number) => setPurchase(purchase => ({
    ...purchase,
    purchaseCreditNotes: getUpdatedPurchaseCreditNotes(purchase.purchaseCreditNotes.map((pcn, i) => i === purchaseCreditNoteIndex ? {
      ...pcn,
      creditNote: {
        ...pcn.creditNote,
        creditNoteItems: pcn.creditNote.creditNoteItems.filter((_, j) => j !== creditNoteItemIndex)
      }
    } : pcn), purchase.purchaseItems, canChangeReceived, isFinished)
  }));

  const handleCreditNoteItemChange = <T extends keyof CreditNoteItem>(purchaseCreditNoteIndex: number, creditNoteItemIndex: number, name: T, value: CreditNoteItem[T]) => setPurchase(purchase => ({
    ...purchase,
    purchaseCreditNotes: getUpdatedPurchaseCreditNotes(purchase.purchaseCreditNotes.map((pcn, i) => i === purchaseCreditNoteIndex ? {
      ...pcn,
      creditNote: {
        ...pcn.creditNote,
        creditNoteItems: pcn.creditNote.creditNoteItems.map((cni, j) => j === creditNoteItemIndex ? {
          ...cni,
          [name]: value
        } : cni)
      }
    } : pcn), purchase.purchaseItems, canChangeReceived, isFinished)
  }));

  const handleAddPaymentOpen = (anchor: HTMLElement) => setAddPayment(anchor);
  const handleAddPaymentClose = () => setAddPayment(null);

  const handleAddPaymentClick = (paymentMethodId: number, creditNoteId: number) => {
    setAddPayment(null);
    setPurchase(purchase => {
      const creditNote = creditNoteId
        ? creditNoteId > 0
          ? releasedCreditNotes.find(cn => cn.id === creditNoteId)!
          : purchase.purchaseCreditNotes.map(pcn => pcn.creditNote).find(cn => cn.id === creditNoteId)!
        : null;

      return {
        ...purchase,
        purchasePayments: getUpdatedPurchasePayments([
          ...purchase.purchasePayments,
          {
            id: Math.min(0, ...purchase.purchasePayments.map(pp => pp.id)) - 1,
            purchaseId: 0,
            paymentMethodId: paymentMethodId,
            creditNoteId: creditNoteId,
            paymentAmount: creditNote ? getCreditNoteSubtotal(creditNote) : 0,
            paydayLimit: getCurrentLocalDate(),
            paymentDate: getCurrentLocalDateTime(),
            paid: creditNote ? true : false,
            purchaseProvider: '',
            purchaseIncludesVat: false,
            paymentMethodName: paymentMethods.find(pm => pm.id === paymentMethodId)!.name,
            creditNoteDescription: creditNote ? creditNote.description : ''
          }
        ], purchase.purchaseCreditNotes, fixedPurchasePaymentIds, purchaseItemsSubtotal, purchase.purchasePayments.length, isFinished)
      };
    });
  };

  const handleRemovePaymentClick = (index: number) => setPurchase(purchase => ({
    ...purchase,
    purchasePayments: getUpdatedPurchasePayments(purchase.purchasePayments.filter((_, i) => i !== index), purchase.purchaseCreditNotes, fixedPurchasePaymentIds, purchaseItemsSubtotal, index, isFinished)
  }));

  const handlePaymentChange = <T extends keyof PurchasePayment>(index: number, name: T, value: PurchasePayment[T]) => setPurchase(purchase => ({
    ...purchase,
    purchasePayments: getUpdatedPurchasePayments(purchase.purchasePayments.map((pp, i) => i === index ? {
      ...pp,
      [name]: value
    } : pp), purchase.purchaseCreditNotes, fixedPurchasePaymentIds, purchaseItemsSubtotal, index + 1, isFinished)
  }));

  const handleFixedPaymentChange = (purchasePaymentId: number, fixed: boolean) => {
    setFixedPurchasePaymentIds([
      ...fixedPurchasePaymentIds.filter(ppi => ppi !== purchasePaymentId),
      ...fixed ? [purchasePaymentId] : []
    ]);
  };

  const handleSavePurchaseClick = () => {
    if (purchaseItemIdsToUpdate.length) {
      updateStockProductPurchasePrices(purchaseItemIdsToUpdate.map(pii => {
        const purchaseItem = purchase.purchaseItems.find(pi => pi.id === pii)!;
        return {
          id: 0,
          productId: purchaseItem.productId,
          productTypeId: purchaseItem.productTypeId,
          measureId: purchaseItem.measureId,
          priceTypeId: 0,
          priceProfit: 0,
          purchasePrice: purchaseItem.purchasePrice,
          purchaseDiscount: purchaseItem.purchaseDiscount,
          salePrice: 0,
          saleDiscount: 0,
          providerId: 0,
          measureUnitsNumber: 0,
          priceTypeDescription: '',
          fullName: ''
        };
      }))
        .then(() => {
          setPurchaseItemIdsToUpdate([]);
          showSuccessNotification('Productos actualizados correctamente.');
        })
        .catch(() => showErrorNotification('Error al actualizar los productos.'));
    }

    savePurchase({
      ...purchase,
      date: getCurrentLocalDateTime()
    })
      .then(purchase => {
        setFixedPurchasePaymentIds([]);
        navigate(`/pedidos/${purchase.id}`);
        showSuccessNotification('Compra registrada correctamente.');
      })
      .catch(() => showErrorNotification('Error al registrar la Compra.'));
  };

  const handleCompletePurchaseClick = () => {
    completePurchase({
      ...purchase,
      date: getCurrentLocalDateTime()
    })
      .then(purchase => {
        setPurchase(purchase);
        showSuccessNotification('Compra completada correctamente.');
      })
      .catch(() => showErrorNotification('Error al completar la Compra.'));
  };

  const handleCancelPurchaseClick = () => {
    cancelPurchase({
      ...purchase,
      date: getCurrentLocalDateTime()
    })
      .then(purchase => {
        setPurchase(purchase);
        showSuccessNotification('Compra cancelada correctamente.');
      })
      .catch(() => showErrorNotification('Error al cancelar la Compra.'));
  };

  const handleDownloadOrderFormatClick = () => {
    downloadOrderFormat(purchase.id)
      .then(file => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(file.data);
        anchor.download = file.name;
        anchor.click();
        showSuccessNotification('Formato de Pedidos descargado correctamente.');
      })
      .catch(() => showErrorNotification('Error al descargar el Formato de Pedidos.'));
  };

  return (
    <Box>
      <Grid container spacing={4} mb={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Datos Generales</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
            <Controller name="provider" defaultValue={purchase.providerId || ''} control={control} rules={{ required: true }} render={({ field: { onChange }, fieldState: { invalid } }) => (
              <TextField select fullWidth label="Proveedor" disabled={purchase.id > 0} error={invalid} value={purchase.providerId || ''} onChange={e => { onChange(e); handlePurchaseChange('providerId', +e.target.value); }}>
                {(providers.length || purchase.id === 0 ? providers : [{
                  id: purchase.providerId,
                  name: 'Proveedor'
                }]).map(p => (
                  <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                ))}
              </TextField>
            )} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel label="Incluir IVA" control={<Switch disabled={purchase.id > 0} checked={purchase.includesVat} onChange={(_, c) => handlePurchaseChange('includesVat', c)} />} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel label="Completar Compra" control={<Switch disabled={purchase.id > 0} checked={purchase.completed} onChange={(_, c) => handlePurchaseChange('completed', c)} />} />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={8} md={6}>
            <Controller name="remarks" defaultValue={purchase.remarks} control={control} rules={{ maxLength: 500 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
              <TextField multiline fullWidth label="Notas de Compra" disabled={purchase.id > 0} error={invalid} value={purchase.remarks} onChange={e => { onChange(e); handlePurchaseChange('remarks', e.target.value); }} />
            )} />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
      <Grid container spacing={4} mt={0} mb={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Productos</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={11} sm={8} md={6}>
            <Controller name="purchaseItemsCount" control={control} rules={{ validate: pic => pic > 0 }} render={({ fieldState: { invalid } }) => (
              <ProductSelector
                stockProducts={stockProducts}
                disabled={purchase.id > 0}
                error={invalid}
                onStockProductSelect={handleStockProductSelect}
              />
            )} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Producto</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Actualizar</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Precio</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Descuento</Box></TableCell>
                {canShowReceived ? (
                  <>
                    <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Solicitado</Box></TableCell>
                    <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Recibido</Box></TableCell>
                  </>
                ) : (
                  <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Cantidad</Box></TableCell>
                )}
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchase.purchaseItems.map((pi, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography variant="body1">{pi.fullName}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled={pi.id > 0 || pi.purchasePrice <= 0 || pi.purchaseDiscount >= 100} checked={purchaseItemIdsToUpdate.includes(pi.id)} onChange={(_, c) => handleUpdatePriceChange(pi.id, c)} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="write" format="currency" disabled={pi.id > 0} value={pi.purchasePrice} onChange={v => handlePurchaseItemChange(i, 'purchasePrice', v)} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="write" format="percentage" disabled={pi.id > 0} value={pi.purchaseDiscount} onChange={v => handlePurchaseItemChange(i, 'purchaseDiscount', v)} />
                  </TableCell>
                  <TableCell align="center">
                    <Controller shouldUnregister name={`requestedQuantity-${pi.id}`} defaultValue={pi.requestedQuantity} control={control} rules={{ min: 1 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                      <NumberField type="write" format="integer" disabled={pi.id > 0} error={invalid} value={pi.requestedQuantity} onChange={v => { onChange(v); handlePurchaseItemChange(i, 'requestedQuantity', v); }} />
                    )} />
                  </TableCell>
                  {canShowReceived && (
                    <TableCell align="center">
                      <Controller shouldUnregister name={`receivedQuantity-${pi.id}`} defaultValue={pi.receivedQuantity} control={control} rules={{ max: pi.requestedQuantity }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                        <NumberField type="write" format="integer" disabled={!canChangeReceived} error={invalid} value={pi.receivedQuantity} onChange={v => { onChange(v); handlePurchaseItemChange(i, 'receivedQuantity', v); }} />
                      )} />
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={(canShowReceived ? getPurchaseItemReceivedSubtotal : getPurchaseItemSubtotal)(pi)} />
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={(canShowReceived ? getPurchaseItemReceivedTotal : getPurchaseItemTotal)(pi, purchase.includesVat)} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton color="error" size="small" disabled={pi.id > 0} onClick={() => handleRemovePurchaseItemClick(i)}>
                      <Cancel />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={canShowReceived ? 6 : 5} sx={{ borderBottom: 'none' }}></TableCell>
                <TableCell align="center" sx={{ color: theme => theme.palette.text.primary, fontWeight: 'bold' }}>
                  <NumberField type="text" format="currency" value={(canShowReceived ? getPurchaseItemsReceivedSubtotal : getPurchaseItemsSubtotal)(purchase.purchaseItems)} />
                </TableCell>
                <TableCell align="center" sx={{ color: theme => theme.palette.text.primary, fontWeight: 'bold' }}>
                  <NumberField type="text" format="currency" value={(canShowReceived ? getPurchaseItemsReceivedTotal : getPurchaseItemsTotal)(purchase.purchaseItems, purchase.includesVat)} />
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
      <Grid container spacing={4} mt={0} mb={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Notas de Crédito</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" disabled={purchase.id > 0} endIcon={<ExpandMore />} onClick={e => handleAddCreditNoteOpen(e.currentTarget)}>Agregar</Button>
          <Menu anchorEl={addCreditNote} open={!!addCreditNote} onClose={handleAddCreditNoteClose}>
            <MenuItem onClick={() => handleAddCreditNoteClick(0)}>Nueva Nota de Crédito</MenuItem>
            {!!openedCreditNotes.length && ([
              <Divider key={-2} />,
              <ListSubheader key={-1}>Notas de Crédito Abiertas</ListSubheader>,
              openedCreditNotes.map((cn, i) => (
                <MenuItem key={i} disabled={!!purchase.purchaseCreditNotes.find(pcn => pcn.creditNote.id === cn.id)} onClick={() => handleAddCreditNoteClick(cn.id)}>
                  <ListItemText inset>{cn.description}</ListItemText>
                </MenuItem>
              ))
            ])}
          </Menu>
        </Grid>
        <Grid item xs={12} md={6}>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Nota de Crédito</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Abierta</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Importe Base</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Crédito</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Cantidad</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                <TableCell>
                  <Menu anchorEl={addCreditNoteItem?.anchor} open={!!addCreditNoteItem} onClose={handleAddCreditNoteItemClose}>
                    {purchase.purchaseItems.map((pi, i) => (
                      <MenuItem
                        key={i}
                        disabled={!!addCreditNoteItem && !!purchase.purchaseCreditNotes[addCreditNoteItem.creditNoteIndex].creditNote.creditNoteItems.find(cni => cni.purchaseItemId === pi.id)}
                        onClick={() => handleAddCreditNoteItemClick(pi.id)}
                      >{pi.fullName}</MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchase.purchaseCreditNotes.map((pcn, i) => {
                const cn = pcn.creditNote;
                return (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell colSpan={2}>
                        {cn.id > 0 || pcn.id < 0 ? (
                          <TextField fullWidth size="small" disabled={true} value={cn.description} />
                        ) : (
                          <Controller shouldUnregister name={`creditNoteName-${cn.id}`} defaultValue={cn.description} control={control} rules={{ required: true, maxLength: 50, validate: v => !reservedCreditNoteDescriptions.includes(v) }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                            <TextField fullWidth size="small" error={invalid} value={cn.description} onChange={e => { onChange(e); handleCreditNoteChange(i, 'description', e.target.value); }} />
                          )} />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox disabled={cn.id > 0 || pcn.id < 0} checked={cn.opened} onChange={(_, c) => handleCreditNoteChange(i, 'opened', c)} />
                      </TableCell>
                      <TableCell align="center">
                        {!hasCreditNoteItems(cn) && <NumberField type="text" format="currency" value={cn.baseAmount} />}
                      </TableCell>
                      <TableCell align="center">
                        {!hasCreditNoteItems(cn) && (
                          <Controller shouldUnregister name={`creditReturn-${cn.id}`} defaultValue={cn.creditReturn} control={control} rules={{ validate: v => v > 0 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                            <NumberField type="write" format="percentage" disabled={cn.id > 0 || pcn.id < 0} error={invalid} value={cn.creditReturn} onChange={v => { onChange(v); handleCreditNoteChange(i, 'creditReturn', v); }} />
                          )} />
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell align="center">
                        <Controller shouldUnregister name={`creditNoteItemsCount-${cn.id}`} control={control} rules={{ validate: cno => !cno.isOpened || cno.itemsCount > 0 }} render={({ fieldState: { invalid } }) => (
                          <NumberField type="text" format="currency" error={invalid} value={getCreditNoteSubtotal(cn)} />
                        )} />
                      </TableCell>
                      <TableCell align="center">
                        <Controller shouldUnregister name={`creditNoteItemsCount-${cn.id}`} control={control} rules={{ validate: cno => !cno.isOpened || cno.itemsCount > 0 }} render={({ fieldState: { invalid } }) => (
                          <NumberField type="text" format="currency" error={invalid} value={getCreditNoteTotal(cn, purchase.includesVat)} />
                        )} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton color="success" size="small" disabled={purchase.id > 0 || !purchase.purchaseItems.length || pcn.id < 0} onClick={e => handleAddCreditNoteItemOpen(i, e.currentTarget)}>
                          <AddCircle />
                        </IconButton>
                        <IconButton color="error" size="small" disabled={purchase.id > 0 || pcn.id < 0} onClick={() => handleRemoveCreditNoteClick(i)}>
                          <Cancel />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {cn.creditNoteItems.map((cni, j) => (
                      <TableRow key={`${i}-${j}`}>
                        {j < cn.creditNoteItems.length - 1 ? (
                          <TableCell sx={{ width: 100, borderBottom: 'none' }}></TableCell>
                        ) : (
                          <TableCell sx={{ width: 100 }}></TableCell>
                        )}
                        <TableCell colSpan={2}>{cni.fullName}</TableCell>
                        <TableCell align="center">
                          <NumberField type="text" format="currency" size="xsmall" value={cni.baseAmount} />
                        </TableCell>
                        <TableCell align="center">
                          <Controller shouldUnregister name={`creditReturn-${cn.id}-${cni.purchaseItemId}`} defaultValue={cni.creditReturn} control={control} rules={{ validate: v => v > 0 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                            <NumberField type="write" format="percentage" size="xsmall" disabled={cni.id > 0 || pcn.id < 0} error={invalid} value={cni.creditReturn} onChange={v => { onChange(v); handleCreditNoteItemChange(i, j, 'creditReturn', v); }} />
                          )} />
                        </TableCell>
                        <TableCell align="center">
                          <NumberField type="text" format="integer" size="xsmall" value={cni.quantity} />
                        </TableCell>
                        <TableCell align="center">
                          <NumberField type="text" format="currency" size="xsmall" value={getCreditNoteItemSubtotal(cni)} />
                        </TableCell>
                        <TableCell align="center">
                          <NumberField type="text" format="currency" size="xsmall" value={getCreditNoteItemTotal(cni, purchase.includesVat)} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton color="error" size="small" disabled={cni.id > 0 || pcn.id < 0} onClick={() => handleRemoveCreditNoteItemClick(i, j)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
      <Grid container spacing={4} mt={0} mb={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Pagos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" disabled={purchase.id > 0} endIcon={<ExpandMore />} onClick={e => handleAddPaymentOpen(e.currentTarget)}>Agregar</Button>
          <Menu anchorEl={addPayment} open={!!addPayment} onClose={handleAddPaymentClose}>
            {paymentMethods.filter(pm => pm.id !== creditNotePaymentMethodId).map((pm, i) => (
              <MenuItem key={i} onClick={() => handleAddPaymentClick(pm.id, 0)}>{pm.name}</MenuItem>
            ))}
            {!!purchase.purchaseCreditNotes.filter(pcn => !pcn.creditNote.opened).length && ([
              <Divider key={-2} />,
              <ListSubheader key={-1}>Notas de Crédito Generadas</ListSubheader>,
              purchase.purchaseCreditNotes.map(pcn => pcn.creditNote).filter(cn => !cn.opened && !reservedCreditNoteDescriptions.includes(cn.description)).map((cn, i) => (
                <MenuItem key={i} disabled={!!purchase.purchasePayments.find(pp => pp.creditNoteId === cn.id)} onClick={() => handleAddPaymentClick(creditNotePaymentMethodId, cn.id)}>
                  <ListItemText inset>{cn.description || 'Nueva Nota de Crédito'}</ListItemText>
                </MenuItem>
              ))
            ])}
            {!!releasedCreditNotes.length && ([
              <Divider key={-2} />,
              <ListSubheader key={-1}>Notas de Crédito Liberadas</ListSubheader>,
              releasedCreditNotes.map((cn, i) => (
                <MenuItem key={i} disabled={!!purchase.purchasePayments.find(pp => pp.creditNoteId === cn.id)} onClick={() => handleAddPaymentClick(creditNotePaymentMethodId, cn.id)}>
                  <ListItemText inset>{cn.description}</ListItemText>
                </MenuItem>
              ))
            ])}
          </Menu>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Forma de Pago</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Fijo</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Pagado</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Límite de Pago</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
                <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchase.purchasePayments.map((pp, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Controller shouldUnregister name={`beginingCreditNotes-${pp.id}`} control={control} rules={{ validate: bcn => bcn }} render={({ fieldState: { invalid } }) => (
                      <Typography variant="body1" color={invalid ? 'error' : 'default'}>{pp.paymentMethodName}{pp.paymentMethodId === creditNotePaymentMethodId ? `: ${pp.creditNoteDescription || 'Nueva Nota de Crédito'}` : ''}</Typography>
                    )} />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled={pp.id > 0 || pp.paymentMethodId === creditNotePaymentMethodId} checked={fixedPurchasePaymentIds.includes(pp.id)} onChange={(_, c) => handleFixedPaymentChange(pp.id, c)} />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled={pp.id > 0 || pp.paymentMethodId === creditNotePaymentMethodId} checked={pp.paid} onChange={(_, c) => handlePaymentChange(i, 'paid', c)} />
                  </TableCell>
                  <TableCell align="center">
                    {!pp.paid && (
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={SpanishLocale}>
                        <Controller shouldUnregister name={`paydayLimit-${pp.id}`} defaultValue={pp.paydayLimit} control={control} rules={{ validate: d => isGreaterOrEqual(d ?? getInvalidDate(), getCurrentLocalDate()) }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                          <DatePicker minDate={getCurrentLocalDate()} disabled={pp.id > 0} value={pp.paydayLimit} onChange={d => { onChange(d ?? getInvalidDate()); handlePaymentChange(i, 'paydayLimit', d ?? getInvalidDate()) }} renderInput={params => <TextField {...params} size="small" error={invalid} sx={{ width: 150 }} />} />
                        )} />
                      </LocalizationProvider>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {pp.paymentMethodId !== creditNotePaymentMethodId && (purchase.purchasePayments.filter(pp => pp.paymentMethodId !== creditNotePaymentMethodId && !fixedPurchasePaymentIds.includes(pp.id)).length > 1 || fixedPurchasePaymentIds.includes(pp.id)) ? (
                      <Controller shouldUnregister name={`paymentAmount-${pp.id}`} defaultValue={pp.paymentAmount} control={control} rules={{ validate: v => v > 0 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                        <NumberField type="write" format="currency" disabled={pp.id > 0} error={invalid} value={pp.paymentAmount} onChange={v => { onChange(v); handlePaymentChange(i, 'paymentAmount', v); }} />
                      )} />
                    ) : (
                      <NumberField type="text" format="currency" value={pp.paymentAmount} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <NumberField type="text" format="currency" value={getPurchasePaymentTotal(pp, purchase.includesVat)} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton color="error" size="small" disabled={pp.id > 0} onClick={() => handleRemovePaymentClick(i)}>
                      <Cancel />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <Controller name="purchaseSubtotals" control={control} rules={{ validate: ps => roundToCents(ps.paymentsSubtotal) >= roundToCents(ps.itemsSubtotal) }} render={({ fieldState: { invalid } }) => (
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderBottom: 'none' }}></TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    <NumberField type="text" format="currency" error={invalid} value={getPurchasePaymentsSubtotal(purchase.purchasePayments)} />
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    <NumberField type="text" format="currency" error={invalid} value={getPurchasePaymentsTotal(purchase.purchasePayments, purchase.includesVat)} />
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                </TableRow>
              )} />
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
      <Grid container spacing={4} mt={0} mb={4}>
        <Grid item xs={12}>
          {purchase.id === 0 ? (
            <Button variant="contained" color="success" onClick={handleSubmit(handleSavePurchaseClick)}>Guardar Orden</Button>
          ) : !isFinished ? (
            <Stack direction="row" spacing={2}>
              {purchase.providerId === berelProviderId && (
                <Button variant="contained" color="info" onClick={handleDownloadOrderFormatClick}>Formato de Pedidos</Button>
              )}
              <Button variant="contained" color="secondary" onClick={handleSubmit(handleCompletePurchaseClick)}>Completar Orden</Button>
              <Button variant="contained" color="error" onClick={handleCancelPurchaseClick}>Cancelar Orden</Button>
            </Stack>
          ) : <></>}
        </Grid>
      </Grid>
    </Box >
  );
}