import { useEffect, useState } from "react";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useNotifications } from "../../core/hooks/use-notifications";
import { Employee } from "../../core/models/employee";
import { Loan } from "../../core/models/loan";
import { LoanItem } from "../../core/models/loan-item";
import { LoanPayment } from "../../core/models/loan-payment";
import { PartnerStore } from "../../core/models/partner-store";
import { PaymentMethod } from "../../core/models/payment-method";
import { StockProduct } from "../../core/models/stock-product";
import { getCurrentLocalDateTime } from "../../core/services/dates";
import { getEmployees } from "../../core/services/employees";
import { getItemsTotal, getNextTemporaryId, getUpdateBeginingIndex, getUpdatedEntitiesTotal, getUpdatedEntityDate, setEntityPropertyValue } from "../../core/services/entities";
import { cancelLoan, completeLoan, downloadLoanContract, getLoan, saveLoan, updateLoan } from "../../core/services/loans";
import { roundToCents } from "../../core/services/numbers";
import { getPartnerStores } from "../../core/services/partner-stores";
import { getLoanPaymentMethods } from "../../core/services/payment-methods";
import { getStockProducts } from "../../core/services/stock-products";
import { condonationPaymentMethodId, productPaymentMethodId, productPaymentMethodName, vatFactor } from "../../core/utils/app-constants";
import PropertyTable from "../../shared/components/PropertyTable";

const isSaved = (loan: Loan) => loan.id > 0;
const isFinished = (loan: Loan) => isSaved(loan) && (loan.completed || loan.canceled);
const isPaid = (loan: Loan) => loan.loanItems.filter(li => !li.returned).reduce((s, li) => s + getLoanItemTotal(li), 0) === loan.loanPayments.reduce((s, lp) => s + getLoanPaymentTotal(lp), 0);

const getInitialLoan = (): Loan => ({
  id: 0,
  employeeId: 0,
  partnerStoreId: 0,
  date: getCurrentLocalDateTime(),
  borrower: false,
  partnerStoreRepresentative: '',
  completed: false,
  canceled: false,
  remarks: '',
  loanItems: [],
  loanPayments: [],
  employeeName: '',
  partnerStoreName: ''
});

const getInitialLoanItem = (stockProduct: StockProduct, loan: Loan): LoanItem => ({
  id: getNextTemporaryId(loan.loanItems),
  productId: stockProduct.productId,
  productTypeId: stockProduct.productTypeId,
  measureId: stockProduct.measureId,
  purchasePrice: stockProduct.purchasePrice,
  purchaseDiscount: stockProduct.purchaseDiscount,
  quantity: 1,
  returned: isSaved(loan),
  fullName: stockProduct.fullName
});

const getInitialLoanPayment = (paymentMethod: PaymentMethod, loan: Loan): LoanPayment => ({
  id: getNextTemporaryId(loan.loanPayments),
  paymentMethodId: paymentMethod.id,
  paymentAmount: 0,
  paymentDate: getCurrentLocalDateTime(),
  paymentMethodName: paymentMethod.name
});

const getLoanItemTotal = (loanItem: LoanItem) => roundToCents(loanItem.purchasePrice * (1 - loanItem.purchaseDiscount / 100) * loanItem.quantity * vatFactor);
const getLoanPaymentTotal = (loanPayment: LoanPayment) => loanPayment.paymentAmount;

const updateLoanPayments = (currentLoan: Loan, previousLoan: Loan) => {
  if (isSaved(currentLoan)) {
    let newLoan: Loan | undefined;
    let beginingIndex: number | undefined;

    if (currentLoan.loanPayments !== previousLoan.loanPayments) {
      newLoan = currentLoan;
      beginingIndex = getUpdateBeginingIndex(currentLoan, previousLoan, 'loanPayments');
    } else if (currentLoan.loanItems !== previousLoan.loanItems) {
      const savedLoanPayments = currentLoan.loanPayments.filter(lp => lp.id > 0);
      const newNotProductLoanPayments = currentLoan.loanPayments.filter(lp => lp.id < 0 && lp.paymentMethodId !== productPaymentMethodId);
      const newReturnedLoanItems = currentLoan.loanItems.filter(li => li.id < 0 && li.returned);

      newLoan = {
        ...currentLoan,
        loanPayments: [
          ...savedLoanPayments,
          ...newNotProductLoanPayments,
          ...newReturnedLoanItems.length ? [{
            id: getNextTemporaryId(newNotProductLoanPayments),
            paymentMethodId: productPaymentMethodId,
            paymentAmount: getItemsTotal(newReturnedLoanItems, getLoanItemTotal),
            paymentDate: getCurrentLocalDateTime(),
            paymentMethodName: productPaymentMethodName
          }] : []
        ]
      };

      beginingIndex = -1;
    }

    if (newLoan !== undefined && beginingIndex !== undefined) {
      const loanItemsLendedTotal = getItemsTotal(newLoan.loanItems.filter(li => !li.returned), getLoanItemTotal)
      const updatedLoanPayments = getUpdatedEntitiesTotal(newLoan.loanPayments, 'paymentAmount', loanItemsLendedTotal, beginingIndex, lp => lp.id < 0 && lp.paymentMethodId !== productPaymentMethodId, lp => lp.id < 0 && lp.paymentMethodId === condonationPaymentMethodId);

      return {
        ...newLoan,
        loanPayments: updatedLoanPayments
      };
    }
  }

  return currentLoan;
};

export default function LoanPage() {
  const { id } = useParams();

  const [loan, setLoan] = useState<Loan>(getInitialLoan());

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [partnerStores, setPartnerStores] = useState<PartnerStore[]>([]);
  const [stockProducts, setStockProducts] = useState<StockProduct[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const form = useForm();
  const navigate = useNavigate();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    getEmployees().then(employees => setEmployees(employees)).catch(() => showErrorNotification('Error al cargar los Empleados.'));
    getPartnerStores().then(partnerStores => setPartnerStores(partnerStores)).catch(() => showErrorNotification('Error al cargar las Tiendas Asociadas.'));
    getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos.'));
    getLoanPaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods)).catch(() => showErrorNotification('Error al cargar los Métodos de Pago.'));
  }, [showErrorNotification]);

  useEffect(() => {
    if (id) {
      getLoan(+id)
        .then(loan => {
          form.reset({
            employeeId: loan.employeeId,
            partnerStoreId: loan.partnerStoreId,
            partnerStoreRepresentative: loan.partnerStoreRepresentative,
            remarks: loan.remarks,
          });
          setLoan(loan);
        })
        .catch(() => showErrorNotification('Error al cargar el Préstamo.'));
    } else {
      setLoan(getInitialLoan());
    }
  }, [id, form, showErrorNotification]);

  useEffect(() => {
    const partnerStoreRepresentative = partnerStores.find(ps => ps.id === loan.partnerStoreId)?.manager ?? '';
    form.setValue('partnerStoreRepresentative', partnerStoreRepresentative, { shouldValidate: form.formState.isSubmitted });
    setLoan(l => isSaved(l) ? l : setEntityPropertyValue(l, 'partnerStoreRepresentative', partnerStoreRepresentative));;
  }, [loan.partnerStoreId, partnerStores, form]);

  const handleSaveLoanClick = () => {
    saveLoan(getUpdatedEntityDate(loan))
      .then(loan => {
        navigate(`/prestamos/${loan.id}`);
        showSuccessNotification('Préstamo registrado correctamente.');
      })
      .catch(() => showErrorNotification('Error al registrar el Préstamo.'));
  };

  const handleUpdateLoanClick = () => {
    updateLoan(getUpdatedEntityDate(loan))
      .then(loan => {
        setLoan(loan);
        showSuccessNotification('Préstamo actualizado correctamente.');
      })
      .catch(() => showErrorNotification('Error al actualizar el Préstamo.'));
  };

  const handleCompleteLoanClick = () => {
    completeLoan(getUpdatedEntityDate(loan))
      .then(loan => {
        setLoan(loan);
        showSuccessNotification('Préstamo completado correctamente.');
      })
      .catch(() => showErrorNotification('Error al completar el Préstamo.'));
  };

  const handleCancelLoanClick = () => {
    cancelLoan(getUpdatedEntityDate(loan))
      .then(loan => {
        setLoan(loan);
        showSuccessNotification('Préstamo cancelado correctamente.');
      })
      .catch(() => showErrorNotification('Error al cancelar el Préstamo.'));
  };

  const handleDownloadLoanContractClick = () => {
    downloadLoanContract(loan.id)
      .then(file => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(file.data);
        anchor.download = file.name;
        anchor.click();
        showSuccessNotification('Contrato de Préstamo descargado correctamente.');
      })
      .catch(() => showErrorNotification('Error al descargar el Contrato de Préstamo.'));
  };

  return (
    <Box>
      <FormProvider {...form}>
        <Grid container spacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos Generales</Typography>
          </Grid>
          <Grid item container xs={12}>
            <FormControl>
              <FormLabel>Rol de Berel Eje 8</FormLabel>
              <RadioGroup row value={loan.borrower} onChange={e => setLoan(setEntityPropertyValue(loan, 'borrower', e.target.value === 'true'))}>
                <FormControlLabel value={false} label="Prestamista" control={<Radio disabled={isSaved(loan)} />} />
                <FormControlLabel value={true} label="Prestatario" control={<Radio disabled={isSaved(loan)} />} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="employeeId" defaultValue={loan.employeeId || ''} rules={{ required: true }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField select fullWidth label="Representante de Berel Eje 8" disabled={isSaved(loan)} error={invalid} value={loan.employeeId || ''} onChange={e => { onChange(e); setLoan(setEntityPropertyValue(loan, 'employeeId', +e.target.value)); }}>
                  {(employees.length || loan.id === 0 ? employees : [{
                    id: loan.employeeId,
                    name: 'Representante de Berel Eje 8'
                  }]).map(e => (
                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                  ))}
                </TextField>
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="partnerStoreId" defaultValue={loan.partnerStoreId || ''} rules={{ required: true }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField select fullWidth label="Tienda Asociada" disabled={isSaved(loan)} error={invalid} value={loan.partnerStoreId || ''} onChange={e => { onChange(e); setLoan(setEntityPropertyValue(loan, 'partnerStoreId', +e.target.value)); }}>
                  {(partnerStores.length || loan.id === 0 ? partnerStores : [{
                    id: loan.partnerStoreId,
                    name: 'Tienda Asociada'
                  }]).map(ps => (
                    <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
                  ))}
                </TextField>
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Controller name="partnerStoreRepresentative" defaultValue={loan.partnerStoreRepresentative} rules={{ required: true, maxLength: 50 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField fullWidth label="Representante de Tienda Asociada" disabled={isSaved(loan)} error={invalid} value={loan.partnerStoreRepresentative} onChange={e => { onChange(e); setLoan(setEntityPropertyValue(loan, 'partnerStoreRepresentative', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8} md={6}>
              <Controller name="remarks" defaultValue={loan.remarks} rules={{ maxLength: 500 }} render={({ field: { onChange }, fieldState: { invalid } }) => (
                <TextField multiline fullWidth label="Notas de Préstamo" disabled={isFinished(loan)} error={invalid} value={loan.remarks} onChange={e => { onChange(e); setLoan(setEntityPropertyValue(loan, 'remarks', e.target.value)); }} />
              )} />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Productos Prestados</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={loan}
              property={'loanItems'}
              disabled={isSaved(loan)}
              canRemove={true}
              required={true}
              sourceEntities={stockProducts}
              getInitialItem={getInitialLoanItem}
              getItemTotal={getLoanItemTotal}
              filterItems={li => !li.returned}
              setEntity={setLoan}
              columns={{
                fullName: {
                  name: 'Producto'
                },
                purchasePrice: {
                  name: 'Precio',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'currency',
                  isValid: v => v > 0
                },
                purchaseDiscount: {
                  name: 'Descuento',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'percentage'
                },
                quantity: {
                  name: 'Cantidad',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'integer',
                  isValid: v => v > 0
                }
              }} />
          </Grid>
        </Grid>
        {isSaved(loan) && (
          <>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Productos Devueltos</Typography>
              </Grid>
              <Grid item xs={12}>
                <PropertyTable
                  entity={loan}
                  property={'loanItems'}
                  disabled={isFinished(loan)}
                  canRemove={true}
                  sourceEntities={stockProducts}
                  getInitialItem={getInitialLoanItem}
                  getItemTotal={getLoanItemTotal}
                  getCanUpdate={li => li.id < 0}
                  getCanRemove={li => li.id < 0}
                  filterItems={li => li.returned}
                  updateEntity={updateLoanPayments}
                  setEntity={setLoan}
                  columns={{
                    fullName: {
                      name: 'Producto'
                    },
                    purchasePrice: {
                      name: 'Precio',
                      type: 'number',
                      access: 'write',
                      align: 'center',
                      format: 'currency',
                      isValid: v => v > 0
                    },
                    purchaseDiscount: {
                      name: 'Descuento',
                      type: 'number',
                      access: 'write',
                      align: 'center',
                      format: 'percentage'
                    },
                    quantity: {
                      name: 'Cantidad',
                      type: 'number',
                      access: 'write',
                      align: 'center',
                      format: 'integer',
                      isValid: v => v > 0
                    }
                  }} />
              </Grid>
            </Grid>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Pagos</Typography>
              </Grid>
              <Grid item xs={12}>
                <PropertyTable
                  entity={loan}
                  property={'loanPayments'}
                  disabled={isFinished(loan)}
                  canRemove={true}
                  required={true}
                  sourceEntities={paymentMethods.filter(pm => pm.id !== productPaymentMethodId)}
                  getInitialItem={getInitialLoanPayment}
                  getItemTotal={getLoanPaymentTotal}
                  getCanUpdate={lp => lp.id < 0 && lp.paymentMethodId !== productPaymentMethodId && lp.paymentMethodId !== condonationPaymentMethodId}
                  getCanRemove={lp => lp.id < 0 && lp.paymentMethodId !== productPaymentMethodId}
                  updateEntity={updateLoanPayments}
                  setEntity={setLoan}
                  isValid={lp => lp.paymentAmount > 0 || lp.paymentMethodId === condonationPaymentMethodId}
                  columns={{
                    paymentMethodName: {
                      name: 'Forma de Pago'
                    },
                    paymentAmount: {
                      name: 'Importe',
                      type: 'number',
                      align: 'center',
                      format: 'currency',
                      validateItem: true,
                      getAccess: lp => lp.paymentMethodId !== productPaymentMethodId && lp.paymentMethodId !== condonationPaymentMethodId && loan.loanPayments.filter(lp => lp.id < 0 && lp.paymentMethodId !== productPaymentMethodId).length > 1 ? 'write' : 'read'
                    },
                    paymentDate: {
                      name: 'Fecha de Pago',
                      type: 'date-time',
                      align: 'center'
                    }
                  }} />
              </Grid>
            </Grid>
          </>
        )}
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {!isSaved(loan) && <Button variant="contained" color="success" onClick={form.handleSubmit(handleSaveLoanClick)}>Guardar Préstamo</Button>}
              {isSaved(loan) && !isFinished(loan) && <Button variant="contained" color="info" onClick={handleDownloadLoanContractClick}>Contrato de Préstamo</Button>}
              {isSaved(loan) && !isFinished(loan) && !isPaid(loan) && <Button variant="contained" color="warning" onClick={form.handleSubmit(handleUpdateLoanClick)}>Actualizar Préstamo</Button>}
              {isSaved(loan) && !isFinished(loan) && isPaid(loan) && <Button variant="contained" color="secondary" onClick={form.handleSubmit(handleCompleteLoanClick)}>Completar Préstamo</Button>}
              {isSaved(loan) && !isFinished(loan) && <Button variant="contained" color="error" onClick={handleCancelLoanClick}>Cancelar Préstamo</Button>}
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
}