import { useEffect, useState } from "react";
import { Measure } from "../models/measure";
import { Product } from "../models/product";
import { ProductType } from "../models/product-type";
import { StockProduct } from "../models/stock-product";

export const useProductsFilter = (
  products: Product[],
  productTypes: ProductType[],
  measures: Measure[],
  stockProducts: StockProduct[],
  selectedProviderIds: number[],
  selectedProductIds: number[],
  selectedProductTypeIds: number[]
) => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
  const [filteredProductTypes, setFilteredProductTypes] = useState<ProductType[]>(productTypes);
  const [filteredMeasures, setFilteredMeasures] = useState<Measure[]>(measures);

  const [filteredProductIds, setFilteredProductIds] = useState<number[]>([]);
  const [filteredProductTypeIds, setFilteredProductTypeIds] = useState<number[]>([]);
  
  useEffect(() => setFilteredProductIds(filteredProducts.map(p => p.id)), [filteredProducts]);
  useEffect(() => setFilteredProductTypeIds(filteredProductTypes.map(pt => pt.id)), [filteredProductTypes]);

  useEffect(() => {
    setFilteredProducts(selectedProviderIds.length ? products.filter(p => selectedProviderIds.includes(p.providerId)) : products);
  }, [products, selectedProviderIds]);

  useEffect(() => {
    const productIds = selectedProductIds.length ? selectedProductIds : filteredProductIds.length < products.length ? filteredProductIds : [];
    setFilteredProductTypes(productIds.length ? productTypes.filter(pt => productIds.includes(pt.productId)) : productTypes);
  }, [products, productTypes, selectedProductIds, filteredProductIds]);

  useEffect(() => {
    const productTypeIds = selectedProductTypeIds.length ? selectedProductTypeIds : filteredProductTypeIds.length < productTypes.length ? filteredProductTypeIds : [];
    const measureIds = stockProducts.filter(sp => productTypeIds.includes(sp.productTypeId)).map(sp => sp.measureId);
    setFilteredMeasures(measureIds.length ? measures.filter(m => measureIds.includes(m.id)) : measures);
  }, [productTypes, measures, stockProducts, selectedProductTypeIds, filteredProductTypeIds]);

  return {
    filteredProducts,
    filteredProductTypes,
    filteredMeasures
  };
};
