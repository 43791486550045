import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNotifications } from "../../core/hooks/use-notifications";
import { Employee } from "../../core/models/employee";
import { PaymentMethod } from "../../core/models/payment-method";
import { Profit } from "../../core/models/profit";
import { ProfitActivity } from "../../core/models/profit-activity";
import { ProfitInk } from "../../core/models/profit-ink";
import { ProfitItem } from "../../core/models/profit-item";
import { ProfitPayment } from "../../core/models/profit-payment";
import { ProfitSample } from "../../core/models/profit-sample";
import { ProfitShipping } from "../../core/models/profit-shipping";
import { ShippingVehicle } from "../../core/models/shipping-vehicle";
import { StockProduct } from "../../core/models/stock-product";
import { getEmployees } from "../../core/services/employees";
import { getUpdatedEntitiesTotal, getNextTemporaryId, getItemsTotal, setEntityPropertyValue } from "../../core/services/entities";
import { roundToCents, roundToInteger } from "../../core/services/numbers";
import { getSalePaymentMethods } from "../../core/services/payment-methods";
import { getStockProducts } from "../../core/services/stock-products";
import { vatFactor } from "../../core/utils/app-constants";
import PropertyTable from "../../shared/components/PropertyTable";
import IncomeStatement from "../../shared/components/IncomeStatement";
import NumberField from "../../shared/components/NumberField";

const inkProductIds = [206, 207, 210];

const vehicles: ShippingVehicle[] = [
  {
    id: 1,
    description: 'Volkswagen Saveiro 2012',
    fuelEfficiency: 8
  }
];

const getInitialProfit = (): Profit => ({
  id: 0,
  saleCommision: 0,
  profitItems: [],
  profitInks: [],
  profitPayments: [],
  profitShippings: [],
  profitActivities: [],
  profitSamples: []
});

const getInitialProfitItem = (stockProduct: StockProduct, profit: Profit): ProfitItem => ({
  id: getNextTemporaryId(profit.profitItems),
  productId: stockProduct.productId,
  productTypeId: stockProduct.productTypeId,
  measureId: stockProduct.measureId,
  purchasePrice: stockProduct.purchasePrice,
  purchaseDiscounts: [stockProduct.purchaseDiscount, ...profit.profitItems.length ? new Array(profit.profitItems[0].purchaseDiscounts.length - 1).fill(0) : []],
  salePrice: getStockProductSalePrice(stockProduct),
  saleDiscount: getStockProductSaleDiscount(stockProduct),
  quantity: 1,
  fullName: stockProduct.fullName
});

const getInitialProfitInk = (stockProduct: StockProduct, profit: Profit): ProfitInk => ({
  id: getNextTemporaryId(profit.profitInks),
  productId: stockProduct.productId,
  productTypeId: stockProduct.productTypeId,
  measureId: stockProduct.measureId,
  purchasePrice: stockProduct.purchasePrice,
  purchaseDiscount: stockProduct.purchaseDiscount,
  inkOunces: 0,
  inkParts: 0,
  inkHalf: false,
  fullName: stockProduct.fullName
});

const getInitialProfitPayment = (paymentMethod: PaymentMethod, profit: Profit): ProfitPayment => ({
  id: getNextTemporaryId(profit.profitPayments),
  paymentMethodId: paymentMethod.id,
  paymentAmount: 0,
  paymentCommision: paymentMethod.commission,
  paymentMethodName: paymentMethod.name
});

const getInitialProfitShipping = (shippingVehicle: ShippingVehicle, profit: Profit): ProfitShipping => ({
  id: getNextTemporaryId(profit.profitShippings),
  shippingVehicleId: shippingVehicle.id,
  description: '',
  highwayTolls: 0,
  shippingKilometers: 0,
  fuelLiterPrice: 0,
  fuelEfficiency: shippingVehicle.fuelEfficiency,
  shippingVehicleDescription: shippingVehicle.description
});

const getInitialProfitActivity = (employee: Employee, profit: Profit): ProfitActivity => ({
  id: getNextTemporaryId(profit.profitActivities),
  employeeId: employee.id,
  description: '',
  workedHours: 0,
  hourlyRate: employee.monthlySalary * 12 / 52 / employee.weeklyHours,
  employeeName: employee.name
});

const getInitialProfitSample = (stockProduct: StockProduct, profit: Profit): ProfitSample => ({
  id: getNextTemporaryId(profit.profitSamples),
  productId: stockProduct.productId,
  productTypeId: stockProduct.productTypeId,
  measureId: stockProduct.measureId,
  purchasePrice: stockProduct.purchasePrice,
  purchaseDiscounts: [stockProduct.purchaseDiscount, ...profit.profitSamples.length ? new Array(profit.profitSamples[0].purchaseDiscounts.length - 1).fill(0) : []],
  quantity: 1,
  fullName: stockProduct.fullName
});

const getProfitItemSaleTotal = (profitItem: ProfitItem) => roundToInteger(profitItem.salePrice * (1 - profitItem.saleDiscount / 100)) * profitItem.quantity;
const getProfitItemPurchaseTotal = (profitItem: ProfitItem) => profitItem.purchaseDiscounts.reduce((us, pd) => us * (1 - pd / 100), profitItem.purchasePrice) * profitItem.quantity * vatFactor;
const getProfitInkTotal = (profitInk: ProfitInk) => profitInk.purchasePrice * (1 - profitInk.purchaseDiscount / 100) * (profitInk.inkOunces / 32 + profitInk.inkParts / 32 / 48 + (profitInk.inkHalf ? 1 / 32 / 48 / 2 : 0)) * vatFactor;
const getProfitPaymentCommissionTotal = (profitPayment: ProfitPayment) => profitPayment.paymentAmount * profitPayment.paymentCommision / 100;
const getProfitShippingTotal = (profitShipping: ProfitShipping) => profitShipping.highwayTolls + profitShipping.shippingKilometers * profitShipping.fuelLiterPrice / profitShipping.fuelEfficiency;
const getProfitActivityTotal = (profitActivity: ProfitActivity) => profitActivity.workedHours * profitActivity.hourlyRate;
const getProfitSampleTotal = (profitSample: ProfitSample) => profitSample.purchaseDiscounts.reduce((us, pd) => us * (1 - pd / 100), profitSample.purchasePrice) * profitSample.quantity * vatFactor;

const getStockProductSalePrice = (stockProduct: StockProduct) => roundToInteger(stockProduct.salePrice);
const getStockProductSaleDiscount = (stockProduct: StockProduct) => roundToCents(stockProduct.saleDiscount / stockProduct.salePrice * 100);

const getUpdatedProfitPaymentAmounts = (profit: Profit, previousProfit: Profit) => {
  if (profit.profitPayments !== previousProfit.profitPayments || profit.profitItems !== previousProfit.profitItems) {
    const profitItemsSaleTotal = getItemsTotal(profit.profitItems, getProfitItemSaleTotal);

    let beginingIndex: number | undefined;
    if (profit.profitPayments !== previousProfit.profitPayments) {
      if (profit.profitPayments.length > previousProfit.profitPayments.length) {
        beginingIndex = profit.profitPayments.length - 1;
      } else if (profit.profitPayments.length < previousProfit.profitPayments.length) {
        beginingIndex = previousProfit.profitPayments.findIndex(pp => !profit.profitPayments.includes(pp));
      } else {
        //TODO: If some other property was changed noting should happen
        beginingIndex = profit.profitPayments.findIndex(pp => !previousProfit.profitPayments.includes(pp)) + 1;
      }
    } else if (profitItemsSaleTotal !== getItemsTotal(previousProfit.profitItems, getProfitItemSaleTotal)) {
      beginingIndex = -1;
    }

    if (beginingIndex !== undefined) {
      const updatedProfitPayments = getUpdatedEntitiesTotal(profit.profitPayments, 'paymentAmount', profitItemsSaleTotal, beginingIndex);
      if (updatedProfitPayments !== profit.profitPayments) {
        return {
          ...profit,
          profitPayments: updatedProfitPayments
        };
      }
    }
  }

  return profit;
};

const getSaleTotal = (profit: Profit) => getItemsTotal(profit.profitItems, getProfitItemSaleTotal);
const getCostOfSale = (profit: Profit) => getItemsTotal(profit.profitItems, getProfitItemPurchaseTotal) + getItemsTotal(profit.profitInks, getProfitInkTotal);
const getGrossProfit = (profit: Profit) => getSaleTotal(profit) - getCostOfSale(profit);
const getVariableExpends = (profit: Profit) => getItemsTotal(profit.profitPayments, getProfitPaymentCommissionTotal) + getItemsTotal(profit.profitShippings, getProfitShippingTotal) + getItemsTotal(profit.profitActivities, getProfitActivityTotal) + getItemsTotal(profit.profitSamples, getProfitSampleTotal);
const getSaleCommision = (profit: Profit) => (getGrossProfit(profit) - getVariableExpends(profit)) / (100 / profit.saleCommision + 1);
const getNetProfit = (profit: Profit) => getGrossProfit(profit) - getVariableExpends(profit) - getSaleCommision(profit);

export default function ProfitPage() {
  const [profit, setProfit] = useState<Profit>(getInitialProfit());

  const [stockProducts, setStockProducts] = useState<StockProduct[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [shippingVehicles, setShippingVehicles] = useState<ShippingVehicle[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [stockProductInks, setStockProductInks] = useState<StockProduct[]>([]);

  const form = useForm();

  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    getStockProducts().then(stockProducts => setStockProducts(stockProducts)).catch(() => showErrorNotification('Error al cargar los Productos.'));
    getSalePaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods)).catch(() => showErrorNotification('Error al cargar los Métodos de Pago.'));
    setShippingVehicles(vehicles);
    getEmployees().then(employees => setEmployees(employees)).catch(() => showErrorNotification('Error al cargar los Empleados.'));
  }, [showErrorNotification]);

  useEffect(() => {
    setStockProductInks(stockProducts.filter(sp => inkProductIds.includes(sp.productId)));
  }, [stockProducts]);

  return (
    <Box>
      <FormProvider {...form}>
        <Grid container spacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos Generales</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography>Comisión de Venta:</Typography>
              <NumberField type="write" format="percentage" disabled={profit.id > 0} value={profit.saleCommision} onChange={v => setProfit(setEntityPropertyValue(profit, 'saleCommision', v))} />
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Detalle de Venta</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitItems'}
              disabled={profit.id > 0}
              canRemove={true}
              required={true}
              sourceEntities={stockProducts}
              getInitialItem={getInitialProfitItem}
              getItemTotal={getProfitItemSaleTotal}
              updateEntity={getUpdatedProfitPaymentAmounts}
              setEntity={setProfit}
              columns={{
                fullName: {
                  name: 'Producto'
                },
                salePrice: {
                  name: 'Precio',
                  type: 'number',
                  align: 'center',
                  format: 'currency'
                },
                saleDiscount: {
                  name: 'Descuento',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'percentage',
                  unified: true
                },
                quantity: {
                  name: 'Cantidad',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'integer',
                  isValid: v => v > 0
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Costo de Venta</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Productos</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitItems'}
              disabled={profit.id > 0}
              getItemTotal={getProfitItemPurchaseTotal}
              setEntity={setProfit}
              columns={{
                fullName: {
                  name: 'Producto'
                },
                purchasePrice: {
                  name: 'Precio',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'currency'
                },
                purchaseDiscounts: {
                  name: 'Descuento',
                  type: 'number-array',
                  access: 'write',
                  align: 'center',
                  format: 'percentage',
                  unified: true
                },
                quantity: {
                  name: 'Cantidad',
                  type: 'number',
                  align: 'center',
                  format: 'integer'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Tintas</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitInks'}
              disabled={profit.id > 0}
              canRemove={true}
              sourceEntities={stockProductInks}
              getInitialItem={getInitialProfitInk}
              getItemTotal={getProfitInkTotal}
              setEntity={setProfit}
              isValid={i => i.inkOunces > 0 || i.inkParts > 0 || i.inkHalf}
              columns={{
                fullName: {
                  name: 'Tinta'
                },
                purchasePrice: {
                  name: 'Precio',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'currency',
                  isValid: v => v > 0
                },
                purchaseDiscount: {
                  name: 'Descuento',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'percentage',
                  unified: true
                },
                inkOunces: {
                  name: 'Onzas',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'integer',
                  validateItem: true
                },
                inkParts: {
                  name: 'Partes',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'integer',
                  validateItem: true,
                  isValid: v => v < 48
                },
                inkHalf: {
                  name: 'Media',
                  type: 'boolean',
                  access: 'write',
                  align: 'center',
                  validateItem: true
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Gastos Variables</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Comisión Bancaria</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitPayments'}
              disabled={profit.id > 0}
              canRemove={true}
              required={true}
              sourceEntities={paymentMethods}
              getInitialItem={getInitialProfitPayment}
              getItemTotal={getProfitPaymentCommissionTotal}
              updateEntity={getUpdatedProfitPaymentAmounts}
              setEntity={setProfit}
              columns={{
                paymentMethodName: {
                  name: 'Forma de Pago'
                },
                paymentAmount: {
                  name: 'Cantidad',
                  type: 'number',
                  access: profit.profitPayments.length > 1 ? 'write' : 'read',
                  align: 'center',
                  format: 'currency',
                  isValid: v => v > 0
                },
                paymentCommision: {
                  name: 'Comisión',
                  type: 'number',
                  align: 'center',
                  format: 'percentage'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Traslados / Entregas</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitShippings'}
              disabled={profit.id > 0}
              canRemove={true}
              sourceEntities={shippingVehicles}
              getInitialItem={getInitialProfitShipping}
              getItemTotal={getProfitShippingTotal}
              setEntity={setProfit}
              columns={{
                shippingVehicleDescription: {
                  name: 'Vehículo'
                },
                description: {
                  name: 'Descripción',
                  access: 'write',
                  isValid: v => !!v && v.length <= 50
                },
                highwayTolls: {
                  name: 'Casetas',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'tight-currency'
                },
                shippingKilometers: {
                  name: 'Kilómetros',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'kilometers',
                  isValid: v => v > 0
                },
                fuelLiterPrice: {
                  name: 'Gasolina',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'tight-currency',
                  isValid: v => v > 0
                },
                fuelEfficiency: {
                  name: 'Rendimiento',
                  type: 'number',
                  align: 'center',
                  format: 'fuel-efficiency'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Actividades Realizadas</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitActivities'}
              disabled={profit.id > 0}
              canRemove={true}
              sourceEntities={employees}
              getInitialItem={getInitialProfitActivity}
              getItemTotal={getProfitActivityTotal}
              setEntity={setProfit}
              columns={{
                employeeName: {
                  name: 'Empleado'
                },
                description: {
                  name: 'Descripción',
                  access: 'write',
                  isValid: v => !!v && v.length <= 50
                },
                workedHours: {
                  name: 'Horas',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'hours',
                  isValid: v => v > 0
                },
                hourlyRate: {
                  name: 'Tarifa',
                  type: 'number',
                  align: 'center',
                  format: 'currency'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Muestras / Mermas</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={profit}
              property={'profitSamples'}
              disabled={profit.id > 0}
              canRemove={true}
              sourceEntities={stockProducts}
              getInitialItem={getInitialProfitSample}
              getItemTotal={getProfitSampleTotal}
              setEntity={setProfit}
              columns={{
                fullName: {
                  name: 'Producto'
                },
                purchasePrice: {
                  name: 'Precio',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'currency'
                },
                purchaseDiscounts: {
                  name: 'Descuento',
                  type: 'number-array',
                  access: 'write',
                  align: 'center',
                  format: 'percentage',
                  unified: true
                },
                quantity: {
                  name: 'Cantidad',
                  type: 'number',
                  access: 'write',
                  align: 'center',
                  format: 'integer',
                  isValid: v => v > 0
                }
              }}
            />
          </Grid>
        </Grid>
        {profit.profitItems.length > 0 && (
          <>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Utilidad de Venta</Typography>
              </Grid>
              <Grid item xs={6}>
                <IncomeStatement
                  entity={profit}
                  items={[
                    { name: 'Venta', isBold: true, getAmount: getSaleTotal },
                    { name: 'Costo de Venta', getAmount: getCostOfSale },
                    { name: 'Utilidad Bruta', isBold: true, getAmount: getGrossProfit },
                    { name: 'Gastos Variables', getAmount: getVariableExpends },
                    { name: 'Comisión de Venta', getAmount: getSaleCommision },
                    { name: 'Utilidad Neta', isBold: true, getAmount: getNetProfit }
                  ]}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Button variant="contained" color="success" onClick={form.handleSubmit(() => { })}>Validar Utilidad</Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
}