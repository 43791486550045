export const vatFactor = 1.16;

export const berelProviderId = 1;

export const fixedPriceTypeId = 1;
export const profitPriceTypeId = 2;
export const divisionPriceTypeId = 3;

export const cashPaymentMethodId = 1;
export const productPaymentMethodId = 10;
export const condonationPaymentMethodId = 11;

export const productPaymentMethodName = 'Producto';
export const condonationPaymentMethodName = 'Condonación';

export const minValidDateString = '2020-01-01T00:00';
