import { useState } from "react";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore, ListAlt } from "@mui/icons-material";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { getDateTimeString } from "../../core/services/dates";
import { getItemSubtotal } from "../../core/services/entities";
import { createGroupingTree, getTreeNodeSubtotal, getTreeNodeTotal, TTreeNode } from "../../core/services/trees";
import { TDEntity, TDProperty, TDPropertyValue } from "../../core/utils/entity-types";
import { TColumnAlign, TColumnType, TNumberFormat } from "../../core/utils/util-types";
import NumberField from "./NumberField";

type TreeTableProps<
  TEntity extends TDEntity,
  TGroupingProperty extends TDProperty<TEntity>,
  TGroupingPropertyValue extends TDPropertyValue<TEntity, TGroupingProperty>
  > = {
    entities: TEntity[],
    columns: TreeTableColumnProps<TEntity>,
    groupingProperty: TGroupingProperty,
    groupingColumn: TreeTableColumnDefinitionProps<TEntity, TGroupingProperty, TGroupingPropertyValue>,
    groupingLevels: ((groupingPropertyValue: TGroupingPropertyValue) => string)[]
    emptyLabel?: string,
    getEntityTotal?: (entity: TEntity) => number,
    getEntityLink?: (entity: TEntity) => string
  };

type TreeTableColumnProps<
  TEntity extends TDEntity
  > = {
    [TProperty in TDProperty<TEntity>]?: TreeTableColumnDefinitionProps<TEntity, TProperty, TDPropertyValue<TEntity, TProperty>>
  };

type TreeTableColumnDefinitionProps<
  TEntity extends TDEntity,
  TProperty extends TDProperty<TEntity>,
  TPropertyValue extends TDPropertyValue<TEntity, TProperty>
  > = {
    name: string,
    type?: TColumnType
    align?: TColumnAlign,
    format?: TNumberFormat,
    getValue?: (value: TPropertyValue) => string
  };

type TreeTableRowProps<
  TEntity extends TDEntity,
  TGroupingProperty extends TDProperty<TEntity>,
  TGroupingPropertyValue extends TDPropertyValue<TEntity, TGroupingProperty>
  > = {
    columns: TreeTableColumnProps<TEntity>,
    groupingProperty: TGroupingProperty,
    groupingColumn: TreeTableColumnDefinitionProps<TEntity, TGroupingProperty, TGroupingPropertyValue>,
    treeLevel: number,
    treeNode?: TTreeNode<TEntity>,
    entity?: TEntity,
    getEntityTotal?: (entity: TEntity) => number,
    getEntityLink?: (entity: TEntity) => string
  };

const any = (value: any): any => value as any;

const getColumns = <
  TEntity extends TDEntity
>(columns: TreeTableColumnProps<TEntity>) => {
  return Object.entries(columns).map(([property, column]) => ({
    property: property as TDProperty<TEntity>,
    ...column as TreeTableColumnDefinitionProps<TEntity, TDProperty<TEntity>, TDPropertyValue<TEntity, TDProperty<TEntity>>>
  }));
};

export default function TreeTable<
  TEntity extends TDEntity,
  TGroupingProperty extends TDProperty<TEntity>,
  TGroupingPropertyValue extends TDPropertyValue<TEntity, TGroupingProperty>
>({
  entities,
  columns,
  groupingProperty,
  groupingColumn,
  groupingLevels,
  emptyLabel,
  getEntityTotal,
  getEntityLink
}: TreeTableProps<TEntity, TGroupingProperty, TGroupingPropertyValue>) {
  const filteredColumns = getColumns(columns);
  const groupingTree = createGroupingTree(entities, groupingProperty, groupingLevels);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 0, padding: 0 }}></TableCell>
          <TableCell align={groupingColumn.align || 'left'}>
            <Typography fontWeight="bold">{groupingColumn.name}</Typography>
          </TableCell>
          {filteredColumns.map((column, columnIndex) => (
            <TableCell key={columnIndex} align={column.align || 'left'}>
              <Typography fontWeight="bold">{column.name}</Typography>
            </TableCell>
          ))}
          {getEntityTotal && (
            <>
              <TableCell align="center">
                <Typography fontWeight="bold">Subtotal</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="bold">Total</Typography>
              </TableCell>
            </>
          )}
          {getEntityLink && (
            <TableCell></TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {groupingTree.children.map((treeNode, treeNodeIndex) => (
          <TreeTableRow
            key={treeNodeIndex}
            columns={columns}
            groupingProperty={groupingProperty}
            groupingColumn={groupingColumn}
            treeLevel={1}
            treeNode={treeNode}
            getEntityTotal={getEntityTotal}
            getEntityLink={getEntityLink}
          />
        ))}
        {groupingTree.entities.map((entity, entityIndex) => (
          <TreeTableRow
            key={entityIndex}
            columns={columns}
            groupingProperty={groupingProperty}
            groupingColumn={groupingColumn}
            treeLevel={1}
            entity={entity}
            getEntityTotal={getEntityTotal}
            getEntityLink={getEntityLink}
          />
        ))}
        {!entities.length && emptyLabel && (
          <TableRow>
            <TableCell align="center" colSpan={1 + 1 + filteredColumns.length + (getEntityTotal ? 2 : 0) + (getEntityLink ? 1 : 0)}>
              <Typography>{emptyLabel}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table >
  );
};

function TreeTableRow<
  TEntity extends TDEntity,
  TGroupingProperty extends TDProperty<TEntity>,
  TGroupingPropertyValue extends TDPropertyValue<TEntity, TGroupingProperty>
>({
  columns,
  groupingProperty,
  groupingColumn,
  treeLevel,
  treeNode,
  entity,
  getEntityTotal,
  getEntityLink
}: TreeTableRowProps<TEntity, TGroupingProperty, TGroupingPropertyValue>) {
  const filteredColumns = getColumns(columns);

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 0, padding: 0 }}>
          {treeNode && (
            <IconButton size="small" sx={{ padding: 0, marginLeft: treeLevel * 1.5 }} onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align={groupingColumn.align || 'left'}>
          {treeNode ? (
            <Typography fontStyle="italic">{treeNode.name}</Typography>
          ) : entity ? (
            <>
              {(() => {
                switch (groupingColumn.type) {
                  case 'date-time':
                    return (
                      <Typography>{getDateTimeString(any(entity[groupingProperty]))}</Typography>
                    );
                  default:
                    return (
                      <Typography>{groupingColumn.getValue ? groupingColumn.getValue(any(entity[groupingProperty])) : entity[groupingProperty]}</Typography>
                    );
                }
              })()}
            </>
          ) : (
            <></>
          )}
        </TableCell>
        {filteredColumns.map((column, columnIndex) => (
          <TableCell key={columnIndex} align={column.align || 'left'}>
            <>
              {(() => {
                switch (column.type) {
                  case 'number':
                    return (
                      <>
                        {entity && <NumberField type="text" format={column.format || 'integer'} value={any(entity[column.property])} />}
                      </>
                    );
                  case 'date-time':
                    return (
                      <>
                        {entity && <Typography>{getDateTimeString(any(entity[column.property]))}</Typography>}
                      </>
                    );
                  default:
                    return (
                      <>
                        {entity && <Typography>{column.getValue ? column.getValue(entity[column.property]) : entity[column.property]}</Typography>}
                      </>
                    );
                }
              })()}
            </>
          </TableCell>
        ))}
        {getEntityTotal && (
          <>
            <TableCell align="center" sx={{ ...treeNode && { fontStyle: 'italic' } }}>
              <NumberField type="text" format="currency" value={treeNode ? getTreeNodeSubtotal(treeNode, getEntityTotal, getItemSubtotal) : entity ? getItemSubtotal(entity, getEntityTotal) : 0} />
            </TableCell>
            <TableCell align="center" sx={{ ...treeNode && { fontStyle: 'italic' } }}>
              <NumberField type="text" format="currency" value={treeNode ? getTreeNodeTotal(treeNode, getEntityTotal) : entity ? getEntityTotal(entity) : 0} />
            </TableCell>
          </>
        )}
        {getEntityLink && (
          <TableCell align="center">
            {entity && (
              <IconButton color="info" size="small" component={Link} to={getEntityLink(entity)}>
                <ListAlt />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      {treeNode && expanded && treeNode.children.map((treeNode, treeNodeIndex) => (
        <TreeTableRow
          key={treeNodeIndex}
          columns={columns}
          groupingProperty={groupingProperty}
          groupingColumn={groupingColumn}
          treeLevel={treeLevel + 1}
          treeNode={treeNode}
          getEntityTotal={getEntityTotal}
          getEntityLink={getEntityLink}
        />
      ))}
      {treeNode && expanded && treeNode.entities.map((entity, treeNodeIndex) => (
        <TreeTableRow
          key={treeNodeIndex}
          columns={columns}
          groupingProperty={groupingProperty}
          groupingColumn={groupingColumn}
          treeLevel={treeLevel + 1}
          entity={entity}
          getEntityTotal={getEntityTotal}
          getEntityLink={getEntityLink}
        />
      ))}
    </>
  );
};
