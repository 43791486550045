import { vatFactor } from "../utils/app-constants";
import { TDArrayProperty, TDArrayPropertyItem, TDEntity, TDEntityArrayProperty, TDEntityArrayPropertyItem, TDEntityOrNever, TDNumberProperty, TDProperty, TDPropertyValue } from "../utils/entity-types";
import { getCurrentLocalDateTime } from "./dates";

export const setEntityPropertyValue = <
  TEntity extends TDEntity,
  TProperty extends TDProperty<TEntity>,
  TPropertyValue extends TDPropertyValue<TEntity, TProperty>
>(
  entity: TEntity,
  property: TProperty,
  propertyValue: TPropertyValue
): TEntity => ({
  ...entity,
  [property]: propertyValue
});

export const addEntityArrayPropertyItem = <
  TEntity extends TDEntity,
  TArrayProperty extends TDArrayProperty<TEntity>,
  TArrayPropertyItem extends TDArrayPropertyItem<TEntity, TArrayProperty>
>(
  entity: TEntity,
  arrayProperty: TArrayProperty,
  arrayPropertyItem: TArrayPropertyItem
): TEntity => ({
  ...entity,
  [arrayProperty]: [...entity[arrayProperty] as unknown as unknown[], arrayPropertyItem]
});

export const removeEntityArrayPropertyItem = <
  TEntity extends TDEntity,
  TArrayProperty extends TDArrayProperty<TEntity>
>(
  entity: TEntity,
  arrayProperty: TArrayProperty,
  arrayPropertyItemIndex: number
): TEntity => ({
  ...entity,
  [arrayProperty]: (entity[arrayProperty] as unknown as unknown[]).filter((_, index) => index !== arrayPropertyItemIndex)
});

export const setEntityArrayPropertyItemPropertyValue = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemProperty extends TDProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  TEntityArrayPropertyItemPropertyValue extends TDPropertyValue<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>, TEntityArrayPropertyItemProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty,
  entityArrayPropertyItemIndex: number,
  entityArrayPropertyItemProperty: TEntityArrayPropertyItemProperty,
  entityArrayPropertyItemPropertyValue: TEntityArrayPropertyItemPropertyValue
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map((item, index) => index === entityArrayPropertyItemIndex ? {
    ...item,
    [entityArrayPropertyItemProperty]: entityArrayPropertyItemPropertyValue
  } : item)
});

export const setEntityArrayPropertyItemArrayPropertyItem = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemArrayProperty extends TDArrayProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  TEntityArrayPropertyItemArrayPropertyItem extends TDArrayPropertyItem<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>, TEntityArrayPropertyItemArrayProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty,
  entityArrayPropertyItemIndex: number,
  entityArrayPropertyItemArrayProperty: TEntityArrayPropertyItemArrayProperty,
  entityArrayPropertyItemArrayPropertyItemIndex: number,
  entityArrayPropertyItemArrayPropertyItem: TEntityArrayPropertyItemArrayPropertyItem
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map((item, index) => index === entityArrayPropertyItemIndex ? {
    ...item,
    [entityArrayPropertyItemArrayProperty]: (item[entityArrayPropertyItemArrayProperty] as unknown as any[]).map((item, index) => index === entityArrayPropertyItemArrayPropertyItemIndex ? entityArrayPropertyItemArrayPropertyItem : item)
  } : item)
});

export const setEntityArrayPropertyAllItemsPropertyValue = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemProperty extends TDProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  TEntityArrayPropertyItemPropertyValue extends TDPropertyValue<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>, TEntityArrayPropertyItemProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty,
  entityArrayPropertyItemProperty: TEntityArrayPropertyItemProperty,
  entityArrayPropertyItemPropertyValue: TEntityArrayPropertyItemPropertyValue
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map(item => ({
    ...item,
    [entityArrayPropertyItemProperty]: entityArrayPropertyItemPropertyValue
  }))
});

export const addEntityArrayPropertyAllItemsArrayPropertyItem = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemArrayProperty extends TDArrayProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  TEntityArrayPropertyItemArrayPropertyItem extends TDArrayPropertyItem<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>, TEntityArrayPropertyItemArrayProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty,
  entityArrayPropertyItemArrayProperty: TEntityArrayPropertyItemArrayProperty,
  entityArrayPropertyItemArrayPropertyItem: TEntityArrayPropertyItemArrayPropertyItem
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map(item => ({
    ...item,
    [entityArrayPropertyItemArrayProperty]: [...item[entityArrayPropertyItemArrayProperty] as unknown as any[], entityArrayPropertyItemArrayPropertyItem]
  }))
});

export const removeEntityArrayPropertyAllItemsArrayPropertyItem = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemArrayProperty extends TDArrayProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  >(
    entity: TEntity,
    entityArrayProperty: TEntityArrayProperty,
    entityArrayPropertyItemArrayProperty: TEntityArrayPropertyItemArrayProperty,
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map(item => ({
    ...item,
    [entityArrayPropertyItemArrayProperty]: (item[entityArrayPropertyItemArrayProperty] as unknown as any[]).slice(0, -1)
  }))
});

export const setEntityArrayPropertyAllItemsArrayPropertyItem = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItemArrayProperty extends TDArrayProperty<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>>,
  TEntityArrayPropertyItemArrayPropertyItem extends TDArrayPropertyItem<TDEntityOrNever<TDArrayPropertyItem<TEntity, TEntityArrayProperty>>, TEntityArrayPropertyItemArrayProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty,
  entityArrayPropertyItemArrayProperty: TEntityArrayPropertyItemArrayProperty,
  entityArrayPropertyItemArrayPropertyItemIndex: number,
  entityArrayPropertyItemArrayPropertyItem: TEntityArrayPropertyItemArrayPropertyItem
): TEntity => ({
  ...entity,
  [entityArrayProperty]: (entity[entityArrayProperty] as unknown as any[]).map(item => ({
    ...item,
    [entityArrayPropertyItemArrayProperty]: (item[entityArrayPropertyItemArrayProperty] as unknown as any[]).map((item, index) => index === entityArrayPropertyItemArrayPropertyItemIndex ? entityArrayPropertyItemArrayPropertyItem : item)
  }))
});

export const getArrayPropertyItems = <
  TEntity extends TDEntity,
  TArrayProperty extends TDArrayProperty<TEntity>,
  TArrayPropertyItem extends TDArrayPropertyItem<TEntity, TArrayProperty>
>(
  entity: TEntity,
  arrayProperty: TArrayProperty
): TArrayPropertyItem[] => entity[arrayProperty] as unknown as TArrayPropertyItem[];

export const getEntityArrayPropertyItems = <
  TEntity extends TDEntity,
  TEntityArrayProperty extends TDEntityArrayProperty<TEntity>,
  TEntityArrayPropertyItem extends TDEntityArrayPropertyItem<TEntity, TEntityArrayProperty>
>(
  entity: TEntity,
  entityArrayProperty: TEntityArrayProperty
): TEntityArrayPropertyItem[] => entity[entityArrayProperty] as unknown as TEntityArrayPropertyItem[];

export const getNextTemporaryId = <TEntity extends TDEntity & { id: number }>(items: TEntity[]) => Math.min(0, ...items.map(i => i.id)) - 1;

export const getItemSubtotal = <TEntity extends TDEntity>(entity: TEntity, getItemTotal: (item: TEntity) => number): number => getItemTotal(entity) / vatFactor;
export const getItemsSubtotal = <TEntity extends TDEntity>(items: TEntity[], getItemTotal: (item: TEntity) => number): number => getItemsTotal(items, getItemTotal) / vatFactor;
export const getItemsTotal = <TEntity extends TDEntity>(items: TEntity[], getItemTotal: (item: TEntity) => number): number => items.reduce((total, item) => total + getItemTotal(item), 0);

export const getUpdatedEntityDate = <TEntity extends TDEntity & { date: Date }>(entity: TEntity): TEntity => ({
  ...entity,
  date: getCurrentLocalDateTime()
});

export const getUpdateBeginingIndex = <
  TEntity extends TDEntity,
  TArrayProperty extends TDArrayProperty<TEntity>
>(
  currentEntity: TEntity,
  previousEntity: TEntity,
  arrayProperty: TArrayProperty
) => {
  const currentItems = getArrayPropertyItems(currentEntity, arrayProperty);
  const previousItems = getArrayPropertyItems(previousEntity, arrayProperty);

  if (currentItems !== previousItems) {
    if (currentItems.length > previousItems.length) {
      return currentItems.findIndex(item => !previousItems.includes(item));
    } else if (currentItems.length < previousItems.length) {
      return previousItems.findIndex(item => !currentItems.includes(item));
    } else {
      return currentItems.findIndex(item => !previousItems.includes(item)) + 1;
    }
  }

  return -1;
};

export const getUpdatedEntitiesTotal = <
  TEntity extends TDEntity,
  TNumberProperty extends TDNumberProperty<TEntity>
>(
  entities: TEntity[],
  numberProperty: TNumberProperty,
  targetTotal: number,
  beginingIndex: number,
  canSetProperty?: (entity: TEntity) => boolean,
  canSetNegativeValue?: (entity: TEntity) => boolean
): TEntity[] => {
  const entitiesTotal = entities.reduce((s, e) => s + (e[numberProperty] as unknown as number), 0);

  if (entitiesTotal === targetTotal) {
    return entities;
  } else {
    const isForward = beginingIndex >= 0;
    const arrayLength = entities.length;
    const actualIndex = isForward ? beginingIndex % arrayLength : (beginingIndex + 1) % arrayLength;

    const shiftedEntities = [
      ...(isForward ? entities.slice(actualIndex) : entities.slice(0, arrayLength + actualIndex).reverse()),
      ...(isForward ? entities.slice(0, actualIndex) : entities.slice(arrayLength + actualIndex).reverse())
    ];

    const shiftedPropertyValues = shiftedEntities.reduce((pv, e) => {
      const currentPropertyValue = e[numberProperty] as unknown as number;
      const canSetCurrentProperty = pv.remainingIncrement && (!canSetProperty || canSetProperty(e));
      const canSetCurrentNegativeValue = canSetNegativeValue && canSetNegativeValue(e);

      return {
        remainingIncrement: canSetCurrentProperty ? canSetCurrentNegativeValue ? 0 : Math.min(currentPropertyValue + pv.remainingIncrement, 0) : pv.remainingIncrement,
        propertyValues: [
          ...pv.propertyValues,
          canSetCurrentProperty ? canSetCurrentNegativeValue ? currentPropertyValue + pv.remainingIncrement : Math.max(currentPropertyValue + pv.remainingIncrement, 0) : currentPropertyValue
        ]
      };
    }, {
      remainingIncrement: targetTotal - entitiesTotal,
      propertyValues: [] as number[]
    }).propertyValues;

    const propertyValues = [
      ...(isForward ? shiftedPropertyValues.slice(arrayLength - actualIndex) : shiftedPropertyValues.slice(0, arrayLength + actualIndex).reverse()),
      ...(isForward ? shiftedPropertyValues.slice(0, arrayLength - actualIndex) : shiftedPropertyValues.slice(arrayLength + actualIndex).reverse())
    ];

    return entities.map((e, i) => ({
      ...e,
      [numberProperty]: propertyValues[i]
    }));
  }
};
