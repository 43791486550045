import { ListAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { getDateTimeString } from "../../core/services/dates";
import { getItemSubtotal } from "../../core/services/entities";
import { TDEntity, TDProperty, TDPropertyValue } from "../../core/utils/entity-types";
import { TColumnAlign, TColumnType, TNumberFormat } from "../../core/utils/util-types";
import NumberField from "./NumberField";

type EntityTableProps<TEntity extends TDEntity> = {
  entities: TEntity[],
  columns: EntityTableColumnProps<TEntity>,
  emptyLabel?: string,
  getEntityTotal?: (entity: TEntity) => number,
  getEntityLink?: (entity: TEntity) => string
};

type EntityTableColumnProps<TEntity extends TDEntity> = {
  [TProperty in TDProperty<TEntity>]?: EntityTableColumnDefinitionProps<TEntity, TProperty, TDPropertyValue<TEntity, TProperty>>
};

type EntityTableColumnDefinitionProps<TEntity extends TDEntity, TProperty extends TDProperty<TEntity>, TPropertyValue extends TDPropertyValue<TEntity, TProperty>> = {
  name: string,
  type?: TColumnType
  align?: TColumnAlign,
  format?: TNumberFormat,
  getValue?: (value: TPropertyValue) => string
};

const any = (value: any): any => value as any;

const getColumns = <TEntity extends TDEntity>(columns: EntityTableColumnProps<TEntity>) => {
  return Object.entries(columns).map(([property, column]) => ({
    property: property as TDProperty<TEntity>,
    ...column as EntityTableColumnDefinitionProps<TEntity, TDProperty<TEntity>, TDPropertyValue<TEntity, TDProperty<TEntity>>>
  }));
};

export default function EntityTable<TEntity extends TDEntity>({
  entities,
  columns,
  emptyLabel,
  getEntityTotal,
  getEntityLink
}: EntityTableProps<TEntity>) {
  const filteredColumns = getColumns(columns);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {filteredColumns.map((column, columnIndex) => (
            <TableCell key={columnIndex} align={column.align || 'left'}>
              <Typography fontWeight="bold">{column.name}</Typography>
            </TableCell>
          ))}
          {getEntityTotal && (
            <>
              <TableCell align="center">
                <Typography fontWeight="bold">Subtotal</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="bold">Total</Typography>
              </TableCell>
            </>
          )}
          {getEntityLink && (
            <TableCell></TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {entities.map((entity, entityIndex) => (
          <TableRow key={entityIndex}>
            {filteredColumns.map((column, columnIndex) => (
              <TableCell key={columnIndex} align={column.align || 'left'}>
                <>
                  {(() => {
                    switch (column.type) {
                      case 'number':
                        return (
                          <NumberField type="text" format={column.format || 'integer'} value={any(entity[column.property])} />
                        );
                      case 'date-time':
                        return (
                          <Typography>{getDateTimeString(any(entity[column.property]))}</Typography>
                        );
                      default:
                        return (
                          <Typography>{column.getValue ? column.getValue(entity[column.property]) : entity[column.property]}</Typography>
                        );
                    }
                  })()}
                </>
              </TableCell>
            ))}
            {getEntityTotal && (
              <>
                <TableCell align="center">
                  <NumberField type="text" format="currency" value={getItemSubtotal(entity, getEntityTotal)} />
                </TableCell>
                <TableCell align="center">
                  <NumberField type="text" format="currency" value={getEntityTotal(entity)} />
                </TableCell>
              </>
            )}
            {getEntityLink && (
              <TableCell align="center">
                <IconButton color="info" size="small" component={Link} to={getEntityLink(entity)}>
                  <ListAlt />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
        {!entities.length && emptyLabel && (
          <TableRow>
            <TableCell align="center" colSpan={filteredColumns.length + (getEntityTotal ? 2 : 0) + (getEntityLink ? 1 : 0)}>
              <Typography>{emptyLabel}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table >
  );
};
