import { useEffect, useState } from "react";
import { ListAlt } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Purchase } from "../../../core/models/purchase";
import { getCompletedPurchases } from "../../../core/services/purchases";
import { getDateTimeString } from "../../../core/services/dates";
import { useNotifications } from "../../../core/hooks/use-notifications";
import NumberField from "../../../shared/components/NumberField";

export default function Purchases() {
  const [purchases, setPurchases] = useState<Purchase[]>([]);

  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    getCompletedPurchases().then(purchases => setPurchases(purchases)).catch(() => showErrorNotification('Error al cargar las Compras.'));
  }, [showErrorNotification]);

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Fecha</Box></TableCell>
            <TableCell><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Proveedor</Box></TableCell>
            <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Subtotal</Box></TableCell>
            <TableCell align="center"><Box sx={{ typography: 'body1', fontWeight: 'bold' }}>Total</Box></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchases.map((p, i) => (
            <TableRow key={i}>
              <TableCell>
                <Typography variant="body1">{getDateTimeString(p.date)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{p.providerName}</Typography>
              </TableCell>
              <TableCell align="center">
                <NumberField type="text" format="currency" value={p.purchaseItems.reduce((s, pi) => s + pi.purchasePrice * (1 - pi.purchaseDiscount / 100) * pi.receivedQuantity, 0)} />
              </TableCell>
              <TableCell align="center">
                <NumberField type="text" format="currency" value={p.purchaseItems.reduce((s, pi) => s + pi.purchasePrice * (1 - pi.purchaseDiscount / 100) * pi.receivedQuantity, 0) * (p.includesVat ? 1.16 : 1)} />
              </TableCell>
              <TableCell align="center">
                <IconButton color="info" size="small" component={Link} to={`/compras/${p.id}`}>
                  <ListAlt />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
