import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNotifications } from "../../core/hooks/use-notifications";
import { Sale } from "../../core/models/sale";
import { SaleItem } from "../../core/models/sale-item";
import { SaleItemComponent } from "../../core/models/sale-item-component";
import { SalePaymentMethod } from "../../core/models/sale-payment-method";
import { getCurrentLocalDateTime, getDateTimeString } from "../../core/services/dates";
import { getItemsTotal } from "../../core/services/entities";
import { downloadDeliveryNote, downloadQuoteFormat, getSale } from "../../core/services/sales";
import { vatFactor } from "../../core/utils/app-constants";
import ValueList from "../../shared/components/ValueList";
import PropertyTable from "../../shared/components/PropertyTable";
import IncomeStatement from "../../shared/components/IncomeStatement";

class ExtendedSale implements Sale {
  id: number = 0;
  clientId: number = 0;
  businessUnitId: number = 0;
  date: Date = getCurrentLocalDateTime();
  quotation: boolean = false;
  canceled: boolean = false;
  remarks: string = '';
  saleItems: SaleItem[] = [];
  salePaymentMethods: SalePaymentMethod[] = [];
  clientName: string = '';
  businessUnitDescription: string = '';

  get saleItemComponents() {
    return this.saleItems.flatMap(si => si.saleItemComponents);
  }

  constructor(sale: Sale) {
    Object.assign(this, sale);
  }
}

const getInitialSale = (): Sale => ({
  id: 0,
  clientId: 0,
  businessUnitId: 0,
  date: getCurrentLocalDateTime(),
  quotation: false,
  canceled: false,
  remarks: '',
  saleItems: [],
  salePaymentMethods: [],
  clientName: '',
  businessUnitDescription: ''
});

const getSaleItemTotal = (saleItem: SaleItem) => saleItem.saleTotal;
const getSaleItemComponentTotal = (saleItemComponent: SaleItemComponent) => saleItemComponent.purchaseTotal * vatFactor;
const getSalePaymentTotal = (salePayment: SalePaymentMethod) => salePayment.paymentAmount;
const getSalePaymentCommission = (salePayment: SalePaymentMethod) => salePayment.paymentCommission;

const getSaleTotal = (sale: ExtendedSale) => getItemsTotal(sale.saleItems, getSaleItemTotal);
const getCostOfSale = (sale: ExtendedSale) => getItemsTotal(sale.saleItemComponents, getSaleItemComponentTotal);
const getGrossProfit = (sale: ExtendedSale) => getSaleTotal(sale) - getCostOfSale(sale);
const getVariableExpends = (sale: ExtendedSale) => getItemsTotal(sale.salePaymentMethods, getSalePaymentCommission);
const getNetProfit = (sale: ExtendedSale) => getGrossProfit(sale) - getVariableExpends(sale);

export default function LoanPage() {
  const { id } = useParams();

  const [sale, setSale] = useState<ExtendedSale>(new ExtendedSale(getInitialSale()));

  const form = useForm();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    if (id) {
      getSale(+id)
        .then(sale => {
          setSale(new ExtendedSale(sale));
        })
        .catch(() => showErrorNotification('Error al cargar la Venta.'));
    } else {
      setSale(new ExtendedSale(getInitialSale()));
    }
  }, [id, showErrorNotification]);

  const handleDownloadQuoteFormatClick = () => {
    downloadQuoteFormat(sale.id)
      .then(file => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(file.data);
        anchor.download = file.name;
        anchor.click();
        showSuccessNotification('Formato de Cotización descargado correctamente.');
      })
      .catch(() => showErrorNotification('Error al descargar el Formato de Cotización.'));
  };

  const handleDownloadDeliveryNoteClick = () => {
    downloadDeliveryNote(sale.id)
      .then(file => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(file.data);
        anchor.download = file.name;
        anchor.click();
        showSuccessNotification('Nota de Remisión descargada correctamente.');
      })
      .catch(() => showErrorNotification('Error al descargar la Nota de Remisión.'));
  };

  return (
    <Box>
      <FormProvider {...form}>
        <Grid container spacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Datos Generales</Typography>
          </Grid>
          <Grid item flexGrow={1} xs={12}>
            <ValueList
              items={[
                { name: 'Tipo de Venta', value: sale.quotation ? 'Cotización' : sale.canceled ? 'Cancelación' : 'Venta' },
                { name: 'Fecha y Hora', value: getDateTimeString(sale.date) },
                { name: 'Cliente', value: sale.clientName },
                { name: 'Unidad de Negocio', value: sale.businessUnitDescription },
                { name: 'Notas de Venta', value: sale.remarks || 'Sin Notas de Venta' }
              ]}
            />
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
        <Grid container spacing={4} mt={0} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Productos</Typography>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable
              entity={sale}
              property={'saleItems'}
              getItemTotal={getSaleItemTotal}
              columns={{
                fullName: {
                  name: 'Producto'
                },
                salePrice: {
                  name: 'Precio',
                  type: 'number',
                  align: 'center',
                  format: 'currency'
                },
                saleDiscount: {
                  name: 'Descuento',
                  type: 'number',
                  align: 'center',
                  format: 'currency'
                },
                quantity: {
                  name: 'Cantidad',
                  type: 'number',
                  align: 'center',
                  format: 'integer'
                }
              }}
            />
          </Grid>
        </Grid>
        {!sale.quotation && (
          <>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Inventario</Typography>
              </Grid>
              <Grid item xs={12}>
                <PropertyTable
                  entity={sale}
                  property={'saleItemComponents'}
                  getItemTotal={getSaleItemComponentTotal}
                  columns={{
                    fullName: {
                      name: 'Producto'
                    },
                    purchasePrice: {
                      name: 'Precio',
                      type: 'number',
                      align: 'center',
                      format: 'currency'
                    },
                    purchaseDiscount: {
                      name: 'Descuento',
                      type: 'number',
                      align: 'center',
                      format: 'percentage'
                    },
                    unitsNumber: {
                      name: 'Unidades',
                      type: 'number',
                      align: 'center',
                      format: 'fraction'
                    },
                    started: {
                      name: 'Iniciado',
                      type: 'boolean',
                      align: 'center'
                    },
                    finished: {
                      name: 'Terminado',
                      type: 'boolean',
                      align: 'center'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Pagos</Typography>
              </Grid>
              <Grid item xs={12}>
                <PropertyTable
                  entity={sale}
                  property={'salePaymentMethods'}
                  getItemTotal={getSalePaymentTotal}
                  columns={{
                    paymentMethodName: {
                      name: 'Forma de Pago'
                    },
                    paymentAmount: {
                      name: 'Cantidad',
                      type: 'number',
                      align: 'center',
                      format: 'currency'
                    },
                    paymentCommission: {
                      name: 'Comisión',
                      type: 'number',
                      align: 'center',
                      format: 'currency'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!sale.quotation && !sale.canceled && (
          <>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Utilidad de Venta</Typography>
              </Grid>
              <Grid item xs={6}>
                <IncomeStatement
                  entity={sale}
                  items={[
                    { name: 'Venta', isBold: true, getAmount: getSaleTotal },
                    { name: 'Costo de Venta', getAmount: getCostOfSale },
                    { name: 'Utilidad Bruta', isBold: true, getAmount: getGrossProfit },
                    { name: 'Gastos Variables', getAmount: getVariableExpends },
                    { name: 'Utilidad Neta', isBold: true, getAmount: getNetProfit }
                  ]}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!sale.canceled && (
          <>
            <Divider sx={{ bgcolor: theme => theme.palette.primary.light }} />
            <Grid container spacing={4} mt={0} mb={4}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  {sale.quotation && <Button variant="contained" color="info" onClick={handleDownloadQuoteFormatClick}>Formato de Cotización</Button>}
                  {!sale.quotation && <Button variant="contained" color="success" onClick={handleDownloadDeliveryNoteClick}>Nota de Remisión</Button>}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </FormProvider>
    </Box>
  );
}