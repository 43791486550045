import { useCallback, useEffect, useState } from "react";
import { User } from "../models/user";
import { accessTokenSetEvent, beginLoginEvent, completeLoginEvent, completeSubscriptions, emitter, setAccessToken } from "../services/http-client";

export const useHttpAuthorization = () => {
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<User>();

    const startLogin = useCallback(() => emitter.emit(beginLoginEvent), []);
    const cancelLogin = useCallback(() => emitter.emit(completeLoginEvent), []);
    const logout = useCallback(() => setAccessToken(''), []);

    useEffect(() => {
        emitter.on(beginLoginEvent, () => setIsAuthenticating(true));
        emitter.on(completeLoginEvent, () => setIsAuthenticating(false));

        emitter.on(accessTokenSetEvent, (accessToken: string) => {
            if (accessToken) {
                const tokenObject = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString());
                setAuthenticatedUser({
                    name: tokenObject['name'],
                    email: tokenObject['email'],
                    nickname: tokenObject['nameid'],
                    password: '',
                    nicknameOrEmail: '',
                    newPassword: '',
                    confirmPassword: '',
                    roleName: tokenObject['role']
                })
            } else {
                setAuthenticatedUser(undefined);
            }
        });

        completeSubscriptions();
        
        return () => {
            emitter.off(beginLoginEvent);
            emitter.off(completeLoginEvent);
            emitter.off(accessTokenSetEvent);
        };
    }, []);

    return {
        isAuthenticating,
        authenticatedUser,
        startLogin,
        cancelLogin,
        logout
    };
};
